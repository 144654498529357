.Payouts{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 100;
    
}
/*#spinner {
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count:1;
    animation-duration: 2s;
    /*transform-style: preserve-3d;
    text-align:center;
}
@keyframes spinner {
from {
    transform: rotateX(0deg);
}
to {
    transform: rotateX(-360deg);
}
}*/

@media only screen and (min-width: 1024px) {

    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}