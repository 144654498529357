.news-container {
    height: 20rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-box {
    height: 55%;
    width: 50rem;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 740px) {
    .news-box {
        width: 90%;
        height: 80%;
        display: flex;
        flex-direction: column;
    }
    
    .input-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .news-left {
        width: 90%;
        height: 30% !important;
        margin-top: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-right {
        width: 90%;
        height: 70% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .input-box {
        width: 90%;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }
    .news-input {
        height: 3rem !important;
        width: 100% !important; 
    }
    .news-title {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-body {
        font-size: 1.1rem !important;
        font-weight: 600;
        color: gray !important;
        margin-bottom: 20px;
        text-align: center;
    }
    .news-main {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-head {
        font-size: 2rem !important;
        font-weight: 600;
        color: #000;
        margin-bottom: 20px;
    }
    .news-container {
        height: 30rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-button {
        width: 100% !important;
        height: 3rem !important
    }
}

@media screen and (max-width: 859px) {
    .news-box {
        width: 90%;
    }
}


@media screen and (max-width: 425px) {
    .news-box {
        width: 90%;
        height: 80%;
        display: flex;
        flex-direction: column;
    }
    
    .input-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .news-left {
        width: 90%;
        height: 30% !important;
        margin-top: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-right {
        width: 90%;
        height: 70% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .input-box {
        width: 90%;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }
    .news-input {
        height: 3rem !important;
        width: 100% !important; 
    }
    .news-title {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-body {
        font-size: 1.1rem !important;
        font-weight: 600;
        color: gray !important;
        margin-bottom: 20px;
        text-align: center;
    }
    .news-main {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-head {
        font-size: 1.5rem !important;
        font-weight: 600;
        color: #000;
        margin-bottom: 20px;
    }
    .news-container {
        height: 30rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-button {
        width: 100% !important;
        height: 3rem !important
    }
}

.news-left {
    min-width: 40%;

    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news-title {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
}

.news-main {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
}


.news-head {
    font-size: 1.7rem;
    font-weight: 600;
    color: black;
}

.news-body {
    font-size: 1.6vh;
    font-weight: 400;
    color: gray;
    align-self: flex-start;
    display: flex;
}

.news-right {
    width: 80%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news-input {
    width: 55%;
    height: 30%;
    border: 1px solid rgba(0,0,0,0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.news-button {
    background-color: #c4b454;
    border-radius: 5px;
    height: 30%;
    border: none;
    width: 30%;
}