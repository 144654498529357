.Questions{
    background-color: black;
    padding: 50px;
    padding-bottom: 25px;
    text-align: center;
    font-family: 'Lato', sans-serif;
}
.q-title{
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 30px;
    color: white;
}
.q-body{
    line-height: 1.5;
    margin-bottom: 50px;
    width: 40%;
    margin-left: 30%;
    color: white;
}
.q-btn-div{
    background-color: black;
}
.q-btn-gold{
     padding: 15px 20px;
    border-radius: .4em;
    background-color: #e0cb07;
    color: black;
    font-family: 'Lato', sans-serif !important;
    text-align: center;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    border: none;
    outline: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 0px;
    margin-right: 3%;
}
.q-btn-gold:hover{
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    margin-bottom: 0px;
    filter: grayscale(.3);
}


.q-btn-white{
     padding: 15px 20px;
    border-radius: .4em;
    background-color: white;
    border: 1px solid black;
    color: black;
    font-family: 'Lato', sans-serif !important;
    text-align: center;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    outline: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 0px;
    margin-left: 3%;
}
.q-btn-white:hover{
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    margin-bottom: 0px;
}
.q-btn-gold:hover .q-btn-white{

}
.q-btn-white:hover .q-btn-gold{

}

@media screen and (max-width: 768px) {
    .q-btn-gold{
        display: inline-block;
        margin-right: 0px;
        width: 90%;
    }
    .q-btn-white{
        display: inline-block;
        margin-left: 0px;
        width: 90%;
        margin-top: 10px;
    }
    .q-body{
        width: 100%;
        margin-left: 0%;
    }
    .q-btn-gold:hover{
        font-size: 16px;
    }
    .q-btn-white:hover{
        font-size: 16px;
        margin-top: 10px
    }
}