.ProfileFeed{
    padding: 10px;
    padding-bottom: 10px;
    margin: 5px;
}
.ProfileFeed-Header{
    font-size: 1.5rem;
    font-weight: 600;
}

