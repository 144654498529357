.Teammate{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 10px;
    /*border: 1px solid rgb(240,240,240);*/
    padding: 10px;
    border-radius: .5em;
    background-color: white;
    color: black;
}
.teammate-left{
    font-weight: 500;
    color: black;
}
.teammate-right{
    text-align: right;
}
.teammate-value{
    font-weight: 200;
    display: inline-block;
}
.teammate-percent{
    font-weight: 500;
    display: inline-block;
}
.greentxt{
    color: green;
}
.redtxt{
    color: red;
}
.graytxt{
    color: gray;
}