.NavBar2 {
    background-color: white;
    color: black;
    max-height: 9.9vh;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}



.nav-logo2{
    width: 100%;
}
.nav-toggle2{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 15px 0px;
}

.nav-drop-size2{
    font-size: 1rem !important;
    text-align: center;
    font-weight: 100 !important;
    /*background-color: black !important;*/
    background-color: white;
    color: black;
}
.nav-drop-size2:hover {
    /*color: black !important;
    background-color: rgba(95, 91, 91, 0.068) !important;*/
    color: white !important;
    background-color: gray !important;
    text-decoration: none;
}
.nav-size2{
    font-size: 1.3rem;
    text-align: center;
    background-color: white;
    /*border: 1px solid gray;*/
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.roboNormal2 {
    /*color: white;*/
    color: black;
    background-color: white;
    font-size: 1rem !important;
    text-decoration: none !important;
}

.login-btn2{
    border: 2px solid black;
    border-radius: .2em;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 0px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px;
}
.login-btn3{
    border: 2px solid black;
    border-radius: .2em;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 0px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px;
}
.signup-btn2{
    border-radius: .2em;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 0px;
    background-color: black;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    margin: 5px;
}

.Top{
    padding-top: 6vh;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}
.Top-CTA{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1%;
    padding-top: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.Top-CTA-title{
    font-size: 3.1em;
    font-weight: bold;
    margin-bottom: 3%;
    width: 100%;
    color: white;
    background-color: black;
}

.Top-CTA-title2{
    font-size: 2.25em;
    font-weight: bold;
    margin-bottom: 3%;
    margin-top: 4vh;
    text-align: center;
    color: white;
}
.Top-CTA-subtitle{
    font-weight: 300;
    font-size: 1.2em;
    margin-bottom: 5%;
    color: white;
    width: 100%;
}
.Top-CTA-subtitle2{
    font-weight: 300;
    font-size: .9em;
    width: 90%;
    margin-left: 5%;
    margin-top: 5px;
    margin-bottom: 15px;
    line-height: 1.75;
    text-align: center;
    color: white;
}
.Top-CTA-email{
    font-size: .8em;
    font-weight: 300;
    margin-bottom: 3px;
    margin-left: 2px;
}
.Top-CTA-input{
    width: 68%;
    margin-left: 0%;
    padding: 12px;
    border-radius: .2em;
    border: 2px solid rgb(200,200,200);
    margin-bottom: 5px;
}
.Top-CTA-btn{
    width: 25%;
    margin-left: 5%;
    margin-right: 0%;
    padding: 12px;
    border-radius: .3em;
    background-color: rgb(0,0,0,.9);
    color: white;
    border: none;
    outline: none;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-input5{
    width: 100%;
    margin-left: 0%;
    padding: 12px;
    border-radius: .2em;
    border: 2px solid rgb(200,200,200);
    margin-bottom: 5px;
}
.Top-CTA-btn5{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    padding: 12px;
    margin-bottom: 5px;
    border-radius: .3em;
    background-color: rgb(0,0,0,.9);
    color: white;
    border: none;
    outline: none;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    
}
.Top-CTA-btn2{
    width: 95%;
    margin-left: 2.5%;
    margin-right: 0%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: .3em;
    background-color: #e0cb07;
    color: black;
    font-weight: 500;
    font-size: .9rem;
    border: none;
    outline: none;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-btn2:hover{
    font-size: 1rem;
    filter: grayscale(.3);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    text-decoration: none;
}
.Top-CTA-btn3{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: .3em;
    background-color: #e0cb07;
    color: black;
    font-weight: 500;
    font-size: .9rem;
    border: none;
    outline: none;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-btn3:hover{
    font-size: 1rem;
    filter: grayscale(.3);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    text-decoration: none;
}
.Top-CTA-free{
    font-weight: 400;
    color: white;
    font-size: .8em;
}
.Top-CTA-free2{
    font-weight: 400;
    color: white;
    font-size: .8em;
    text-align: center;
}
.Top-Img{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 3%;
    text-align: center;
    padding-top: 1vh;
    max-width: 100%;
}

.op-img {
    height: 33rem;
    width: 35rem;
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 450px) {
    .op-img {
        height: 20rem;
        width: 20rem;   
    }
    .Top {
        height: 120vh;
        width:100%;
        max-height: 120vh;
    }
    .Top-Img {
        max-width: 100%;
    }
}
@media screen and (max-width: 375px) and (min-width: 320px) {
    .op-img {
        height: 20rem;
        width: 20rem;   
    }
    .Top {
        height: 150vh;
        width:100%;
    }
    .Top-Img {
        max-width: 100%;
    }

    .top-top {
        display: flex;
        flex-direction: column;
    }
}

.SocialProof{
    background-color: black;
    color: white;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
}
.SocialProof-title{
    width: 90%;
    margin-left: 5%;
    font-weight: 400;
}
.SocialProof-subtitle{
    margin-top: 20px;
    margin-bottom: 40px;
    color: rgb(220,220,220);
    font-weight: 300;
}
.SocialProof-Div{
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
}
.SocialProof-Item{
    display: inline;
    width: 100%;
}
.SocialProof-Item-Title{
    font-size: 3em !important;
    font-weight: 700;
}
.spanTitle{
    font-size: 1rem !important; 
    font-weight: 400 !important;
}
.SocialProof-hr{
    width: 25%;
    background-color: rgb(90,90,90);
    margin-top: 0;
    margin-bottom: 5px;
}
.SocialProof-Item-Desc{
    margin-bottom: 50px;
}

.landing-signup{
    width: 95%;
    margin-left: 2.5%;
}

.top-container {
    width: 90%;
    height: 75vh;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

@media screen and (max-width: 1215px) {
    .top-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-header {
        margin-right: 30%;
        width: 100%;
    }
    .top-body {
        margin-right: 30%;
        width: 100%;
    }
    .top-bottom {
        margin-right: 30%;
        width: 100%;
    }
    .top-container-left {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-container-right {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-img {
        width: 100%;
        
        height: 100%;
    }
    
}

.top-container-left {
    max-width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
    row-gap: 1rem;
    background-color: black;
}

.top-container-right {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.top-header {
    width: 55%;
    font-size: 3rem;
    margin-left: 30%;
}

.top-body {
    width: 55%;
    font-size: 1.4rem;
    margin-left: 30%;
}

.top-bottom {
    width: 55%;
    font-size: 1.7rem;
    margin-left: 30%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 3.5%;
}

.svg {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: .2rem;
    border-radius: 5px;
    border: 1px solid white;
    width: 9rem;
}

.go-to {
    width: 11rem;
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.top-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 2.3rem;
    align-items: center;
    column-gap: 1.5rem;
    margin-top: 5%;
}


.svg:hover {
    border: 1px solid #c4b454;
    cursor: pointer;
    
}


@media only screen and (min-width: 594px) {
    .Top{
        padding-left: 10%;
        padding-right: 10%;
        
    }
    .Top-CTA-title{
        font-size: 3.9em;
        font-weight: 600;
        margin-bottom: 3%;
        margin-left: 20%;
    }
    .Top-CTA-subtitle{
        font-weight: 300;
        font-size: 1.5em;
        margin-bottom: 5%;
        margin-left: 20%;
    }
    .Top-CTA-title2{
        font-size: 3.9em;
        font-weight: 600;
        margin-bottom: 3%;
        margin-left: 20%;
    }
    .Top-CTA-subtitle2{
        font-weight: 300;
        font-size: 1.5em;
        margin-bottom: 5%;
        margin-left: 20%;
    }
    .svg {
        margin-left: 20%;
    }
}

@media only screen and (min-width: 740px) {
    .SocialProof-title{
        width: 70%;
        margin-left: 15%;
    }
}

@media only screen and (min-width: 990px) {
    .Top{
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;
        height: 80vh;
    }
    
    
    .Top-CTA-title{
        font-size: 3em;
        font-weight: 600;
        margin-bottom: 5%;
    }
    .Top-CTA-title2{
        font-size: 3em;
        font-weight: 600;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 990px) {
    .NavBar2{
        padding-right: 10%;
        padding-left: 10%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .nav-logo2{
        height: 100%;
        margin-top: -15px;
        padding-top: -15px;
        margin-bottom: -15px;
        padding-bottom: -15px;
    }
    .nav-drop2{
        background-color: white;
        color: black;
    }
    .nav-drop-size2{
        font-size: 1rem !important;
        text-align: center;
        font-weight: 100 !important;
        background-color: white !important;
        color: black;
    }
    .nav-drop-size2:hover {
        color: white !important;
        text-decoration: none;
        background-color: gray !important;
    }
    .nav-size2{
        font-size: 1.3rem;
        text-align: center;
        background-color: white !important;
        -moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
        -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    }
    .roboNormal2 {
        color: black;
        font-size: 1rem !important;
        text-decoration: none !important;
        background-color: white;
    }
    .Top{
        padding-top: 8vh;
        vertical-align: top;
    }
    .Top-CTA{
        display: inline-block !important;
        width: 50%;
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 7%;
        padding-top: 10vh;
        vertical-align: top;
    }
    .Top-CTA-title{
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 3%;
        width: 70%;
    }
    .Top-CTA-subtitle{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
        width: 70%;
    }
    .Top-CTA-title2{
        font-size: 3em;
        font-weight: 600;
        margin-bottom: 3%;
    }
    .Top-CTA-subtitle2{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
    }
    .Top-CTA-email{
        font-size: .8em;
        font-weight: 300;
        margin-bottom: 3px;
        margin-left: 2px;
    }
    .Top-CTA-input{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-input5{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn5{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        margin-bottom: 0px;
        padding: 12px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-btn2{
        width: 90%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: #e0cb07;
        color: black;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-free{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-CTA-free2{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-Img{
        display: inline-block;
        width: 50%;
        padding-left: 1%;
        padding-right: 1%;
        padding-bottom: 3%;
        text-align: center;
        padding-top: 7vh;
    }
    .top-img-src{
        width: 90%;
    }

    .SocialProof-Div{
        padding-left: 5%;
        padding-right: 5%;
        text-align: left;
    }
    .SocialProof-Item{
        display: inline-block;
        width: 31.3%;
        margin-left: 2%;
    }
}
@media only screen and (min-width: 1024px) {
    .Top-CTA-title{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 5%;
    }
    .Top-CTA-title2{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 1314px) {
    .Top{
        padding-top: 12vh;
        vertical-align: top;
    }
    .Top-CTA{
        display: inline-block;
        width: 45%;
        padding-left: 7%;
        padding-right: 2%;
        padding-bottom: 7%;
        padding-top: 15vh;
        height: 83vh;
        vertical-align: top;
    }
    .Top-CTA-title{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 3%;
    }
    .Top-CTA-subtitle{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
    }
    .Top-CTA-title2{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 3%;
    }
    .Top-CTA-subtitle2{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
    }
    .Top-CTA-email{
        font-size: .8em;
        font-weight: 300;
        margin-bottom: 3px;
        margin-left: 2px;
    }
    .Top-CTA-input{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-input5{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn5{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        margin-bottom: 0px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-btn2{
        width: 90%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: #e0cb07;
    color: black;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-free{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-CTA-free2{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-Img{
        display: inline-block;
        width: 50%;
        padding-left: 1%;
        padding-right: 3%;
        padding-bottom: 3%;
        text-align: center;
        padding-top: 3vh;
    }
    .top-img-src{
        width: 90%;
    }


    
    .SocialProof{
        background-color: black;
        color: white;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    .SocialProof-title{
        width: 70%;
        margin-left: 15%;
        font-weight: 400;
    }
    .SocialProof-subtitle{
        margin-top: 20px;
        margin-bottom: 40px;
        color: rgb(220,220,220);
        font-weight: 300;
    }
    .SocialProof-Div{
        padding-left: 10%;
        padding-right: 10%;
        text-align: left;
    }
    .SocialProof-Item{
        display: inline-block;
        width: 31.3%;
        margin-left: 2%;
    }
    .SocialProof-Item-Title{
        font-size: 3em !important;
        font-weight: 700;
    }
    .spanTitle{
        font-size: 1rem !important; 
        font-weight: 400 !important;
    }
    .SocialProof-hr{
        width: 15%;
        background-color: rgb(90,90,90);
        margin-left: 1%;
    }
}