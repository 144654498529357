.rewards-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.container-box {
    width: 350px;
    background-color: #424242;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    border-radius: 15px;
}

.container-title-box {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-title {
    font-size: 1.32rem;
    color: white;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.container-title-section {
    width: 80%;
    height: 18%;
    display: flex;
    flex-direction: column;
    /* padding-left: 2.5rem; */
    justify-content: center;
    /* padding-right: 2.5rem; */
}

.section-title {
    font-size: 1rem;
    color: white;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.section-body {
    font-size: .85rem;
    color: white;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
}

.sharebtn {
    width: 70%;
    height: 7%;
    background-color: #c4b454;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 1rem;
    border-radius: 20px;
    outline: none !important;
    border: none !important;
    
}

.refer-code {
    height: 16%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.refer-box {
    height: 55%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.refer-left {
    width: 45%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.refer-right {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.txtbox {
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-box {
    height: 90%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 10px;
}

.copy-box:hover {
    cursor: pointer;
    border-color: #c4b454;
}

.sharebtn:hover {
    cursor: pointer;
    background-color: #a99b48;
}