@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

.leftbar {
    height: 100vh;
    background-color: black;
    position: fixed;
    left: 0;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    display: flex;
    border-right: .5px solid gray;
}

.home:hover {
    background-color: #f2f2f2;
}

.row-board {
    /* style={{width: "100%", height: "15%", backgroundColor: "black", alignItems: "center", justifyContent: "flex-start", display: "flex", flexDirection: "row",}}
     */
    width: 100%;
    margin-top: 8px;
    background-color: black;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
}

.row-board:hover {
    border-right: 3px solid #c4b454;
    cursor: pointer;
}

.row-board:hover .icon {
    color: #c4b454;
}

.row-board:hover .row-txt {
    color: #c4b454;
}

.row-txt {
    /* style={{color: "white", fontSize: "1rem", fontFamily: "'Lato', sans-serif", fontWeight: 400, marginTop: "3%", alignSelf: "center"}} */
    color: white;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    margin-top: 3%;
    align-self: center;
    font-size: 12px;
}



.icon {
    /* style={{color: "white", fontSize: "1.2rem", marginRight: "7%", marginLeft: "25%"}} */
    color: white;
    margin-right: 7%;
    margin-left: 25%;
    height: 10px;
    width: 5px;
}

.home {
    /* style={{width: "100%", height: "15%", display: "flex", backgroundColor: "transparent", alignItems: "flex-start"}} */
    width: 100%;
    height: 15%;
    display: flex;
    background-color: transparent;
    align-items: flex-start;
}

.rightbar {
    height: calc(100vh - 90px);
    background-color: black;
    position: fixed;
    right: 0;
    width: 300px;
    bottom: 0;
    border-left: 1px solid gray;
    overflow: hidden;
}

.topbar {
    width: calc(100% - 200px);
    height: 90px;
    position: sticky;
    top: 0;
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.topbar-title {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    background-color: black;
    
}

.topbar-middle {
    width: 100%;
    display: flex;
    background-color: black;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    color: white;
    padding-right: 84px;
}

.free-stuff {
    height: 25px;
    width: 250px;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 240px;
}

.free-stuff-item-stock {
    background-color: #c4b454;
    width: 45%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.free-stuff-item-transfer {
    width: 45%;
    height: 100%;
    border-radius: 20px;
    background-color: #212121;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    
}

.topbar-right {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    flex-direction: row;
    column-gap: 1vh;
    position: absolute;
    right: 0;
    
}

.account-section {
    height: 55%;
    width: 75%;
    background-color: #171717;
    border-radius: 15px;
    border: 1px solid rgb(196, 180, 84, .5);
    border-width: thin;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-inner {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account-main {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.account-img {
    height: 36px;
    width: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid white;
}

.account-info {
    height: 100%;
    width:70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 10px;
}

.account-outer {
    height: 100%;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rightbar-ad {
    
    width: 100%;
    border-bottom: 2px solid rgba(33, 33, 33, 0.7);
}

.rightbar-movers {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.movers-title {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px
}

.ads {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ads-header {
    width: 100%;
    
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.ads-arrows {
    display: flex;
    width: 25%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 7%;
}

.ads-close {
    margin-right: 7%;
}

.ads-content {
    
    width: 86%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ads-content-left {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
}


.ads-content-right {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.ads-content-inner {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.ads-content-middle {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ads-content-button {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ads-btn {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #c4b454;
}

.mover-card {
    width: 87%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.mover-img {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #171717;
    border: 0.25px solid #4B4B4B;
}

.mover-info {
    width: 60%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mover-name {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.mover-league {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
}

.mover-price {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mover-top {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.mover-info-top {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mover-info-bottom {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.movers-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

@media (max-width: 1100px) {
    .leftbar {
        display: none;
    }
    .rightbar {
        display: none;
    }
    .topbar {
        display: none;
    }
}

@media (max-width: 1524px) {
    .topbar-middle {
        width: 100%;
    }
}

/* style={{width: 200, height: "100vh", backgroundColor: "black", position: "fixed", left: 0}} */