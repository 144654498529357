.playoff-title{
    font-size: 3em;
    text-align: center;
    font-weight: 600;
    padding-top: 6vh;
    margin-right: 7.5px;
    margin-left: 7.5px;
}
.playoff-subtitle{
    font-size: 1.5em;
    text-align: center;
    margin-right: 7.5px;
    margin-left: 7.5px;
    font-weight: 300
}
.playoff-desc{
    font-size: 1em;
    text-align: center;
    font-weight: 200;
    margin-right: 7.5px;
    margin-left: 7.5px;
}

.left-team{
    display:inline-block;
    width: 45%;
    margin-bottom: 5px;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
}
.middle-team{
    display:inline-block;
    width: 10%;
    margin-bottom: 5px;
    font-size: 1.1rem;
    text-align: center;
    color: gray;
    vertical-align: top;
    padding-top: 5px
}
.right-team{
    display:inline-block;
    width: 45%;
    margin-bottom: 5px;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
}

.banner-img{
    width: 90%;
    border-radius: .7em;
    margin-top: 6vh;
}
.mobile{
    display:  initial
}
.desktop{
    display:none
}

.jersey-img{
    width: 25%
}

.game-div{
    background-color: white;
    width: 90%;
    margin-left: 5%;
    border-radius: .7em;
    margin-top: 20px;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    padding-bottom: 1px;
}

.team-btn{
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: .6em;
    width: 60%;
    background-color: rgb(235,235,235);
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200)
}

.logoImg{
    width: 30%;
}

.blk-back{
    background-color: black;
    width: 90%;
    margin-left: 5%;
    border-radius: .7em;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-top: 6vh;
}


.login-div{
    width: 90%;
    margin-left: 5%;
    margin-top: 25px;
    border: .75px solid gray;
    padding: 20px 40px 20px 40px;
}

.login-type{
    font-size: .8em;
    color: gray;
}

.login-input{
    width: 100%;
}

.submit-btn{
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: .6em;
    width: 30%;
    background-color: #c5b358;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200)
}

.share-btn{
    width: 90%;
    background-color: rgb(245,245,245);
    margin-top: 2%;
    border-radius: .7rem;
    padding: 1% 0% 1% 0%;
    margin-left: 5%;
    border: 1px solid gray;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
}
.share-social{
    width: 90%;
    background-color: rgb(245,245,245);
    margin-top: 2%;
    border-radius: .7rem;
    padding: 1% 0% 1% 0%;
    margin-left: 5%;
    text-align: center;
    font-weight: 300;
}

/*Start Spinner*/
.fixed_width{
    width: 1000px !important
}
.right-card{

  text-align: 'center'
}
.container {
  position: relative;
  margin: 10px auto;
  width: 80%;
  min-height: 300px;
  z-index: 1;
  perspective: 1000;
  

}
.innercontainer {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1.0s linear;
}
.container:hover .innercontainer {
  transform: rotateY(180deg);
  box-shadow: -5px 5px 5px #aaa;
}
.face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 0 2.5px 2px rgb(200, 200, 200);
}
.face.back {
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
  padding: 1px;
  padding-top: 5px;
  color: black;
  text-align: center;
  background-color: white;
  min-height: 300px;
  border: 1px solid gray;
}
.face.front{
  background-color: black;
  color: #c5b358;
  text-align: center;
  padding: 20px;
  border-radius: .7em;
  min-height: 300px;
  
}
.simbull{
    width: 50%;
}
.card-title-spin{
    font-size: 2.9em;
    font-weight: 300;
}
.card-team-spin{
    font-size: 1.4em;
    font-weight: 200;
}

.Spinner{
    border-radius: .7em;
    margin-top: 25px;
    background-color: white;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    width: 90%;
    margin-left: 5%;
    padding: 0%;
    padding-top: 25px;
    padding-bottom: 25px;
    
    

}
.spin-btn{
    margin-top: 10px;
    font-weight: 500;
    background-color: rgb(240,240,240);
    border: 2px solid #c5b358;
    border-radius: .7em;
    font-size: 1.8em;
    width: 60%;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    margin-bottom: 20px;
}
/*End Spinner */

@media only screen and (min-width: 1024px) {

    .mobile{
        display:  none
    }
    .desktop{
        display:initial
    }

    .banner-img{
        width: 60%;
        border-radius: .7em;
        margin-top: 0vh;
    }
    .playoff-title{
        padding-top: 15px;
    }
    .left-team{
        display:inline-block;
        width: 40%;
        padding-left: 5%;
        margin-bottom: 5px;
    }
    .middle-team{
        display:inline-block;
        width: 10%;
        margin-bottom: 5px;
        margin-top: 10px
    }
    .right-team{
        display:inline-block;
        width: 40%;
        padding-right: 5%;
        margin-bottom: 5px;
    }

    .game-div{
        width: 60%;
        margin-left: 20%;

    }
    .share-btn{
        width: 50%;
        background-color: rgb(245,245,245);
        margin-top: 5px;
        border-radius: .7rem;
        padding: 5px 0% 5px 0%;
        margin-left: 25%;
        border: 1px solid gray;
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    }

    .share-social{
        width: 50%;
        background-color: rgb(245,245,245);
        margin-top: 5px;
        border-radius: .7rem;
        padding: 0px 0% 0px 0%;
        margin-left: 25%;
        text-align: center;
        font-weight: 300;
    }

    .logoImg{
    width: 10%;
    }

    .blk-back{
        background-color: black;
        width: 70%;
        margin-left: 15%;
        border-radius: .7em;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .login-div{
        width: 30%;
        margin-left: 35%;
        margin-top: 25px;
    }
    .team-btn{
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: .6em;
        width: 30%;
        background-color: rgb(235,235,235);
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200)
    }

    /* Spinner */
    .container{
        width: 40%;
        min-height: 250px;
    }
    .face.back {
    min-height: 250px;
    }
    .face.front{
min-height: 250px;
    }

    .simbulll{
        width: 20%;
    }
    .card-title-spin{
        font-size: 2.4em;
        font-weight: 300;
    }
    .card-team-spin{
        font-size: 1em;
        font-weight: 200;
    }
    .Spinner{
        width: 40%;
        margin-left: 30%;
    
    }
    .spin-btn{
        margin-top: 10px;
        font-weight: 500;
        background-color: rgb(240,240,240);
        width: 50%;
    }
}