.Teammates{
    padding-top: 7vh;
    background-color: #fffafa;
    min-height: 100vh;
}
@media only screen and (min-width: 1024px) {
    .Teammates{
        padding-top: 2vh;
        padding-left: 25%;
        padding-right: 25%;
        
    }
}
.TeammateShow{
    text-align: center;
}
.teammate-show-btn{
    width: 33%;
    border: 1px solid #e0cb07;
    outline: none;
    background-color: #fffafa;
    color: #c5b358;
}
.tsb-select{
    background-color: #e0cb07;
    color: white;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}

