.signup-wrapper {
    overflow-x: hidden;
}

.signup-container {
    display: flex;
    width: 300vw;
    flex-direction: row;
    margin-top: 0px;
    padding-top: 0px;
    transition: transform .2s ease-out;
}

.signup-container > div {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: calc(10vh + 0px);
    padding-left: calc(15vw - 22px);
    padding-right: calc(15vw - 22px);

    background-color: #000;
    color: #FFF;
}

.signup-title {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px;
}

.signup-subtitle {
    font-weight: 800;
    font-size: 1.4em;
    color: #C4B454;
}

.signup-highlight {
    color: #C4B454;
}

.signup-title p {
    font-family: 'serif';
    font-size: 0.8em;
}

/* region Generic Inputs */

.signup-form-input {
    width: 100%;
    text-align: left;
    position: relative;
    margin-bottom: 1.5em;

    border: 2px solid #FFF;
    border-radius: 6px;

    transition: border-color 0.2s ease-out;
}

.signup-code-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 1.5em;
}

.signup-code-container:hover .signup-code-input,
.signup-code-container:focus-within .signup-code-input {
    border-color: #8f8341;
}

.signup-code-input {
    width: 50px;
    height: 50px;
    font-size: 2em;
    border: 2px solid #FFF;
    border-left: none;
    border-right: none;

    text-align: center;
    background-color: #000;
    color: #c5c5c5;
    caret-color: #C4B454;

    outline: none !important;
    transition: border-color .3s ease-out, color .2s ease-out;
}

.signup-code-input:focus {
    border-color: #C4B454 !important;
    color: #FFF;
}

.signup-code-input::placeholder {
    text-align: center;
    color: #bbbbbb;
    transition: color .2s ease;
}

.signup-code-input:hover::placeholder,
.signup-code-input:focus::placeholder {
    color: #EDEDED;
}

.signup-code-input:first-child {
    border-left: 2px solid;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.signup-code-input:last-child {
    border-right: 2px solid;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

/* Validation */
.signup-form-input .invalid-feedback {
    position: absolute;
    bottom: -1.5em;
}

/* Icon */
.signup-form-input .input-group-text {
    min-width: 48px;

    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    color: #FFF;
    background-color: #000;
    font-size: 1.2em;
    text-align: center;

    justify-content: center;
}

/* Input */
.signup-form-input .form-control {
    padding: .6rem .75rem .6rem 0;
    height: calc(1.5em + 1.2rem + 2px);

    border: none;
    outline: none !important;
    box-shadow: none !important;

    color: #c5c5c5;
    background-color: #000;
    caret-color: #C4B454;

    transition: color 0.2s ease-out;
}

.signup-form-input input:-webkit-autofill {
    background-color: #000 !important;
    background-clip: content-box !important;
}

/* Left align date input placeholder */
.signup-form-input input::-webkit-date-and-time-value {
    text-align: left;
}

.signup-form-input:hover,
.signup-form-input:hover .form-control.secondary{
    border-color: #8f8341 !important;
}

.signup-form-input:focus-within,
.signup-form-input:focus-within .form-control.secondary{
    border-color: #C4B454 !important;
}

.signup-form-input .form-control:hover,
.signup-form-input .form-control:focus {
    color: #FFF;
}

.signup-form-input .form-control::placeholder {
    color: #bbbbbb;
    text-align: left;
}

.signup-form-input .form-control.secondary {
    padding-left: .6rem;
    border-left: 2px solid white;
    transition: border-color .2s ease-out;
    z-index: 4;
}

.signup-form-input .btn-form-signup {
    width: auto;
    border-radius: 0 4px 4px 0;
    margin: 0;
}

/* endregion */
/* region Checkbox */

.signup-form-checkbox {
    width: 100%;

    display: block;
    position: relative;

    color: #949494;

    padding-left: 30px;
    cursor: pointer;
    font-size: 0.8em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signup-form-checkbox label {
    cursor: pointer;
}

.signup-form-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.signup-form-checkbox span {
    position: absolute;
    top: 6px;
    bottom: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #000;
    border: 2px solid #C4B454;
    border-radius: 8px;

    transition: background-color 0.2s ease-out;
}

.signup-form-checkbox:hover input ~ span {
    background-color: #5e531f;
}

.signup-form-checkbox input:checked ~ span {
    background-color: #C4B454;
}

.signup-form-checkbox span:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c"; /* Checkmark */
    position: absolute;
    display: block;
    top: -2px;
    font-size: 1.25em;
    color: white;
    opacity: 0;

    transition: opacity .2s ease-out;
}

.signup-form-checkbox input:checked ~ span:after {
    opacity: 1;
}

/* endregion */
/* region Signup Button */


/* Cant use btn-signup or it'll clash */
.btn-form-signup {
    padding: 0.25em .75em;
    width: 100%;
    border: 2px solid transparent;
    background-color: #C4B454;
    border-radius: 3em;
    color: black;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 3em;

    box-shadow: none;

    transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
}

.btn-form-signup:focus,
.btn-form-signup:hover,
.btn-form-signup:disabled,
.btn-form-signup.disabled,
.btn-form-signup:active {
    color: black;
    background-color: #C4B454 !important;
    border-color: #C4B454 !important;
}

.btn-form-signup:not(:disabled):not(.disabled):hover,
.btn-form-signup:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 180, 84, 0.4) !important;
    color: #FFF !important;
}

/* endregion */
/* region Legal */

.signup-form-info {
    display: flex;
    width: 100%;
    font-size: 0.8em;
    flex-direction: row;
    justify-content: space-between;
    margin-top: .25em;
    margin-bottom: 2em;
    color: #949494;
}

.signup-form-info a,
.signup-form-info button {
    color: #8d8d8d;
    text-decoration: underline;
    transition: all .15s ease-out;
}

.signup-form-info a:hover,
.signup-form-info button:hover {
    color: #aeaeae;
}

.signup-form-info button {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border: none !important;
}

.signup-form-info a:first-child {
    margin-right: .3em;
    text-align: right;
    flex-basis: 0;
    flex-grow: 1;
}

.signup-form-info a:last-child {
    margin-left: .3em;
    text-align: left;
    flex-basis: 0;
    flex-grow: 1;
}

.signup-form-feedback {
    color: #dc3545;
    font-size: 90%;
    text-align: center;
    display: block;
    margin-bottom: .5rem;
}

.signup-referral-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    text-align: center;
}

.signup-referral-info p {
    color: #d3d3d3;
}

.signup-referral-panel {
    width: 100%;
}

.signup-referral-panel .signup-form-input {
    margin-bottom: 0;
    border-radius: 6px 6px 0 0;
    width: 100%;
}

.signup-referral-panel .form-control {
    text-align: center;
}

.signup-referral-panel .btn-form-signup {
    width: 100%;
}

.signup-referral-panel .col-12:not(:first-child):last-child .btn-form-signup {
    border-radius: 0 0 6px 6px;
}

.signup-referral-panel .col-6:not(:last-child) .btn-form-signup {
    border-radius: 0 0 0 6px;
    border-right: 1px solid #333;
}

.signup-referral-panel .col-6:last-child .btn-form-signup {
    border-radius: 0 0 6px 0;
    border-left: 1px solid #333;
}

/* region Media Queries */

@media (min-width: 600px) {
    .signup-referral-info p {
        max-width: 80%;
    }
}

@media (min-width: 600px) and (max-width: 989.9px) {
    .signup-form-input, .signup-form-checkbox, .signup-form-info {
        width: 60vw;
    }

    .btn-form-signup, .signup-referral-panel {
        width: 40vw;
    }
}

@media (min-width: 990px) {
    .signup-form-input, .signup-form-checkbox, .signup-form-info {
        width: 40vw;
    }

    .btn-form-signup, .signup-referral-panel {
        width: 20vw;
    }
}

/* endregion */