.TeamReport{
    padding-top: 9vh;
    background-color: rgb(245, 245, 245);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10vh;
}

.Reports-title{
    font-size: 1rem;
    font-weight: 600;
}
.Reports-item{
    font-size: 1.5rem;
    font-weight: 200;
}
.Reports-body{
    font-weight: 200;
}

.InputReport{
    padding: 3%;
    padding-top: 10vh;
    min-height: 100vh;
    padding-bottom: 10vh;
    background-color: rgb(245, 245, 245);
    
}
.InputReport-head{
    font-size: .8rem;

}
.InputReport-input{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 7px;
    font-size: 1.2rem;
    padding: 2px;
}
.InputReport-submit{
    padding: 5px;
    border-radius: 0.8rem;
    min-width: 60%;
    margin-left: 20%;
    margin-bottom: 7px;
    font-size: 1.2rem;
    border: none;
    outline: none;
    background-color: #c5b358;

}

@media only screen and (min-width: 1024px) {
    .TeamReport{
        padding-top: 5px;
    }
}