.TeammateProfile{
    padding: 10px;
    padding-top: 0px;
    background-color: #fffafa;
    
}
@media only screen and (min-width: 1024px) {
    .TeammateProfile{
        padding-left: 25%;
        padding-right: 25%;
    }
}
.TeammateProfile-User{
    display: inline-block;
    width: 65%;
    padding-left: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TeammateProfile-DisplayName{
    text-align: left;
    font-size: 2rem;
    font-weight: 600;

    text-overflow: ellipsis;
}
.TeammateProfile-Username{
    text-align: left;
    font-size: 1rem;
    color: gray;
}
.TeammateProfile-Member{
    text-align: right;
    display: inline-block;
    width: 35%;
    padding-right: 5px;
    vertical-align: top;
}
.TeammateProfile-Member-Color{
    font-weight: 600;
    font-size: 2rem;
}
.TeammateProfile-Member-Follow{

}
.TeammateProfile-Member-Follow-Btn{
    border: 1px solid #e0cb07;
    border-radius: 1em;
    outline: none;
    color: white;
    
    background-color: rgb(180,180,180);
    padding: 3px 20px 3px 20px; 
    margin-right: 1px;
    font-weight: 500;
}
.TeammateProfile-Member-Following-Btn{
    border: 1px solid #e0cb07;
    border-radius: 1em;
    outline: none;
    background-color: #fffafa;
    padding: 3px 20px 3px 20px; 
    margin-right: 1px;
    font-weight: 500;
}

.ProfileShow{
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
}
.profile-show-btn{
    width: 33%;
    border: 1px solid #e0cb07;
    outline: none;
    background-color: #fffafa;
    color: #c5b358;
}
.psb-select{
    background-color: #e0cb07;
    color: white;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}
