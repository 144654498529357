.teammate-item-card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 10px;
    border: 1px solid rgb(240,240,240);
    padding: 10px;
    border-radius: .5em;    
    background-color: white;

}
.tic-user{
    font-size: 1.1rem;
    font-weight: 500;
}
.tic-body{
    padding: 3px 3px 3px 3px;
}
.tic-date{
    font-size: .75em;
    color: gray;
    text-align: right;
}