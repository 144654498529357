/*Purchase Receipts*/
.transaction-recipt{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    
    font-family: 'Roboto Slab', serif;
}
.transaction-recipt-modal{
    position:fixed;
    background: white;
    width: 80%;
    max-height: 80%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    overflow-y: auto;
    text-align: center;
    outline: 10px black solid;
    padding-bottom: 5%;
}
.transaction-recipt-head{
    padding: 5%;
    padding-bottom: 3%;
    font-size: 2em;
}
.transaction-recipt-body{
    padding: 5%;
    font-size: 1.2em;
}
.transaction-recipt-img{
    padding: 5%;
}
.transaction-recipt-share{
    margin-top: 5%;
    padding: 5%;
}
.share-buttons-div{
    width: 100%;
}
.share-btn{
    width: 90%;
    margin-left: 2.5%;
    background-color: #c5b358;
    margin-top: 2%;
    border-radius: 1rem;
    padding: 1% 0% 1% 0%
}
.share-close-btn{
    width: 90%;
    margin-left: 2.5%;
    background-color: rgb(197, 197, 197);
    margin-top: 2%;
    border-radius: 1rem;
    padding: 1% 0% 1% 0%
}
.tr-name-sim{
    font-style: italic;
}


@media only screen and (min-width: 1024px) {
    .transaction-receipt{
        overflow-y: auto;
    }
    .transaction-recipt-modal{
        position: fixed;
        background: white;
        width: 60%;

        transform: translate(-50%,-50%);
        overflow-y: auto;
        text-align: center;
        outline: 10px black solid;
        padding-bottom: 5%;
    }
    .transaction-recipt-head{
        padding: 5%;
        padding-bottom: 1%;
        font-size: 2em;
    }
    .transaction-recipt-body{
        padding: 5%;
        padding-bottom: 1%;
        font-size: 1.2em;
    }
    .transaction-recipt-img{
        padding: 5%;
        padding-bottom: 1%;
    }
    .transaction-recipt-share{
        margin-top: 2%;
        padding: 5%;
        padding-bottom: 1%;
    }
}