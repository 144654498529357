.Sportle{
    text-align: center;
    vertical-align: middle;
    padding-top:7vh;
    padding-bottom:15vh;
    background-color: #fffafa;
    min-height: 100vh;
}

@media only screen and (min-width: 1024px) {
    .Sportle{
        text-align: center;
        vertical-align: middle;
        padding-top:1vh;
        padding-bottom:15vh;
        background-color: #fffafa;
        min-height: 100vh;
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}

.sportle-heading{
    width: 14.25%;
    color: gray;
    font-weight: 200;
    font-size: .75rem;
    display: inline-block;
}
.sportle-card{
    margin: 10px;
    margin-top: 2px;
    margin-bottom: 3px;
    padding: 5px;
    padding-bottom: 8px;
    background-color: white;
    border-radius: .3em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.sportle-name{
    text-align: left;
    font-size: 1.15rem;
    font-weight: 500;
}
.sportle-correct{
    background-color: #009d3d;
    width: 14.10%;
    margin-right: 0.18%;
    min-height: 6vh;
    color: white;
    font-weight: 600;
    display: inline-block;
    vertical-align: bottom;
    padding: 3px;
    padding-top: 6px;
    margin-bottom: 5px;
    font-size: .95em;
}
.sportle-incorrect{
    background-color: white;
    width: 14.10%;
    margin-right: 0.18%;
    height: 6vh;
    font-weight: 500;
    display: inline-block;
    vertical-align: bottom;
    padding: 3px;
    padding-top: 6px;
    margin-bottom: 5px;
    font-size: .95em;
    color: gray;
}
.sportle-correct div{

    vertical-align: bottom;
}
.sportle-incorrect div{

    vertical-align: bottom;
}