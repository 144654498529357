.TeammateFeed{
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 12vh;
}
.TeammateFeed-Header{
    font-size: 1.5rem;
    font-weight: 600;
}

.activity-shadow{
    border: none;
    outline: none;
    border-radius: .3em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
}