.a-item{
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
    font-size: 1.25rem;
    margin-bottom: 3%;
    margin-left: 10%;
    color: black;
}
.a-btn{
    border-radius: .8rem;
    text-align: center;
    width: 80%;
}
.ContactUs{
    padding: 2% 10% 10% 10%;
    font-family: 'Roboto Slab', serif;
    padding-top: 7vh;
}
.c-Head{
    font-size: 2rem;
}
.c-txt{

}
.c-name{

}
.c-email{

}
.c-message{

}
.c-btn-div{
    text-align: center;
}
.c-btn{
    border-radius: .8rem;
}
.c-input{
    width: 100%;
    border-radius: .8rem;
    text-align: center;
}

/* User and Edit Account  */
.UserAccount{
    padding: 2% 0% 10% 0%;
    font-family: 'Roboto Slab', serif;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
}
.ua-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
}
.ua-left{
    display: inline-block;
    width: 35%;
    font-weight: 600;
}
.ua-right{
    display: inline-block;
    width: 65%;
    text-align: right;
    font-weight: 400;
}
.ua-edit-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    text-align: center;
}
.EditAccount{
    padding: 2% 0% 10vh 0%;
    font-family: 'Roboto Slab', serif;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
}
.ua-div-e{
    background-color: white;
    margin-bottom: 0%;
    padding: 3% 2% 3% 2%;
    font-size: 1.1rem;
}
.ua-hr{
    padding:0%;
    margin: 0%;
}


/* Header */
.Header{
    background-color: black;
    width: 100%;
    height: 6vh;
    color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    text-align: center;
    font-size: 1.75rem;
    font-family: 'Roboto Slab', serif;
}
.header-btn{
    position: fixed;
    top: 0;
    left: 0;
    height: 6vh;
    color: gray;
    background-color: black;
    font-size: 1rem;
}
.fa-angle-left{
    color: white;
}


/* Transfers */
.Transfers{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.transfer-deposit{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    text-align: center;
}
.transfer-deposit-head{
    font-size: 2rem;
    font-weight: 600;
}
.transfer-deposit-body{
    padding-top: 2%;
    padding-bottom: 2%;
}
.transfer-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    text-align: center;
}
.transfer-deposit-input{
    border-radius: 1rem;
    padding: 5% 0% 5% 0%;
    text-align: center;
    font-size: 1.5rem;
    width: 40%;
    margin-bottom: 5%;
}
.transfer-promo-input{
    border-radius: 1rem;
    padding: 3% 2% 3% 2%;
    text-align: center;
    font-size: 1.5rem;
    width: 80;
    margin-bottom: 2%;
}
.transfer-depsoit-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}
.reoccurringDepositDiv{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
}
.reoccurringDepositSpan{
    font-size: 1rem;
    font-weight: 400;
}

/* Withdraw */
.Withdraw{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.withdraw-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.withdraw-head{
    font-size: 2rem;
    font-weight: 600;
}
.withdraw-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.withdraw-body-input{
    border-radius: 1rem;
    font-size: 1.2rem;
    padding: 2.5% 0% 2.5% 0%;
    text-align: center;
}
.withdraw-cash{
    font-size: .8rem;
}
.withdraw-input{
    border-radius: 1rem;
    padding: 5% 0% 5% 0%;
    text-align: center;
    font-size: 1.5rem;
    width: 40%;
    margin-bottom: 5%;
}
.withdraw-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}
.withdraw-textarea{
    width: 80%;
    height: 20vh;
    border-radius: 1rem;
}

/* RequestPennant */
.RequestPennant{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.rp-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.rp-head{
    font-size: 2rem;
    font-weight: 600;
}
.rp-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.rp-cash{
    font-size: .8rem;
}
.rp-input{
    border-radius: 1rem;
    padding: 5% 0% 5% 0%;
    text-align: center;
    font-size: 1.25rem;
    width: 50%;
    margin-bottom: 5%;
}
.rp-option{
    font-size: 1rem;
}
.rp-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}


/* Subscription */
.Subscription{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.subscription-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.subscription-head{
    font-size: 2rem;
    font-weight: 600;
}
.subscription-body{
    padding-top: 1%;
    padding-bottom: 1%;
}
.subscription-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}
.cancelDiv{
    margin-top: 2%;
    background-color: white;
    text-align: center;
    padding: 3%;
}

/* Subscription Two */
.SubscriptionTwo{
    padding: 2% 3% 20% 3%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.subtwo-title{
    font-size: 1.5rem;
    text-align: left;
    font-weight: 600;
    margin-bottom: 1%;
}
.subtwo-subtitle{
    font-size: 1rem;
    text-align: left;
    font-weight: 200;
    margin-bottom: 5%;
    color: gray;
}
.subtwo-box{
    text-align: left;
    background-color: white;
    padding: 5%;
    width: 100%;
    margin-left: 0%;
    -webkit-box-shadow: 0 0 5px 2px rgb(200, 200, 200);;
    -moz-box-shadow: 0 0 5px 2px rgb(200, 200, 200);;
    box-shadow: 0 0 5px 2px rgb(200, 200, 200);
    border-radius: .5rem;
    margin-bottom: 5%;
}
.subtwo-box-head{
    font-weight: 800;
    padding-bottom: 2%;
    font-size: 3rem;
    color: #c5b358;
    -webkit-text-stroke: .5px black;
    text-align: center;
}
.subtwo-box-price{
    font-weight: 400;
    font-size: 2rem;
    color: black;
    text-align: center;
    margin-top: 0%;
}
.subtwo-box-active{
    font-weight: 200;
    font-size: .8rem;
    color: rgb(0, 160, 0);
    text-align: center;
    margin-top: 0%;
    -webkit-text-stroke: .5px rgb(0, 160, 0);
}
.subtwo-box-cancel{
    font-weight: 200;
    font-size: .8rem;
    color: red;
    text-align: center;
    margin-top: 0%;
    -webkit-text-stroke: .5px red;
}
.subtwo-box-length{
    font-weight: 400;
    font-size: 1rem;
    color: black;
    text-align: center;
    margin-top: 0%;
}
.subtwo-box-img-div{

}
.subtwo-box-img{
    width: 50%;
    margin-left: 25%;
}
.subtwo-buy-btn-div{
    text-align: center;
    margin-bottom: 5%;
}
.subtwo-buy-btn{
    text-align: center;
    border-radius: 1rem;
    background-color: #c5b358;
    padding: 2% 4% 2% 4%;
    width: 40%;
}
.subtwo-box-body{
    font-weight: 400;
    font-size: 1.35rem;
    white-space: initial;
}
.subtwo-box-item{
    padding-bottom: 3%;
}
.subtwo-box-item-body{
    font-weight: 200;
    font-size: 1rem;
    padding-left: 6%;
}
.subtwo-section{
    font-size: 0.8rem;
}
.subtwo-receipt-view{
    text-align: center;
}
.subtwo-receipt-view-btn{
    color: black;
    border: none;
    outline: none;
    background-color: white;
    text-decoration: underline;
}
.subtwo-receipt-table{
    width: 100%;
    text-align: center;
    margin-top: 3%;
}
.subtwo-receipt-table-headers{

}
.subtwo-receipt-head-hr{
    background-color: black;
    margin-top: 0%;
    margin-bottom: 2%;
}
.subtwo-receipt-hr{
    background-color: black;
    margin-top: 0%;
    margin-bottom: 2%;
    opacity: .2;
}
.subtwo-cancel-div{
    width: 70%;
    margin-left: 15%;
    text-align: center;
    margin-top: 5%;
    font-size: 1.1rem;
    padding-bottom: 5%; 
}
.subtwo-cancel-body{
    width: 100%;
    margin-left: 2.5%;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    padding-top: 3%; 
    text-decoration: line-through;
}
.subtwo-cancel-btn{
    border-radius: .8rem;
    padding: 1% 2%;
}

/* Referrals */
.Referrals{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.referrals-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.referrals-head{
    font-size: 2rem;
    font-weight: 600;
}
.referrals-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.ref-create-btn{
    border-radius: 1rem;
    padding: 1% 3% 1% 3%;
}
.ref-copy-btn{
    border-radius: 1rem;
    padding: 1% 3% 1% 3%;
}

/* Notifications */
.Notifications{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.notifications-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.notifications-head{
    font-size: 2rem;
    font-weight: 600;
}
.notifications-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.notifications-item{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    vertical-align: top;
}
.notifications-left{
    display: inline-block;
    width: 85%;
    font-weight: 600;
}
.notifications-right{
    display: inline-block;
    width: 15%;
    text-align: right;
    font-weight: 400;
    vertical-align: top;
}



/* GiveReview */
.GiveReview{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
}
.givereview-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.givereview-head{
    font-size: 2rem;
    font-weight: 600;
}
.givereview-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.givereview-left{
    display: block;
    text-align: center;
    font-weight: 600;
}
.givereview-right{
    display: block;
    text-align: center;
    font-weight: 400;
    width: 100%;
    font-size: 1.5rem;
}
.givereview-select{
    width: 20%;
    background-color: rgb(237, 237, 237);
}
.givereview-txt{
    width: 95%;
    margin-top: 5%;
}


/*Paypal */
.Transfer-Paypal-Sheet{
    padding: 5%;
    margin-top: 7vh;
    text-align: center;
    font-family: 'Roboto Slab', serif;
}
.Transfer-Paypal-Sheet-Div{
    
}
.Transfer-Paypal-Sheet-Title{
    font-weight: 600;
    font-size: 3rem;
}
.Transfer-Paypal-Sheet-Amount{
    display: inline-block;
    width: 50%;
    text-align: right;
}
.Transfer-Paypal-Sheet-Amount-txt{
    display: inline-block;
    width: 50%;
    text-align: left;
}


/* PayPal */
.paypal-subscription-amount{
    padding: 5%;
    font-size: 2rem;
}

/* Tutorial */
.Tutorial{
    padding: 2% 0% 20% 0%;
    padding-top: 6vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    text-align: center;
}
.tutorial-div{
    margin-top: 3%;
}
.tutorial-div-title{
    font-size: 2rem;
}
.tutorial-video{
    width: 100%;
    margin-left: 0%;
    border-radius: 1rem;
    margin-top: 1%;
    margin-bottom: 5%;
}
.tutorial-contents{
    text-align: left;
    padding-left: 3%;
    font-size: 1.25rem;
}
.tutorial-contents-item{
    padding-left: 5%;
    font-size: 1rem;
    text-decoration: underline;
}


/*Deposit*/
.deposit-btn{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 1px solid gray;
    font-size: 1.5em;
    color: gray;
    border-radius: 0.1em;
}

.deposit-btn-gold{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 3px solid #c5b358;
    font-size: 1.5em;
    color: #c5b358;
    border-radius: 0.1em;
    font-weight: 500;
}



@media only screen and (min-width: 1024px) {
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
    .Account{
        padding-top: 5%;
        width: 100%;
    }
    .Header{
        height: 9vh;
        font-size: 3.5rem;
        padding-bottom: 2%;
        display: none;
    }
    .header-btn{
        height: 9vh;
        font-size: 1.5rem;
        width: 10%;
        display: none;
    }
    .SubscriptionTwo{
        padding-left: 33%;
        padding-right: 33%;
    }
}