.Portfolio{
  font-size: 100%;
}
.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid green;
}
.graph-btn1{
    border-radius: 1rem;
    width: 20%;
    margin-left: 0%;
    margin-right: 0%;
    background-color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    color: #c5b358;
    font-size: 1rem;
    padding: 0;
    border: none;
    background: none;
    margin-bottom: 0
}
.NotificationBox{
  box-shadow: 0px 4px 8px 2px rgba(0,0,0,0.2);
  border-radius: 0.3em;
  border: 1px solid rgb(180,180,180);
  padding: 10px;
  display: inline-block;
  margin: 10px;
  min-width: 90%;
  max-width: 95%!important;
  height: 100%!important;
  padding-bottom: 100%;
  margin-bottom: -100%;
} 

.portfolioDiv{
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 7px;
    vertical-align: top;
}
.portfolioBox{
    background-color: white;
    padding: 2% 3%;
    display: inline-block;
    border-radius: .5rem;
    vertical-align: top;
    margin-right: 7px;
    border: .5px solid rgb(200,200,200);
}

.recurring{
    background-color: white;
    padding: 2% 3%;
    width: 99%;
    margin-left: .5%;
    margin-top: 10px;
    display: inline-block;
    border-radius: .5rem;
    vertical-align: top;
    margin-right: 7px;
    border: .5px solid #c5b358;
    text-align: center;
    font-weight: 300;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.2);
    
}

.modalOpen {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.team-img-port2{
  width: 100%;
  max-height: 6vh;
}

.holding-img{
  width: 15%;
  margin-top: -5px
}

.buyTeam-btn-div{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 9vh;
  text-align: center;
}
.buyTeam-btn{
  box-shadow: 0px 16px 32px 8px rgba(0,0,0,0.2);
  border-radius: 0.3em; 
  font-size: 1.7em;
  padding: 1%;
  background-color: #c5b358;
  width: 98%;
  border: none;
  font-weight: 200;
}

.p-payouts-div-2{
    text-align: center;
    margin-top: 5px;
}
.p-payouts-btn-2{
    border-radius: .3rem;
    border: none;
    outline: none;
    font-size: 1.3rem;
    background-color: #c5b358;
    box-shadow: 0px 2.5px 10px 5px #d1d1d1;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fullOrderBook-desktop{
    display: none;
  }

#spinner {
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count:1;
  animation-duration: 1s;
  /*transform-style: preserve-3d;*/
  
}
@keyframes spinner {
from {
    transform: scale(0.1);
}
to {
    transform: scale(1);
}
}

.TeamPageDiv{
  padding-top: 7vh; 
  padding-bottom: 19vh;
  font-size: 100%;
}

.Value{
  padding-top: 7vh;
  padding-left: 5px;
  padding-right: 5px;
}

/*Canva*/
.simbull16{
  position: relative;
  width: 80%;
  margin-left: 10%;
  height: 0;
  padding-top: 133.333%;
  padding-bottom: 48px; 
  box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em; 
  overflow: hidden;
  border-radius: 8px; 
  will-change: transform;
}
.sim16-body{
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

@media only screen and (min-width: 768px) {
  .team-img-port2{
    width: 75%;
    max-height: 10vh;
  }
}

@media only screen and (min-width: 1024px) {
  .Portfolio{

  }
  .Value{
    padding-top: 1vh;
    padding-left: 5px;
    padding-right: 5px;
  }
  .team-img-port2{
    width: 50%;
    max-height: 10vh;
  }
  .DesktopContainer{
      width: 100%;
      padding-left: 25%;
      padding-right: 25%;
  }
  .TeamPageDiv{
    padding-top: 1vh; 
    padding-bottom: 16vh;
    font-size: 100%;
  }
  .holding-img{
    width: 7%
  }
  .buyTeam-btn-div{
    left: 0;
    padding-bottom: 3vh;
  }
  .buyTeam-btn{
    box-shadow: 0px 16px 32px 8px rgba(0,0,0,0.2);
    border-radius: 0.3em; 
    font-size: 1.8em;
    background-color: #c5b358;
    width: 40%;
    border: none;
    font-weight: 200;
    
  }

  .fullOrderBook-mobile{
    display: none;
  }
  .fullOrderBook-desktop{
    display: inline;
  }
}