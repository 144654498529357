.Teammate-TimeSorter{
    vertical-align: bottom;
    display: inline-block;
    width: 20%;
    text-align: right;
}
.Teammate-TimeSorter-Display{
    font-size: 1.2em;
    font-weight: 300;
    margin-right: 5px;
    color: gray;
    
}
.Teammate-TimeSorter-Drop{

}
.Teammate-TimeSorter-Div{
    background-color: white;
    padding: 3px;
    margin-right: 5px;
    border-radius: .3rem;
    border: 1px solid gray;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 20px 80px 30px;
    padding-top: 15px;
}
.Teammate-TimeSorter-Item{
    display: block;
    width: 25vw;
    margin-bottom: 15px;
    margin-right: 5px;
    margin-left: 5px;
    right: 5;
    border-radius: .3rem;
    border: none;
    outline: none;
    font-size: 1.3em;
    background-color: #c5b358;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 20px 10px;
}
.Teammate-TimeSorter-Item:hover{
    background-color: #e0cb07;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 10px 40px 20px;
}