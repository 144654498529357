.WelcomeTour{
    padding: 2% 5% 2% 5%;
    font-family: 'Roboto Slab', serif;
}
.welcome-Head{
    font-size: 2rem;
}
.welcome-btns {
    margin-top: 2%;
    text-align: center;
} 

.input-desc{
    font-size: 0.7em;
    color: gray;
    margin-left: 10%;
}
.input-input{
    font-size: 1em;
    color: black;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 5px;
    border: 1px solid gray;
    padding-left: 5px;
    text-align: center;
    border-radius: 0.2rem;
}

.makemoney{
    margin-top: 11px;
    text-align: center;
}
.trade-box{
    text-align: center;
    border: 1px solid black;
    border-radius: 0.8em;
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
}

.deposit-input{
    font-size: 1.5em;
    color: black;
    width: 78.5%;
    margin-bottom: 5px;
    border: 1px solid gray;
    padding-left: 5px;
    text-align: center;
    border-radius: 0.2rem;
}
.deposit-btn{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 1px solid gray;
    font-size: 1.5em;
    color: gray;
    border-radius: 0.1em;
}

.deposit-btn-gold{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 3px solid #c5b358;
    font-size: 1.5em;
    color: #c5b358;
    border-radius: 0.1em;
    font-weight: 500;
}

@media only screen and (min-width: 1024px) {
    .WelcomeTour{
        padding-left: 33%;
        padding-right: 33%;

    }


}