.email-capture{
    padding: 15px;
    padding-top: 6vh;
}
.back-btn{
    display: block;
    outline: none;
    border: none;
    background: white;
    text-decoration: underline;
    color: rgb(100,100,100);
    margin-bottom: 50px;
}
.reset-head{
    margin-bottom: 15px;
    font-weight: 700;
}
.reset-body{
    color: gray;
    margin-bottom: 25px;
}
.reset-input-head{
    color: gray;
}
.reset-input-div{
    margin-left: 2px;
    width: 100%;
}
.reset-input{
    width: 100%;
    border-radius: .8em;
    margin-bottom: 10px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
    padding-left: 10px;
}
.reset-input-btn{
    width: 100%;
    background-color: #c5b358;
    color: white;
    padding: 8px;
    border: none;
    outline: none;
    border-radius: .95rem;
    font-weight: 600;
}

.check{
    text-align: center;
}
.check-head{
    padding-top: 20vh;
    font-weight: 700;
}
.check-div{
    min-width: 50%;
    color: #c5b358;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    
    outline: none;
    border-radius: .9rem;
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 1.75rem;
}
.skip-btn{
    min-width: 40%;
    background-color: white;
    color: gray;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    outline: none;
    border-radius: .9rem;
    font-weight: 600;
    background-color: white;
    margin-bottom: 40px;
}
.check-under{
    font-size: .9em;
    color: gray;
}

.pass-head{
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 2.2rem;
}
.pass-input{
    width: 100%;
    border-radius: .8em;
    margin-bottom: 0px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
    padding-left: 10px;
}
.pass-under{
    font-size: .75em;
    color: gray;
    margin-bottom: 15px;
    margin-left: 3px;
}

@media only screen and (min-width: 1024px) {
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}