.Login{
    min-height: 40vh;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    padding-bottom: 15%;
}
.login-head{
    font-size: 2rem;
    padding-top: 12%;
    padding-bottom: 10%;
    font-weight: 900;
    
}
.login-div{
    padding-bottom: 5%;
}
.login-div-head{
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: underline;
}
.login-div-input{
    border-radius: .75rem;
    width: 80%;
    margin: 1%;
    text-align: center;
    font-size: 1.2rem;
}
.login-div-check{
    -webkit-appearance:none;
    width:20px;
    height:20px;
    background:white;
    border-radius:5px;
    border:2px solid #555;
}
input[type='checkbox']:checked {
    background: black;
}
.login-div-btn{
    display: block;
    width: 60%;
    margin-left: 20%;
    border-radius: 0.75rem;
    margin-top: 2%;
    background-color: lightgray;
    color: black
}
.login-switch{
    padding: 5%;
}
.login-forgot{
    border-radius: 1rem;
}
.loginPage{
    margin-top: 9vh;
}
@media only screen and (min-width: 1024px) {
    .loginPage{
        margin-top: 12vh;
    }
}