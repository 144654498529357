.GettingStarted{
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20%;
    margin-top: 10vh;
    text-align: center;
}
.gs-head{
    font-size: 2rem;
    font-weight: 900;
}
.gs-sub{

}
.gs-img{
    width: 50%;
}
.gs-car-txt{
    text-align: center;
    font-size: .9rem;
}
@media only screen and (min-width: 1024px) {
    .gs-img{
        width: 15%;
    }
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}