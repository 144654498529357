/*
 *  Flex Layout Specifics
*/
main{
  display:flex;
  flex-direction:row;
  font-size: .85rem;
}
.round{
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:25%;
  list-style:none;
  padding:0;
}
.firstRound{
    width: 25%;
}
.roundFinal{
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:50% !important;
  list-style:none;
  padding:0;
}
.round .spacer{ flex-grow:1; }

.round .spacer:first-child,
.round .spacer:last-child{ 
    flex-grow:.5; }

.round .game-spacer{
    flex-grow:.75;
}
.round .gameRight-spacer{
    flex-grow:.75;
}
.final-four{
    width: 50%;
}

/*
 *  General Styles
*/


li.game{
    padding-left:3px;
    font-weight: 400;
}

li.game.winner{
    font-weight:bold;
}
.gameSpan{
    margin-left: 3px;
}
.gameRightSpan{
    
}
.seed{
    color: gray;
    font-size: .8em;
    font-weight: 300;
}
li.gameRight{
    /*float:left;*/
    margin-right:3px;
}

li.game-top{ 
    border-bottom:1.25px solid black; 
    white-space: nowrap;
    overflow: visible!important;
    z-index: 100;
    font-weight: 500;
    direction: ltr;
    width: 100%;
}

li.game-spacer{ 
    border-right:1px solid black;
    min-height: 30px;
    background-color: white;
    background-image: linear-gradient(to right, rgb(240,240,240) , white);
}
li.gameRight-spacer{ 
    border-left:1px solid black;
    min-height: 30px;
    background-image: linear-gradient(to left, rgb(240,240,240) , white);
}

li.game-bottom{ 
    border-top:1.25px solid black;
    white-space: nowrap;
    overflow: visible !important;
    direction: ltr;
    z-index: 100;
    width: 100%;
    /*box-shadow: 0px 5px 5px -5px #c5b358,
    0px -5px 3px -3px rgb(200,200,200);*/
    font-weight: 500;
}
.rightAlign{
    text-align: right;
    width: 100%;
    overflow: visible;
    direction: rtl;
}
.rules-canva{
    position: relative; width: 100%; height: 0; padding-top: 75.0000%;
    padding-bottom: 48px; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
    border-radius: 8px; will-change: transform;
    padding-left: 15px;
    padding-right: 15px;
}
.iframe-canva-rules{
    position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

.Bracket-signup{
    width: 95%;
    margin-left: 2.5%;
}

.Top-CTA-btn-signup{
    width: 98%;
    margin-left: 1%;
    margin-right: 0%;
    padding: 12px;
    font-size: .8em;
    padding-left: 2px;
    padding-top: 17.5px;
    padding-right: 2px;
    border-radius: .3em;
    background-color: #e0cb07;
    color: black;
    border: none;
    outline: none;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-input-signup{
    width: 100%;
    margin-left: 0%;
    padding: 12px;
    border-radius: .2em;
    border: 2px solid rgb(200,200,200);
    margin-bottom: 5px;
}

.picks-submit-btn{
    background-color: #c5b358;
    border: none;
    border-radius: .3em;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
}
.picks-submit-btn:hover{
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: #e0cb07;
    box-shadow: 0 8px 36px rgba(0, 0, 0, 0.2);
}

.viewpicks{
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 6vh;
    background: rgba(224, 224, 224, .8);
    font-weight: 500;
}
.viewpicks_nouser{
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(224, 224, 224, .8);
    font-weight: 500
}
.z10000{
    z-index: 10000;
}

.viewp{
    display: block;
}
.hidep{
    display: none;
}

@media only screen and (min-width: 1024px) {

    .viewpicks{
        top: 0;
        right: 0;
        margin-top: 11vh;
        background: rgba(224, 224, 224, .8);
        font-weight: 500;

    }
    .viewpicks_nouser{
        top: 0;
        right: 0;
        background: rgba(224, 224, 224, .8);
        font-weight: 500;
    }
    
    .round{
        width: 17.5%;
    }
    .firstRound{
        width: 30%;
    }
    .Top-CTA-btn-signup{
        width: 27.5%;
        margin-left: 0%;
        margin-right: 0%;
        font-size: 1em;
        padding: 12px;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: .3em;
        background-color: #e0cb07;
        color: black;
        border: none;
        outline: none;
        -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-input-signup{
        width: 70%;
        margin-left: 0%;
        margin-right: 2.5%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .visible{
        width: 50%;
        margin-left: 25%;
    }
}