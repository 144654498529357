.TeammateRequests{
    background-color: white;    
    margin: 0%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border-radius: .3rem;
    margin-top: 5px;
    margin-bottom: 5px;
    color: gray;
    font-weight: 300;
}
.TeammateRequest-Message{
    display: inline-block;
    width: 90%;
}
.Request-Popup{
    background-color: rgb(245,245,245);
    min-width: 90vw;
    min-height: 50vh;
    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.8) 0px 40px 180px 70px;
    border-radius: .3rem;
    padding: 20px;
}
.Request-Popup-Header{
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
}