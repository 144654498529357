.LandingPage3{
    background: rgb(235,235,235);
    min-height: 100vh;
    /*padding-right: %;
    padding-left: 5%;*/
    text-align: left;
        font-family: 'Roboto Slab', serif;
    
}
.league-btns{
    text-align: center;
    margin-bottom: 10px;
}
.filterBTN{
    border: 1px solid #c5b358;
    outline: none;
    font-size: .9rem;
    border-radius: 1rem;
    margin-right: 2px;
    margin-top: 2px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    color: gray;
    background-color: rgb(235,235,235);
}
.filterBTN-selected{
    border: none;
    outline: none;
    border: 1px solid #c5b358;
    outline: none;
    font-size: .9rem;
    border-radius: 1rem;
    margin-right: 2px;
    margin-top: 2px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    color: black;
    background-color: #c5b358;
}
.heading{
    font-size: 1.2rem;
    font-weight: 300;
}
.teams-title{
    font-size: 1.8rem;
    margin-left: -1.5%;
    font-weight: 600;
    text-align: left;
}
.teams-emoji{
    font-size: 1.25rem;
}
.teams-third-title{
    font-size: .9rem;
    font-style: italic;
}
.LP3-teamcard{
    display: inline-block;
    background-color: white;
    margin: .5%;
    margin-left: 1%;
    margin-right: 0.5%;
    border-radius: .4rem;
    min-width: 30%;
    max-width: 50%;
    display: inline-block;
    text-align: left;
    font-size: 0.9rem;
    -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
}
.LP3-teamcard-header-left{
    display: inline-block;
    width: 70%;
    padding: 5px;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.LP3-teamcard-header-right{
    text-align: right;
    display: inline-block;
    width: 30%;
    padding: 5px;
    font-weight: 300;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.LP3-teamcard-subheader{
    text-align: center;
    width: 100%;
    font-size: 0.6rem;
    padding-top: 0px;
    margin-top: 0px;
}
.LP3-teamcard-stats{
    font-size: .6rem;
    font-weight: 600;
    padding-left: 7px;
    margin-bottom: 0;
    padding-bottom: 0;
}
.LP3-teamcard-statsdiv{
    line-height: 80%;
    margin-bottom: 3px;
}
.LP3-teamcard-stat{
    display: inline-block;
    width: 50%;
    font-size: .45rem;
    margin-top: 0;
    padding-top: 0;
    
}
.LP3-teamcard-stat-left{
    display: inline-block;
    width: 60%;
    margin-top: 0;
    padding-top: 0;
    padding-left: 3px;
}
.LP3-teamcard-stat-right{
    display: inline-block;
    width: 40%;
    margin-top: 0;
    padding-top: 0;
}
.LP3-teamcard-trades{
    margin-top: 0px;
    margin-bottom: 4px;
}
.LP3-teamcard-trade{
    font-size: 0.7rem;
    color: gray;
    font-weight: 300;
}
.green{
    color: green;
}
.red{
    color: #ff7d7d;
}
.LP3-teamcard-trade-type{
    display: inline-block;
    margin-right: 0%;
    width: 44%;
    padding-left: 20px;
}
.LP3-teamcard-trade-team{
    display: inline-block;
}
.LP3-teamcard-trade-price{
    display: inline-block;
    width: 56%;
    text-align: right;
    padding-right: 20px
}
.payouts-div{
    margin-left: 0%;
    text-align: center;
    
}
.payout-img{
    width: 33%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}
.threecard{
    background-color: white;
    margin: .5%;
    margin-left: 1%;
    margin-right: 0.5%;
    border-radius: .4rem;
    margin-bottom: 2%;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-size: 1.2rem;
    -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%
}
.threecard-title{
    font-size: 1.55rem;
    font-weight: 600;
}

.Reserves{
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 75px;
    text-align: center;
}

.FAQs3{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 3%;
    padding-top: 1%;
    margin-bottom: 100px;
    margin-top: 55px;
    background-color: white;
    -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    border-radius: 1rem;
}
.FAQs3-title{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
}
.FAQs3-item{
    padding: 1%;
}
.FAQs3-item:hover{
    background-color: rgb(249,249,249)
}
.FAQs3-item-left{
    text-align: left;
    display: inline-block;
    width: 70%;
    font-size: 1.15rem;
    font-weight: 500;
    color: black;
}
.FAQs3-item-right{
    text-align: right;
    display: inline-block;
    width: 30%;
    color: black;
}
.FAQs3-item-body{
    text-align: left;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 25px;
}

.backWhite{
    background-color: white;
    width: 90%;
    margin-left: 5%;
    
}

@media only screen and (min-width: 1024px) {
    .LandingPage3{
        background: rgb(235,235,235);
        min-height: 100vh;
        /*padding-right: %;
        padding-left: 5%;*/
        text-align: left;
            font-family: 'Roboto Slab', serif;
        
    }
    .league-btns{
        text-align: left;
        margin-bottom: 10px;
    }
    .filterBTN{
        border: 1px solid #c5b358;
        outline: none;
        font-size: 1.2rem;
        border-radius: 1rem;
        margin-right: 5px;
        margin-top: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        color: gray;
        background-color: rgb(235,235,235);
    }
    .filterBTN-selected{
        border: none;
        outline: none;
        border: 1px solid #c5b358;
        outline: none;
        font-size: 1.2rem;
        border-radius: 1rem;
        margin-right: 5px;
        margin-top: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        color: black;
        background-color: #c5b358;
    }
    .heading{
        font-size: 1.2rem;
        font-weight: 300;
    }
    .teams-title{
        font-size: 3rem;
        margin-left: -1.5%;
        font-weight: 600;
        text-align: left;
    }
    .teams-emoji{
        font-size: 2rem;
    }
    .teams-third-title{
        font-size: 1.3rem;
        font-style: italic;
    }
    .LP3-teamcard{
        display: inline-block;
        background-color: white;
        margin: .5%;
        margin-left: 1%;
        margin-right: 0.5%;
        border-radius: .4rem;
        min-width: 14.75%;
        max-width: 14.75%;
        display: inline-block;
        text-align: left;
        font-size: 0.9rem;
        -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    }
    .LP3-teamcard-header-left{
        display: inline-block;
        width: 70%;
        padding: 5px;
        font-weight: 600;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .LP3-teamcard-header-right{
        text-align: right;
        display: inline-block;
        width: 30%;
        padding: 5px;
        font-weight: 300;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .LP3-teamcard-subheader{
        text-align: center;
        width: 100%;
        font-size: 0.6rem;
        padding-top: 0px;
        margin-top: 0px;
    }
    .LP3-teamcard-stats{
        font-size: .6rem;
        font-weight: 600;
        padding-left: 7px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .LP3-teamcard-statsdiv{
        line-height: 80%;
        margin-bottom: 3px;
    }
    .LP3-teamcard-stat{
        display: inline-block;
        width: 50%;
        font-size: .45rem;
        margin-top: 0;
        padding-top: 0;
        
    }
    .LP3-teamcard-stat-left{
        display: inline-block;
        width: 60%;
        margin-top: 0;
        padding-top: 0;
        padding-left: 3px;
    }
    .LP3-teamcard-stat-right{
        display: inline-block;
        width: 40%;
        margin-top: 0;
        padding-top: 0;
    }
    .LP3-teamcard-trades{
        margin-top: 0px;
        margin-bottom: 4px;
    }
    .LP3-teamcard-trade{
        font-size: 0.7rem;
        color: gray;
        font-weight: 300;
    }
    .green{
        color: green;
    }
    .red{
        color: #ff7d7d;
    }
    .LP3-teamcard-trade-type{
        display: inline-block;
        margin-right: 0%;
        width: 44%;
        padding-left: 20px;
    }
    .LP3-teamcard-trade-team{
        display: inline-block;
    }
    .LP3-teamcard-trade-price{
        display: inline-block;
        width: 56%;
        text-align: right;
        padding-right: 20px
    }
    .payouts-div{
        margin-left: 0%;
    
        
    }
    .payout-img{
        width: 20%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    .threecard{
        background-color: white;
        margin: .5%;
        margin-left: 1%;
        margin-right: 0.5%;
        border-radius: .4rem;
        width: 30%;
        display: inline-block;
        text-align: left;
        font-size: 1.2rem;
        -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        text-align: center;
        padding-top: 1%;
        padding-bottom: 1%
    }
    .threecard-title{
        font-size: 1.55rem;
        font-weight: 600;
    }
    .FAQs3{
        text-align: center;
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
        padding: 3%;
        padding-top: 1%;
        margin-bottom: 100px;
        margin-top: 55px;
        background-color: white;
        -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        border-radius: 1rem;
    }
    .FAQs3-title{
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        width: 100%;
    }
    .FAQs3-item{
        padding: 1%;
    }
    .FAQs3-item:hover{
        background-color: rgb(249,249,249)
    }
    .FAQs3-item-left{
        text-align: left;
        display: inline-block;
        width: 70%;
        font-size: 1.35rem;
        font-weight: 500;
        color: black;
    }
    .FAQs3-item-right{
        text-align: right;
        display: inline-block;
        width: 30%;
        color: black;
    }
    .FAQs3-item-body{
        text-align: left;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .Reserves{
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 75px;
        text-align: center;
    }
}