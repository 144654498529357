.Guess{
    background-color: #fffafa;
    vertical-align: top;
    text-align: center;
}
@media only screen and (min-width: 1024px) {
    .Guess{
        padding-bottom: 1vh;
    }
}

.Guess-Div{
    vertical-align: top;
    background-color: white;
    margin: 2%;
    width: 96%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border-radius: .3rem;
    margin-top: 10px;
}

.Guess-Header{
    font-size: 1.59rem;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
}

.Guess-Input-Div{
    text-align: left;
    margin-bottom: 5px;
}
.Guess-Input{
    outline: none;
    border: none;
    width: 84%;
    display:inline-block;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
    font-size: 1.25rem;
    padding: 5px;
    text-align: center;
    font-weight: 300;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}
.Guess-btn{
    width: 15%;
    display: inline-block;
    border-radius: 1rem;
    margin-left: 1%;
    outline: none;
    border: none;
    background-color: white;
    font-size: 1.5rem;
    padding: .5px;
    font-weight: 200;
    color: #e0cb07;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}
.Guess-btn:hover{
    width: 15%;
    display: inline-block;
    border-radius: 1rem;
    margin-left: 1%;
    outline: none;
    border: none;
    background-color: #c5b358;
    font-weight: 200;
    color: white;
    font-size: 1.25rem;
    padding: 5px;
    box-shadow: none;
    background-color: #e0cb07;
}

.Guess-Search-Player{
    width: 100%;
    
}
.Guess-Search-Player-btn{
    border: none;
    outline: none;
    background-color: white;
    width: 90%;
    margin-bottom: 3px;
    border-radius: .5em;
    font-size: 1.2rem;
    font-weight: 300;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    
}
.Guess-Search-Player-btn:hover{
    background-color: rgb(240,240,240);
    font-size: 1.25rem;
    font-weight: 400;
    
}