.LandingPage{
    scroll-behavior: smooth;
}
.NavBar {
    background-color: black;
    color: white;
    max-height: 9.9vh;
}
.SimCFB{
    padding-top: 9.9vh;
}
.roboNormal {
    /*color: white;*/
    color: black;
    background-color: white;
    font-size: 1rem !important;
    text-decoration: none !important;
}
.fa-caret-down{
    display: none;
}
.background-img1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fiowa.jpeg?alt=media&token=2fde7db5-5836-47bc-8950-a6408190a3ad') !important;
    background-repeat: no-repeat !important;
    background-attachment:scroll !important;
    background-size:cover !important;
    background-position: 0vh !important;
    width: 100% !important;
    /*height: 300px !important;*/
    background-blend-mode:soft-light !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
    padding-bottom: 15%;
}
.main-heading{
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 3.5rem;
    text-align: center; 
    padding-top: 25%; 
    color: #c5b358;
}
.main-subheading{
    font-size: 1.5rem;
    color: white;
   
}
.nav-logo{
    height: 3.3vmax !important;
}
.robo200{
    font-family: 'Roboto Slab', serif;
    font-weight: 200;
}
.robo400{
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
}
.robo900{
    font-family: 'Roboto Slab', serif;
    font-weight: 900;
}
.nav-size{
    font-size: 1.3rem;
    text-align: center;
    background-color: white;
}

.nav-drop-size{
    font-size: 1rem !important;
    text-align: center;
    font-weight: 100 !important;
    /*background-color: black !important;*/
    background-color: white;
    color: black;
}
.nav-drop-size:hover {
    /*color: black !important;
    background-color: rgba(95, 91, 91, 0.068) !important;*/
    color: white !important;
    background-color: gray;
}
.nav-btn{
    word-wrap: none;
    min-width: 20%;
}
.main-title{
    font-size: 2rem;
    text-align: left !important;
    margin-top: 4%;
    margin-bottom: 4%;
}
.main-simbull{
    font-size: 1rem;
}
.main-under{
    font-size: .7rem;
}
.main-phone-img{
    border-radius: 1.6rem;
    margin-right: 0%;
}
.mpi1{
    transform: translateX(20px) rotate(-20deg) scale(.9);
    margin-top: 5%;
    z-index: 1;
    position: relative;
}
.mpi2{
    transform: translateX(-20px) rotate(20deg) scale(.9);
    margin-top: 5%;
    z-index: 1;
    position: relative;
}
.mpi3{
    z-index: 10;
    position: relative;
}
.main-car{
    margin-left: 1%;
    margin-top: 5%;
}
.account-form {
    text-align: center;
}
.topScrollBtn{
    background-color: #c5b358;
    border-radius: 0.5rem;
    padding: 1% 2% 1% 2%;
    margin: 1%;
}
.topScrollBtn1 {
    background-color: #c5b358;
    border-radius: .5rem;
    display: inline-block;
    cursor: pointer;
    padding: 4px 5px 5px 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 1%;
}
.signup{
    margin-top: 4%;
    padding: 5%;
    text-align: center;
}
.signup-title{
    padding: 2%;
    font-size: 1.35rem;
}
.signup-bg {
    background-color: #dfeaf7;
}
.signup-input{
    border-radius: 10px;
    border: 1px solid black;

    text-align: center;
}
.signup-btn{
    display: block;
    width: 50%;
    margin-left: 25%;
    border-radius: 0.75rem;
    margin-top: 2%;
    background-color: lightgray;
    color: black
}
.signup-hr{
    background-color: #c5b358;
    height: 5px;
}
.signup-terms2{
    font-size: .65rem;
    font-weight: 200;
}
.longcopy{
    padding: 7%;
    background-color: #c5b358;
    color: black;
    
    background-image: linear-gradient(to bottom right,  #c5b358, #fce77d);
}
.lc-body{
    font-size: 1.3rem;

}
.lc-body-span{
    margin-bottom: 3%;
}
#gold-triangle{
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 40px solid #ebd772;
    margin-left: 42.5%;
}
#gold-triangle2{
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 40px solid #c2a92f;
    margin-left: 42.5%;
    margin-top: -1%;
}
.hiw-title{
    font-size: 2.1rem;
    text-align: center;
    padding-top: 3%;
}
.hiw-section{
    padding: 5%;
    text-align: center;
}
.hiw-head{
    display: block;
    font-size: 1.75rem;
    margin-bottom: 5%;
}
.hiw-img{
    display: block;
    width: 30%;
    margin-left: 35%;
    margin-bottom: 5%;
}
.hiw-details{
    /* display: block; */
}
.hiw-desktop{
    display: none;
}
.main-desktop{
    display: none;
}
.gold-img{
    width: 100%;
}
.Screens{
    background-image: linear-gradient(to bottom , #c2a92f, #ffffff);
    color: black;
}
.img-user-border {
    width: 40%; 
    border: 8px solid rgb(197, 179, 88); 
    border-radius: 50%;
    margin-top: 8%;
}
.review-comma {
    font-size: 3rem; 
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: -22px;
    color: rgb(197, 179, 88);
}
.screens-title{
    font-size: 2rem !important;
    text-align: center;
    padding: 5%;
    font-weight: 600 !important;
}
.screens-img{
    width: 60% !important;
    margin-top: -2.5% !important;
    margin-left: 20%;
    /* background-color: white !important; */
}
.screens-img2{
    width: 100%;
    margin-left: 0%;
    border-radius: 1rem;
}
.carousel-caption {
    position: relative;
    left: 0;
    top: 0;
}
.caption{
    font-size: 1.2rem;
    color: black !important;
    padding: 5%;
    padding-top: 10% !important;
    text-align: center;
    margin-left: 0%;

}
.infomercial{
    padding: 5%;
}
.infovideo{
    width: 100%;
    margin-left: 0%;
    border-radius: 1rem;
    margin-top: 5%;
    margin-bottom: 5%;
}

.reviews{
    background: none !important;
}

.review-card {
    width: 80% !important;
    padding: 2%;
    margin: 10% 0% 10% 10%;
    background-color: #eee !important;
    border-radius: 20px !important;
    -moz-box-shadow: 5px 5px 5px 5px #eee !important;
    -webkit-box-shadow: 5px 5px 5px 5px #eee !important;
    box-shadow: 5px 5px 5px 5px #eee !important;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    z-index: 999 !important;
  }
  .review-name {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .review-stars {
    font-size: 1rem;
    font-weight: 400;
  }
  .review-details {
    font-size: 0.8rem;
    font-weight: 400 !important;
    margin-bottom: 40px;
  }
.review-title{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 0%;
}

.seenon-title{
    font-size: 1.5rem;
    text-align: center;
    margin-top: 5%;
}
.logo{
    width: 33%;
    -webkit-filter: grayscale(50%);
    -moz-filter: grayscale(50%);
    -o-filter: grayscale(50%);
    -ms-filter: grayscale(50%);
    filter: grayscale(50%); 
}
.seenon{
    padding: 5% 10% 0% 10%;
}
.mobile1{
    margin-left: 16.5%;
    margin-top: 3%;
}
.mobile2{
    margin-right: 16%;
    margin-top: 3%;
}
.MakeMoney{
    padding: 12% 10% 20% 10%;
    padding-top: 10vh;
}
.mm-head{
    font-size: 2rem;
}
.sm{
    font-size: .9rem;
}
.md{
    font-size: 1.25rem;
}

.FAQs{
    padding: 12% 0% 22% 0%;
    text-align: center;
    color: black
}
.f-head{
    font-size: 2rem;
    padding: 2%;
}
.blk-txt1{
    color: black !important;
    
}
.f-card{
    margin-bottom: 2%;
    z-index: -1;
}
.faq-head{
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color: rgb(233, 233, 233);
    margin-bottom: 3%;
    outline: solid 1px gray;
    color: black !important;
}
.faq-body{
    padding-bottom: 5%;
}
.ContactUs{
    padding: 2% 10% 10% 10%;
    font-family: 'Roboto Slab', serif;
    margin-top: 7vh;
}
.c-Head{
    font-size: 2rem;
}
.c-txt{

}
.c-name{

}
.c-email{

}
.c-message{

}
.c-btn-div{
    text-align: center;
}
.c-btn{
    border-radius: .8rem;
}
.c-input{
    width: 100%;
    border-radius: .8rem;
}
.OurStory{
    padding: 15% 10% 10% 10%;
    font-family: 'Roboto Slab', serif;
}
.os-title{
    font-size: 2rem;
    font-weight: 900;
}
.os-body{
    font-size: 1.1rem;
}
.os-img{
    width: 80%;
    margin-left: 10%;
    margin-top: 5%;
}
.os-video{
    width: 100%;
    margin-bottom: 15%;
}
.os-video-intro{
    text-align: center;
    font-size: 1.3rem;
    padding-bottom: 3%;
}


.Footer{
    font-family: 'Roboto Slab', serif;
    background-color: white/*#21242c*/ !important;
    padding-left: 5%;
    color: black !important;
    padding-right: 5%;
    vertical-align: top;
}
.f-div1{
    display: block;

}
.f-div{
    display: block;
    margin-top: 15%;
}
.f-img-div{
    margin-left: 5px !important;
}
.f-img{
    width: 60% !important;
}
.f-div-about{
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    font-weight: 200 !important;
    font-size: 0.98rem !important;
}
.f-div-address{
    padding-right: 10%;
    font-weight: 300 !important;
    font-size: 0.98rem !important;
}
.f-div-head{
    font-size: 1.5rem !important;
    font-weight: 600;
}
.f-div-item{
    font-size: 0.98rem !important;
    font-weight: 300 !important;
    padding-top: 5%;
    color: black !important;
}
.f-icon-div{

}
.f-icon{
    display: inline-block;
    margin-right: 5%;
    padding-top: 5%;
}
.fa{
    font-size: 1.75rem;
    
}
.fa-facebook{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
    margin-left: 0px !important;
}
.fa-instagram{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
}
.fa-twitter{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
}
.fa-linkedin{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
}
.f-copyright{
    text-align: center;
    font-size: 0.98rem !important;
    font-weight: 300 !important;
    padding-bottom: 10px !important;
}
.connector1 {
  flex-grow: 1;
  width: 10px;
  content: "-";
  display: block;
  height: 2px;
  background-color: rgb(197, 179, 88);
  -ms-transform: rotate(52deg); /* IE 9 */
  -ms-transform-origin: 20% 40%; /* IE 9 */
  transform: rotate(52deg);
  transform-origin: 20% 40%;
  /* border: 1px dashed black; */
  position: absolute;
}
.clip-art-img {
    background-color: white !important;
    border: none !important;
    z-index: 0 !important;
    box-shadow: none !important;
}
.nodes {
    background-color: #c2a92f !important;
    border: 3px solid #21242c !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    width: 25px !important;
    height: 25px !important;
    color: black !important;
}
.connector2 {
    flex-grow: 1;
    width: 10px;
    content: "-";
    display: block;
    height: 2px;
    background-color: rgb(197, 179, 88);
    -ms-transform: rotate(130deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(130deg);
    transform-origin: 20% 40%;
    /* border: 1px dashed black; */
    position: absolute;
  }

.Headshots{
    background-color: white;
    color: black;
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Roboto Slab', serif;
    padding-top: 10%;
}
.h-div{
    width: 80%;
    display: inline-block;
    text-align: center;
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 20%;
}
.h-img{
    border-radius: 100rem;
    width: 100%;
    
}
.h-name{
    font-weight: 600;
    font-size: 1.2rem;
}
.h-details{
    font-weight: 400;
}

.main-car-2{
    display: none;
}

.seenon-articles{
    width: 80%;
    margin-left: 10%;
    outline: 1px solid rgb(234, 234, 234);
    margin-bottom: 12px;
    padding: 12px;
    text-align: left;
}
.seenon-articles-left{
    width: 15%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: lightgray;
}
.seenon-articles-right{
    width: 85%;
    display: inline-block;
}


.transition {
    animation: fadeinout 2s linear 1;
  }
  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.01;
    }
    100% {
      opacity: 1;
    }
}
@media only screen and (min-width: 1024px) {
    .main-mobile{
        display: none;
    }
    .main-desktop{
        display: initial;
    }
    .main-car-2{
        display: none;
    }
    .main-heading{
        margin-top: 10%;
    }
}
@media only screen and (min-width: 1024px) {
    .NavBar{
        padding-right: 10%;
        padding-left: 10%;
        max-height: 9.9vh;
    }
    .SimCFB{
        padding-right: 10%;
        padding-left: 10%;
        padding-top: 9.9vh;
    }
    .roboNormal {
        color: white;
        font-size: 1rem !important;
        text-decoration: none !important;
        background-color: black;
    }
    .fa-caret-down{
        display: initial;
    }
    .nav-size{
        font-size: 1.3rem;
        text-align: center;
        background-color: black !important;
    }
    .nav-drop{
        background-color: black;
        color: white;
    }
    .nav-drop-size{
        font-size: 1rem !important;
        text-align: center;
        font-weight: 100 !important;
        background-color: white !important;
        color: black;
    }
    .nav-drop-size:hover {
        color: white !important;
        background-color: gray !important;
    }
    .background-img1{
        padding-top: 15%;
        padding-bottom: 5%;
    }
    .main-title{
        width: 40%;
        display: inline-block;
        margin-left: 15%;
        text-align: center;
        vertical-align: top;
        margin-top: 10%;
        font-size: 3rem;
    }
    .main-car{
        width: 50%;
        margin-left: 25%;
        display: none;
    }
    .main-car-2{
        display: none;
        width: 40%;
    }
    .main-phone-img{
        width: 100%;
        padding-top: 0%;
        margin-top: 0%;
    }
    .main-simbull{
        display: block;
        text-align: left;
    }
    .main-heading{
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        font-size: 4.5rem;
        text-align: center; 
        padding-top: 40%; 
        color: #c5b358;
    }
    .main-padding{
    }
    .main-subheading{
        font-size: 2.5rem;
        color: white;
    
    }
    .mpi1{
        transform: translateX(75px) rotate(-20deg) scale(.9) translateY(-80px);
        margin-top: 5%;
        z-index: 1;
        position: relative;
    }
    .mpi2{
        transform: translateX(-75px) rotate(20deg) scale(.9) translateY(-80px);
        margin-top: 5%;
        z-index: 1;
        position: relative;
    }
    .mpi3{
        z-index: 10;
        position: relative;
        transform:translateY(-80px);
    }
    .signup-input{
        border-radius: .75rem;
        display: block;
        margin: 1%;
        text-align: center;
        width: 40%;
        margin-left: 30%;
    }
    .signup-btn{
        width: 30%;
        margin-left: 35%;
    }
    #gold-triangle{
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 55px solid transparent;
        border-top: 80px solid #eed971;
        margin-left: 46.5%;
    }
    .lc-body-span{
        font-size: 2rem;
    }
    .hiw-mobile{
        display: none;
    }
    .hiw-desktop{
        display: initial;
    }
    .hiw-section{
        width: 33%;
        display: inline-block;
        padding: 1%;
    }
    .hiw-middle{
        vertical-align: middle;
    }
    .hiw-title{
        font-size: 1rem;
        padding-bottom: 2%;
        color: white;
    }
    .hiw-head{
        display: block;
        font-size: 1.75rem;
        margin-bottom: 0%;
    }
    .hiw-img{
        display: block;
        width: 30%;
        margin-left: 35%;
        margin-bottom: 0%;
    }
    .hiw-details{
        display: block;
        padding-bottom: 10%;
    }
    .signup-title{
        margin-top: 5%;
    }
    .signup-btn{
        margin-bottom: 10%;
    }
    
    .Screens{
        padding-left: 20%;
        padding-right: 20%;
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
        background-size: cover !important;
        background-position: 0vh !important;
        width: 100% !important;
        /*height: 410px !important;*/
        background-blend-mode:soft-light !important;
        background-color: rgba(0, 0, 0, 0.4) !important;
        color: white;
    }
    .caption{
        margin-top: 5%;
        margin-bottom: 10%;
        margin-left: 15%;
        margin-right: 15%;
        text-align: center;
        padding: 0%;
        color: white !important;
    }
    .screens-title{
        padding: 5%;
        padding-bottom: 0%;
        font-size: 2rem;
    }
    .infovideo{
        width: 50%;
        margin-left: 25%;
    }
    .review-title{
        font-size: 2.2rem;
    }
    .reviews{
        padding-bottom: 3%;
    }
    #gold-triangle2{
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 55px solid transparent;
        border-top: 80px solid #c2a92f;
        margin-left: 47.5%;
        margin-top: 0%;
    }
    .seenon-title{
        font-size: 2.2rem;
        text-align: center;
    }
    .logo{
        width: 15%;;
        margin-right: 2.5%;
        margin-left: 2.5%;
    }
    .mobile1{
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-top: 0%;
    }
    .mobile2{
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-top: 0%;
    }
    .MakeMoney{
        padding: 12% 20% 8% 20%;
        padding-top: 10vh;
    }
    .FAQs{
        padding: 5% 15% 2% 15%;
        text-align: center;
        color: black
    }
    .OurStory{
        padding: 10% 25% 10% 25%;
        font-family: 'Roboto Slab', serif;
        
    }
    .os-img{
        margin-top: 5%;
        width: 30%;
        margin-left: 35%;
    }


    .Footer{
        font-family: 'Roboto Slab', serif;
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 3% !important;
        vertical-align: top;
        background: rgb(235,235,235);
    }
    .f-div1{
        display: inline-block;
        width: 40%;
        vertical-align: top;
    }
    .f-div{
        display: inline-block;
        width: 20%;
        vertical-align: top;
        margin-top: 0%;
    }
    .f-img-div{
    
    }
    .f-img{
        width: 25%;
    }
    .f-div-about{
        padding-right: 10%;
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 1.2rem;
    }
    .f-div-address{
        padding-right: 10%;
        font-size: 1.2rem;
    }
    .f-div-head{
        font-size: 1.5rem;
        font-weight: 600;
    }
    .f-div-item{
        font-size: 1.2rem;
        font-weight: 400;
        padding-top: 5%;
    }
    .f-icon-div{
    
    }
    .f-icon{
        display: inline-block;
        margin-right: 2%;
    }
    .fa{
        font-size: 1.75rem;
        
    }
    .fa-facebook{
        color: gray;
        width: 100%;
    }
    .fa-instagram{
        color: gray;
    }
    .fa-twitter{
        color: gray;
    }
    .fa-linkedin{
        color: gray;
    }
    .f-copyright{
        text-align: center;
    }

    .h-div{
        width: 23%;
        display: inline-block;
        text-align: center;
        margin-right: 5%;
        margin-left: 5%
    }

    .seenon-articles{
        
        width: 60%;
        margin-left: 20%;
    }

}
.gray2{
    background: rgb(235,235,235) !important
}