.TeammateValue{
    display: inline-block;
    width: 60%;

    padding-left: 5px;
}
.TeammateValue-Span{
    color: gray;
    font-weight: 300;
    font-size: .9em;
}
.TeammateValue-Amount{
    font-size: 1.65rem;
    font-weight: 400;
    margin-left: 0px;
    padding-left: 0px;
}
.TeammateValue-Change-Div{
    display: inline-block;
    width: 40%;
    color: gray;
    text-align: right;
    padding-right: 5px;

}
.TeammateValue-Return-Span{
    font-size: 1.5em;
    font-weight: 500;
}
.TeammateValue-caret-up{
    color: green;
    font-size: 1em;
    margin-right: 5px;
}
.TeammateValue-caret-down{
    color: red;
    font-size: 1em;
    margin-right: 5px;
}