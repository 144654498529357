.NumberProof{
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10%;
    height: 25vh;
    border-top: 0.25px solid rgb(31, 31, 31);
    border-bottom: 0.25px solid rgb(31, 31, 31);
}
.NP-title{
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10%;
}
.NP-div{
    opacity: 1;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 7%;
}

.pff {
    /* style={{height: 100, width: 180}} */
    height: 100px;
    width: 180px;
}

.sportstechie {
    /* style={{height: 100, width: 200}} */
    height: 100px;
    width: 200px;
}

.techstars {
    /* style={{height: 100, width: 200}} */
    height: 45px;
    width: 195px;
}

@media screen and (max-width: 768px) {
    .NumberProof{
        background-color: black;
        padding: 25px;
        text-align: center;
        height: 100%;
    }

    .pff {
        /* style={{height: 100, width: 180}} */
        height: 80px;
        width: 144px;
    }

    .sportstechie {
        /* style={{height: 100, width: 200}} */
        height: 80px;
        width: 160px;
    }

    .techstars {
        /* style={{height: 100, width: 200}} */
        height: 36px;
        width: 156px;
    }
    .NP-title{
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 50px;
        opacity: .8;
        width: 90%;
        margin-left: 5%;
    }
    .NP-div{
        opacity: 1;
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
    }
    .NP-number{
        font-size: 2.25rem;
        font-weight: 600;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 40px;
        display: inline-block;
    }
    .NP-span{
        font-size: 1rem;
        font-weight: 400;
        margin-left: 10px;
    }
    .NP-hr{
        width: 15%;
        background-color: black;
        opacity: .8;
        margin-left: 1%;
        padding: .5px;
    }
}