
.timeline {
    position: relative;
    padding: 100px 0;
    padding-bottom: 5px;
    padding-top: 20px;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 400;
    background-color: black;
    width: 90%;
    margin-bottom: 0px;
    margin-left: 5%;
}
.timeline::before {
    content: '';
    background: rgb(240,240,240);
    width: 2px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0px;
}
.timeline-item {
    width: 100%;
    margin-bottom: 70px;
}
.timeline-item:nth-child(even) .timeline-content {
    float: right;
    padding: 40px 30px 10px 30px;
}
.timeline-item:nth-child(even) .timeline-content .date {
    right: auto;
    left: 0;
}
.timeline-item::after {
    content: '';
    display: block;
    clear: both;
}
.timeline-content {
    position: relative;
    width: 45%;
    border-radius: 4px;
    display: inline-block;
    /* margin-left: 5%; */
    position: relative;
    padding: 10px 30px;
    /* left: 25%; */
}
.timeline-content2 {
    position: relative;
    width: 45%;
    margin-left: 10%;
    padding: 10px 30px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
}
.timeline-content2left {
    position: relative;
    width: 45%;
    padding: 10px 30px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    text-align: right;
}

.timeline-img {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 5px;
    margin-left: -15px;
    border: 2px solid rgb(240,240,240);
    text-align: center;
    font-size: 1.1em;
}
.timeline-a {
    background: #3f51b5;
    color: black;
    padding: 8px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 2px;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);
}
.timeline-card {
    padding: 0 !important;
}
.timeline-card p {
    padding: 0 20px;
}
.timeline-card a {
    margin-left: 20px;
}
.timeline-item .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url('https://picsum.photos/1000/800/?random') center center no-repeat;
    background-size: cover;
}
.timeline-img-header {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
}
.timeline-img-header h2 {
    color: black;
    position: absolute;
    bottom: 5px;
    left: 20px;
}


.bottom-btn{
    padding: 15px 20px;
    border-radius: .4em;
    background-color: #3d3d3d;
    color: white;
    font-family: 'Lato', sans-serif !important;
    text-align: center;
    font-size: 14px;
    margin-top: 30px;
    font-weight: 600;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    border: none;
    outline: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 70px;
}
.bottom-btn:hover{
    background-color: #202020;
    margin-top: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    margin-bottom: 75px;
}

.Footer3{
    font-family: 'Roboto Slab', serif;
    background-color: black/*#21242c*/ !important;
    padding-left: 5%;
    color: white !important;
    padding-right: 5%;
    vertical-align: top;
    padding-top: 50px;
}
.f-div-item2{
    font-size: 0.98rem !important;
    font-weight: 300 !important;
    padding-top: 5%;
    color: white;
}

.fa-facebook{
    color: white;
    width: 100%;
}
.fa-instagram{
    color: white;
}
.fa-twitter{
    color: white;
}
.fa-linkedin{
    color: white;
}
.white{
    color: white !important;
}

.headerImage{
    width: 30%;
}

.signup_modal{
    width: 40vw
}

@media screen and (max-width: 768px) {
    .timeline::before {
        left: 0px;
   }
    .timeline .timeline-img {
        left: 0px;
   }
    .timeline .timeline-content {
        max-width: 100%;
        width: auto;
   }
    .timeline .timeline-item:nth-child(even) .timeline-content {
        float: none;
   }
   .timeline-content2{
       width: 100%;
       margin-left: 0%;
       margin-right: 0%;
       margin-bottom: 20px;
   }
   .timeline-content2left{
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 20px;
        text-align: left;
   }
   .Footer3{
        font-family: 'Roboto Slab', serif;
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 3% !important;
        vertical-align: top;
        background: black;
    }
     .f-div-item2{
        font-size: 1.2rem;
        font-weight: 400;
        padding-top: 5%;
    }

    .headerImage{
        width: 70%;
    }

    .signup_modal{
        width: 90vw
    }
}

#arrow {
    position: relative;
    top: 0;
    transition: top ease 0.25s;
  }
#arrow:hover {
    top: -2px;
    text-decoration: underline;
    transform: scale(1.1)
}
#arrow i:hover {
    transform: scale(1.1)
}