.Status{
    width: 80%;
    margin: 10%;
    background-color: white;
    border-radius: .7em;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    font-size: 1.1em;
}

.Status-Head{
    font-size: 1.25rem;
    text-align: center;
    font-size: 900;
}

.Status-Table{
    width: 100%;
}
.Status-Row{
    width: 100%;
}
.Status-Left{
    text-align: left;
    padding-left: 15px;
    font-weight: 300;
}
.Status-Right{
    text-align: right;
    padding-right: 15px;
    font-size: 1.1rem;
    font-weight: 600;
}