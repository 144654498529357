.TeammateList{
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 12vh;
}    

.TeammateList-Header{
    font-size: 1.5rem;
    font-weight: 600;
    display: inline-block;
    width: 80%;

}

.TeammateList-SubHeader{
    font-size: 1.25rem;
    font-weight: 300;
    color: gray;
    margin-top: 25px;
}