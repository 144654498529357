.Toggle{
    width: 100%;
    background-color: white;
    margin-top: 5px;
    padding: 6px;
    border-radius: .3rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.Toggle-name{
    display: inline-block;
    width: 60%;
    text-align: left;
    font-size: 1.2rem;
    
}

.Toggle-btns-div{
    display: inline-block;
    width: 40%;
}

.Toggle-btn{
    display: inline-block;
    width: 35%;
    margin: 2.5%;
    color: white;
    font-size: .8rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    padding: 5px;
}