.Reviews2{
    background-color: black;
    padding: 20px;
    font-family: 'Lato', sans-serif !important;
    line-height: 1.6;
    color: #3d3d3d;
}
.rev-title{
    font-family: 'Lato', sans-serif !important;
    font-size: 2em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    font-weight: 700;
    line-height: 1.3;
    color: white;
    text-align: center;
    margin-bottom: 60px;
    width: 100%;
}

.rev-logos{
    margin-left: 7%;
    margin-right: 7%;
    filter: grayscale(1);
    max-width: 100%;
}

.rev-logo-div{
    width:  20%;
    display: inline-block;
    text-align: center;
}
.rev-logo{
    height: 34px;
}
.review-card{
    background-color: white !important;
    margin-left: 25%;
    margin-right: 20%;
    width: 50%!important;
    border-radius: .5em;
    padding: 35px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 35px;
}
.review-img{
    height: 50px;
    margin-bottom: 15px;
}
.review-body{
    font-weight: 400;
    margin-bottom: 15px;
}
.review-author{
    color: gray;
    font-weight: 400;
    font-size: .9em;
    margin-bottom: 15px;
}
.review-link{
    font-size: .8em;
    font-weight: 600!important;
}
.custom-dot-list-style{
    color: gold;
}

@media screen and (max-width: 768px) {
    .rev-logo-div{
        width:  50%;
        display: inline-block;
        text-align: center;
    }
    .rev-logo{
        max-height: 60px;
        margin-bottom: 20px;
        filter: grayscale(1);
    }

    .review-card{
        background-color: white !important;
        margin-left: 2%;
        margin-right: 20%;
        width: 96%!important;
        border-radius: .5em;
        padding: 15px;
        text-align: left;
        margin-top: 50px;
        margin-bottom: 35px;
    }
    .rev-title{
        font-size: 1rem;
    }
}