@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.LandingPage3{
    background: rgb(235,235,235);
    min-height: 100vh;
    /*padding-right: %;
    padding-left: 5%;*/
    text-align: left;
        font-family: 'Roboto Slab', serif;
    
}
.league-btns{
    text-align: center;
    margin-bottom: 10px;
}
.filterBTN{
    border: 1px solid #c5b358;
    outline: none;
    font-size: .9rem;
    border-radius: 1rem;
    margin-right: 2px;
    margin-top: 2px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    color: gray;
    background-color: rgb(235,235,235);
}
.filterBTN-selected{
    border: none;
    outline: none;
    border: 1px solid #c5b358;
    outline: none;
    font-size: .9rem;
    border-radius: 1rem;
    margin-right: 2px;
    margin-top: 2px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    color: black;
    background-color: #c5b358;
}
.heading{
    font-size: 1.2rem;
    font-weight: 300;
}
.teams-title{
    font-size: 1.8rem;
    margin-left: -1.5%;
    font-weight: 600;
    text-align: left;
}
.teams-emoji{
    font-size: 1.25rem;
}
.teams-third-title{
    font-size: .9rem;
    font-style: italic;
}
.LP3-teamcard{
    display: inline-block;
    background-color: white;
    margin: .5%;
    margin-left: 1%;
    margin-right: 0.5%;
    border-radius: .4rem;
    min-width: 30%;
    max-width: 50%;
    display: inline-block;
    text-align: left;
    font-size: 0.9rem;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
}
.LP3-teamcard-header-left{
    display: inline-block;
    width: 70%;
    padding: 5px;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.LP3-teamcard-header-right{
    text-align: right;
    display: inline-block;
    width: 30%;
    padding: 5px;
    font-weight: 300;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.LP3-teamcard-subheader{
    text-align: center;
    width: 100%;
    font-size: 0.6rem;
    padding-top: 0px;
    margin-top: 0px;
}
.LP3-teamcard-stats{
    font-size: .6rem;
    font-weight: 600;
    padding-left: 7px;
    margin-bottom: 0;
    padding-bottom: 0;
}
.LP3-teamcard-statsdiv{
    line-height: 80%;
    margin-bottom: 3px;
}
.LP3-teamcard-stat{
    display: inline-block;
    width: 50%;
    font-size: .45rem;
    margin-top: 0;
    padding-top: 0;
    
}
.LP3-teamcard-stat-left{
    display: inline-block;
    width: 60%;
    margin-top: 0;
    padding-top: 0;
    padding-left: 3px;
}
.LP3-teamcard-stat-right{
    display: inline-block;
    width: 40%;
    margin-top: 0;
    padding-top: 0;
}
.LP3-teamcard-trades{
    margin-top: 0px;
    margin-bottom: 4px;
}
.LP3-teamcard-trade{
    font-size: 0.7rem;
    color: gray;
    font-weight: 300;
}
.green{
    color: green;
}
.red{
    color: #ff7d7d;
}
.LP3-teamcard-trade-type{
    display: inline-block;
    margin-right: 0%;
    width: 44%;
    padding-left: 20px;
}
.LP3-teamcard-trade-team{
    display: inline-block;
}
.LP3-teamcard-trade-price{
    display: inline-block;
    width: 56%;
    text-align: right;
    padding-right: 20px
}
.payouts-div{
    margin-left: 0%;
    text-align: center;
    
}
.payout-img{
    width: 33%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}
.threecard{
    background-color: white;
    margin: .5%;
    margin-left: 1%;
    margin-right: 0.5%;
    border-radius: .4rem;
    margin-bottom: 2%;
    width: 100%;
    display: inline-block;
    text-align: left;
    font-size: 1.2rem;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%
}
.threecard-title{
    font-size: 1.55rem;
    font-weight: 600;
}

.Reserves{
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 75px;
    text-align: center;
}

.FAQs3{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 3%;
    padding-top: 1%;
    margin-bottom: 100px;
    margin-top: 55px;
    background-color: white;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    border-radius: 1rem;
}
.FAQs3-title{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
}
.FAQs3-item{
    padding: 1%;
}
.FAQs3-item:hover{
    background-color: rgb(249,249,249)
}
.FAQs3-item-left{
    text-align: left;
    display: inline-block;
    width: 70%;
    font-size: 1.15rem;
    font-weight: 500;
    color: black;
}
.FAQs3-item-right{
    text-align: right;
    display: inline-block;
    width: 30%;
    color: black;
}
.FAQs3-item-body{
    text-align: left;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 25px;
}

.backWhite{
    background-color: white;
    width: 90%;
    margin-left: 5%;
    
}

@media only screen and (min-width: 1024px) {
    .LandingPage3{
        background: rgb(235,235,235);
        min-height: 100vh;
        /*padding-right: %;
        padding-left: 5%;*/
        text-align: left;
            font-family: 'Roboto Slab', serif;
        
    }
    .league-btns{
        text-align: left;
        margin-bottom: 10px;
    }
    .filterBTN{
        border: 1px solid #c5b358;
        outline: none;
        font-size: 1.2rem;
        border-radius: 1rem;
        margin-right: 5px;
        margin-top: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        color: gray;
        background-color: rgb(235,235,235);
    }
    .filterBTN-selected{
        border: none;
        outline: none;
        border: 1px solid #c5b358;
        outline: none;
        font-size: 1.2rem;
        border-radius: 1rem;
        margin-right: 5px;
        margin-top: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        color: black;
        background-color: #c5b358;
    }
    .heading{
        font-size: 1.2rem;
        font-weight: 300;
    }
    .teams-title{
        font-size: 3rem;
        margin-left: -1.5%;
        font-weight: 600;
        text-align: left;
    }
    .teams-emoji{
        font-size: 2rem;
    }
    .teams-third-title{
        font-size: 1.3rem;
        font-style: italic;
    }
    .LP3-teamcard{
        display: inline-block;
        background-color: white;
        margin: .5%;
        margin-left: 1%;
        margin-right: 0.5%;
        border-radius: .4rem;
        min-width: 14.75%;
        max-width: 14.75%;
        display: inline-block;
        text-align: left;
        font-size: 0.9rem;
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    }
    .LP3-teamcard-header-left{
        display: inline-block;
        width: 70%;
        padding: 5px;
        font-weight: 600;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .LP3-teamcard-header-right{
        text-align: right;
        display: inline-block;
        width: 30%;
        padding: 5px;
        font-weight: 300;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
    .LP3-teamcard-subheader{
        text-align: center;
        width: 100%;
        font-size: 0.6rem;
        padding-top: 0px;
        margin-top: 0px;
    }
    .LP3-teamcard-stats{
        font-size: .6rem;
        font-weight: 600;
        padding-left: 7px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .LP3-teamcard-statsdiv{
        line-height: 80%;
        margin-bottom: 3px;
    }
    .LP3-teamcard-stat{
        display: inline-block;
        width: 50%;
        font-size: .45rem;
        margin-top: 0;
        padding-top: 0;
        
    }
    .LP3-teamcard-stat-left{
        display: inline-block;
        width: 60%;
        margin-top: 0;
        padding-top: 0;
        padding-left: 3px;
    }
    .LP3-teamcard-stat-right{
        display: inline-block;
        width: 40%;
        margin-top: 0;
        padding-top: 0;
    }
    .LP3-teamcard-trades{
        margin-top: 0px;
        margin-bottom: 4px;
    }
    .LP3-teamcard-trade{
        font-size: 0.7rem;
        color: gray;
        font-weight: 300;
    }
    .green{
        color: green;
    }
    .red{
        color: #ff7d7d;
    }
    .LP3-teamcard-trade-type{
        display: inline-block;
        margin-right: 0%;
        width: 44%;
        padding-left: 20px;
    }
    .LP3-teamcard-trade-team{
        display: inline-block;
    }
    .LP3-teamcard-trade-price{
        display: inline-block;
        width: 56%;
        text-align: right;
        padding-right: 20px
    }
    .payouts-div{
        margin-left: 0%;
    
        
    }
    .payout-img{
        width: 20%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    .threecard{
        background-color: white;
        margin: .5%;
        margin-left: 1%;
        margin-right: 0.5%;
        border-radius: .4rem;
        width: 30%;
        display: inline-block;
        text-align: left;
        font-size: 1.2rem;
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        text-align: center;
        padding-top: 1%;
        padding-bottom: 1%
    }
    .threecard-title{
        font-size: 1.55rem;
        font-weight: 600;
    }
    .FAQs3{
        text-align: center;
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
        padding: 3%;
        padding-top: 1%;
        margin-bottom: 100px;
        margin-top: 55px;
        background-color: white;
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
        border-radius: 1rem;
    }
    .FAQs3-title{
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        width: 100%;
    }
    .FAQs3-item{
        padding: 1%;
    }
    .FAQs3-item:hover{
        background-color: rgb(249,249,249)
    }
    .FAQs3-item-left{
        text-align: left;
        display: inline-block;
        width: 70%;
        font-size: 1.35rem;
        font-weight: 500;
        color: black;
    }
    .FAQs3-item-right{
        text-align: right;
        display: inline-block;
        width: 30%;
        color: black;
    }
    .FAQs3-item-body{
        text-align: left;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .Reserves{
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 75px;
        text-align: center;
    }
}
.Payouts{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 100;
    
}
/*#spinner {
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count:1;
    animation-duration: 2s;
    /*transform-style: preserve-3d;
    text-align:center;
}
@keyframes spinner {
from {
    transform: rotateX(0deg);
}
to {
    transform: rotateX(-360deg);
}
}*/

@media only screen and (min-width: 1024px) {

    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}
.LandingPage{
    scroll-behavior: smooth;
}
.NavBar {
    background-color: black;
    color: white;
    max-height: 9.9vh;
}
.SimCFB{
    padding-top: 9.9vh;
}
.roboNormal {
    /*color: white;*/
    color: black;
    background-color: white;
    font-size: 1rem !important;
    text-decoration: none !important;
}
.fa-caret-down{
    display: none;
}
.background-img1 {
    background-image: url('https://firebasestorage.googleapis.com/v0/b/sports-market.appspot.com/o/website%2Fiowa.jpeg?alt=media&token=2fde7db5-5836-47bc-8950-a6408190a3ad') !important;
    background-repeat: no-repeat !important;
    background-attachment:scroll !important;
    background-size:cover !important;
    background-position: 0vh !important;
    width: 100% !important;
    /*height: 300px !important;*/
    background-blend-mode:soft-light !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
    padding-bottom: 15%;
}
.main-heading{
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 3.5rem;
    text-align: center; 
    padding-top: 25%; 
    color: #c5b358;
}
.main-subheading{
    font-size: 1.5rem;
    color: white;
   
}
.nav-logo{
    height: 3.3vmax !important;
}
.robo200{
    font-family: 'Roboto Slab', serif;
    font-weight: 200;
}
.robo400{
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
}
.robo900{
    font-family: 'Roboto Slab', serif;
    font-weight: 900;
}
.nav-size{
    font-size: 1.3rem;
    text-align: center;
    background-color: white;
}

.nav-drop-size{
    font-size: 1rem !important;
    text-align: center;
    font-weight: 100 !important;
    /*background-color: black !important;*/
    background-color: white;
    color: black;
}
.nav-drop-size:hover {
    /*color: black !important;
    background-color: rgba(95, 91, 91, 0.068) !important;*/
    color: white !important;
    background-color: gray;
}
.nav-btn{
    word-wrap: none;
    min-width: 20%;
}
.main-title{
    font-size: 2rem;
    text-align: left !important;
    margin-top: 4%;
    margin-bottom: 4%;
}
.main-simbull{
    font-size: 1rem;
}
.main-under{
    font-size: .7rem;
}
.main-phone-img{
    border-radius: 1.6rem;
    margin-right: 0%;
}
.mpi1{
    transform: translateX(20px) rotate(-20deg) scale(.9);
    margin-top: 5%;
    z-index: 1;
    position: relative;
}
.mpi2{
    transform: translateX(-20px) rotate(20deg) scale(.9);
    margin-top: 5%;
    z-index: 1;
    position: relative;
}
.mpi3{
    z-index: 10;
    position: relative;
}
.main-car{
    margin-left: 1%;
    margin-top: 5%;
}
.account-form {
    text-align: center;
}
.topScrollBtn{
    background-color: #c5b358;
    border-radius: 0.5rem;
    padding: 1% 2% 1% 2%;
    margin: 1%;
}
.topScrollBtn1 {
    background-color: #c5b358;
    border-radius: .5rem;
    display: inline-block;
    cursor: pointer;
    padding: 4px 5px 5px 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 1%;
}
.signup{
    margin-top: 4%;
    padding: 5%;
    text-align: center;
}
.signup-title{
    padding: 2%;
    font-size: 1.35rem;
}
.signup-bg {
    background-color: #dfeaf7;
}
.signup-input{
    border-radius: 10px;
    border: 1px solid black;

    text-align: center;
}
.signup-btn{
    display: block;
    width: 50%;
    margin-left: 25%;
    border-radius: 0.75rem;
    margin-top: 2%;
    background-color: lightgray;
    color: black
}
.signup-hr{
    background-color: #c5b358;
    height: 5px;
}
.signup-terms2{
    font-size: .65rem;
    font-weight: 200;
}
.longcopy{
    padding: 7%;
    background-color: #c5b358;
    color: black;
    
    background-image: linear-gradient(to bottom right,  #c5b358, #fce77d);
}
.lc-body{
    font-size: 1.3rem;

}
.lc-body-span{
    margin-bottom: 3%;
}
#gold-triangle{
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 40px solid #ebd772;
    margin-left: 42.5%;
}
#gold-triangle2{
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 40px solid #c2a92f;
    margin-left: 42.5%;
    margin-top: -1%;
}
.hiw-title{
    font-size: 2.1rem;
    text-align: center;
    padding-top: 3%;
}
.hiw-section{
    padding: 5%;
    text-align: center;
}
.hiw-head{
    display: block;
    font-size: 1.75rem;
    margin-bottom: 5%;
}
.hiw-img{
    display: block;
    width: 30%;
    margin-left: 35%;
    margin-bottom: 5%;
}
.hiw-details{
    /* display: block; */
}
.hiw-desktop{
    display: none;
}
.main-desktop{
    display: none;
}
.gold-img{
    width: 100%;
}
.Screens{
    background-image: linear-gradient(to bottom , #c2a92f, #ffffff);
    color: black;
}
.img-user-border {
    width: 40%; 
    border: 8px solid rgb(197, 179, 88); 
    border-radius: 50%;
    margin-top: 8%;
}
.review-comma {
    font-size: 3rem; 
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: -22px;
    color: rgb(197, 179, 88);
}
.screens-title{
    font-size: 2rem !important;
    text-align: center;
    padding: 5%;
    font-weight: 600 !important;
}
.screens-img{
    width: 60% !important;
    margin-top: -2.5% !important;
    margin-left: 20%;
    /* background-color: white !important; */
}
.screens-img2{
    width: 100%;
    margin-left: 0%;
    border-radius: 1rem;
}
.carousel-caption {
    position: relative;
    left: 0;
    top: 0;
}
.caption{
    font-size: 1.2rem;
    color: black !important;
    padding: 5%;
    padding-top: 10% !important;
    text-align: center;
    margin-left: 0%;

}
.infomercial{
    padding: 5%;
}
.infovideo{
    width: 100%;
    margin-left: 0%;
    border-radius: 1rem;
    margin-top: 5%;
    margin-bottom: 5%;
}

.reviews{
    background: none !important;
}

.review-card {
    width: 80% !important;
    padding: 2%;
    margin: 10% 0% 10% 10%;
    background-color: #eee !important;
    border-radius: 20px !important;
    box-shadow: 5px 5px 5px 5px #eee !important;
    text-align: center;
    font-family: 'Roboto Slab', serif;
    z-index: 999 !important;
  }
  .review-name {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .review-stars {
    font-size: 1rem;
    font-weight: 400;
  }
  .review-details {
    font-size: 0.8rem;
    font-weight: 400 !important;
    margin-bottom: 40px;
  }
.review-title{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 0%;
}

.seenon-title{
    font-size: 1.5rem;
    text-align: center;
    margin-top: 5%;
}
.logo{
    width: 33%;
    -webkit-filter: grayscale(50%);
    -moz-filter: grayscale(50%);
    -o-filter: grayscale(50%);
    -ms-filter: grayscale(50%);
    filter: grayscale(50%); 
}
.seenon{
    padding: 5% 10% 0% 10%;
}
.mobile1{
    margin-left: 16.5%;
    margin-top: 3%;
}
.mobile2{
    margin-right: 16%;
    margin-top: 3%;
}
.MakeMoney{
    padding: 12% 10% 20% 10%;
    padding-top: 10vh;
}
.mm-head{
    font-size: 2rem;
}
.sm{
    font-size: .9rem;
}
.md{
    font-size: 1.25rem;
}

.FAQs{
    padding: 12% 0% 22% 0%;
    text-align: center;
    color: black
}
.f-head{
    font-size: 2rem;
    padding: 2%;
}
.blk-txt1{
    color: black !important;
    
}
.f-card{
    margin-bottom: 2%;
    z-index: -1;
}
.faq-head{
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    background-color: rgb(233, 233, 233);
    margin-bottom: 3%;
    outline: solid 1px gray;
    color: black !important;
}
.faq-body{
    padding-bottom: 5%;
}
.ContactUs{
    padding: 2% 10% 10% 10%;
    font-family: 'Roboto Slab', serif;
    margin-top: 7vh;
}
.c-Head{
    font-size: 2rem;
}
.c-txt{

}
.c-name{

}
.c-email{

}
.c-message{

}
.c-btn-div{
    text-align: center;
}
.c-btn{
    border-radius: .8rem;
}
.c-input{
    width: 100%;
    border-radius: .8rem;
}
.OurStory{
    padding: 15% 10% 10% 10%;
    font-family: 'Roboto Slab', serif;
}
.os-title{
    font-size: 2rem;
    font-weight: 900;
}
.os-body{
    font-size: 1.1rem;
}
.os-img{
    width: 80%;
    margin-left: 10%;
    margin-top: 5%;
}
.os-video{
    width: 100%;
    margin-bottom: 15%;
}
.os-video-intro{
    text-align: center;
    font-size: 1.3rem;
    padding-bottom: 3%;
}


.Footer{
    font-family: 'Roboto Slab', serif;
    background-color: white/*#21242c*/ !important;
    padding-left: 5%;
    color: black !important;
    padding-right: 5%;
    vertical-align: top;
}
.f-div1{
    display: block;

}
.f-div{
    display: block;
    margin-top: 15%;
}
.f-img-div{
    margin-left: 5px !important;
}
.f-img{
    width: 60% !important;
}
.f-div-about{
    padding-right: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    font-weight: 200 !important;
    font-size: 0.98rem !important;
}
.f-div-address{
    padding-right: 10%;
    font-weight: 300 !important;
    font-size: 0.98rem !important;
}
.f-div-head{
    font-size: 1.5rem !important;
    font-weight: 600;
}
.f-div-item{
    font-size: 0.98rem !important;
    font-weight: 300 !important;
    padding-top: 5%;
    color: black !important;
}
.f-icon-div{

}
.f-icon{
    display: inline-block;
    margin-right: 5%;
    padding-top: 5%;
}
.fa{
    font-size: 1.75rem;
    
}
.fa-facebook{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
    margin-left: 0px !important;
}
.fa-instagram{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
}
.fa-twitter{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
}
.fa-linkedin{
    color: black !important;
    font-size: 1.3rem !important;
    margin: 10px !important;
}
.f-copyright{
    text-align: center;
    font-size: 0.98rem !important;
    font-weight: 300 !important;
    padding-bottom: 10px !important;
}
.connector1 {
  flex-grow: 1;
  width: 10px;
  content: "-";
  display: block;
  height: 2px;
  background-color: rgb(197, 179, 88);
  -ms-transform: rotate(52deg); /* IE 9 */
  -ms-transform-origin: 20% 40%; /* IE 9 */
  transform: rotate(52deg);
  transform-origin: 20% 40%;
  /* border: 1px dashed black; */
  position: absolute;
}
.clip-art-img {
    background-color: white !important;
    border: none !important;
    z-index: 0 !important;
    box-shadow: none !important;
}
.nodes {
    background-color: #c2a92f !important;
    border: 3px solid #21242c !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    width: 25px !important;
    height: 25px !important;
    color: black !important;
}
.connector2 {
    flex-grow: 1;
    width: 10px;
    content: "-";
    display: block;
    height: 2px;
    background-color: rgb(197, 179, 88);
    -ms-transform: rotate(130deg); /* IE 9 */
    -ms-transform-origin: 20% 40%; /* IE 9 */
    transform: rotate(130deg);
    transform-origin: 20% 40%;
    /* border: 1px dashed black; */
    position: absolute;
  }

.Headshots{
    background-color: white;
    color: black;
    padding-left: 5%;
    padding-right: 5%;
    font-family: 'Roboto Slab', serif;
    padding-top: 10%;
}
.h-div{
    width: 80%;
    display: inline-block;
    text-align: center;
    margin-right: 10%;
    margin-left: 10%;
    padding-bottom: 20%;
}
.h-img{
    border-radius: 100rem;
    width: 100%;
    
}
.h-name{
    font-weight: 600;
    font-size: 1.2rem;
}
.h-details{
    font-weight: 400;
}

.main-car-2{
    display: none;
}

.seenon-articles{
    width: 80%;
    margin-left: 10%;
    outline: 1px solid rgb(234, 234, 234);
    margin-bottom: 12px;
    padding: 12px;
    text-align: left;
}
.seenon-articles-left{
    width: 15%;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    color: lightgray;
}
.seenon-articles-right{
    width: 85%;
    display: inline-block;
}


.transition {
    animation: fadeinout 2s linear 1;
  }
  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.01;
    }
    100% {
      opacity: 1;
    }
}
@media only screen and (min-width: 1024px) {
    .main-mobile{
        display: none;
    }
    .main-desktop{
        display: inline;
        display: initial;
    }
    .main-car-2{
        display: none;
    }
    .main-heading{
        margin-top: 10%;
    }
}
@media only screen and (min-width: 1024px) {
    .NavBar{
        padding-right: 10%;
        padding-left: 10%;
        max-height: 9.9vh;
    }
    .SimCFB{
        padding-right: 10%;
        padding-left: 10%;
        padding-top: 9.9vh;
    }
    .roboNormal {
        color: white;
        font-size: 1rem !important;
        text-decoration: none !important;
        background-color: black;
    }
    .fa-caret-down{
        display: inline;
        display: initial;
    }
    .nav-size{
        font-size: 1.3rem;
        text-align: center;
        background-color: black !important;
    }
    .nav-drop{
        background-color: black;
        color: white;
    }
    .nav-drop-size{
        font-size: 1rem !important;
        text-align: center;
        font-weight: 100 !important;
        background-color: white !important;
        color: black;
    }
    .nav-drop-size:hover {
        color: white !important;
        background-color: gray !important;
    }
    .background-img1{
        padding-top: 15%;
        padding-bottom: 5%;
    }
    .main-title{
        width: 40%;
        display: inline-block;
        margin-left: 15%;
        text-align: center;
        vertical-align: top;
        margin-top: 10%;
        font-size: 3rem;
    }
    .main-car{
        width: 50%;
        margin-left: 25%;
        display: none;
    }
    .main-car-2{
        display: none;
        width: 40%;
    }
    .main-phone-img{
        width: 100%;
        padding-top: 0%;
        margin-top: 0%;
    }
    .main-simbull{
        display: block;
        text-align: left;
    }
    .main-heading{
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        font-size: 4.5rem;
        text-align: center; 
        padding-top: 40%; 
        color: #c5b358;
    }
    .main-padding{
    }
    .main-subheading{
        font-size: 2.5rem;
        color: white;
    
    }
    .mpi1{
        transform: translateX(75px) rotate(-20deg) scale(.9) translateY(-80px);
        margin-top: 5%;
        z-index: 1;
        position: relative;
    }
    .mpi2{
        transform: translateX(-75px) rotate(20deg) scale(.9) translateY(-80px);
        margin-top: 5%;
        z-index: 1;
        position: relative;
    }
    .mpi3{
        z-index: 10;
        position: relative;
        transform:translateY(-80px);
    }
    .signup-input{
        border-radius: .75rem;
        display: block;
        margin: 1%;
        text-align: center;
        width: 40%;
        margin-left: 30%;
    }
    .signup-btn{
        width: 30%;
        margin-left: 35%;
    }
    #gold-triangle{
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 55px solid transparent;
        border-top: 80px solid #eed971;
        margin-left: 46.5%;
    }
    .lc-body-span{
        font-size: 2rem;
    }
    .hiw-mobile{
        display: none;
    }
    .hiw-desktop{
        display: inline;
        display: initial;
    }
    .hiw-section{
        width: 33%;
        display: inline-block;
        padding: 1%;
    }
    .hiw-middle{
        vertical-align: middle;
    }
    .hiw-title{
        font-size: 1rem;
        padding-bottom: 2%;
        color: white;
    }
    .hiw-head{
        display: block;
        font-size: 1.75rem;
        margin-bottom: 0%;
    }
    .hiw-img{
        display: block;
        width: 30%;
        margin-left: 35%;
        margin-bottom: 0%;
    }
    .hiw-details{
        display: block;
        padding-bottom: 10%;
    }
    .signup-title{
        margin-top: 5%;
    }
    .signup-btn{
        margin-bottom: 10%;
    }
    
    .Screens{
        padding-left: 20%;
        padding-right: 20%;
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
        background-size: cover !important;
        background-position: 0vh !important;
        width: 100% !important;
        /*height: 410px !important;*/
        background-blend-mode:soft-light !important;
        background-color: rgba(0, 0, 0, 0.4) !important;
        color: white;
    }
    .caption{
        margin-top: 5%;
        margin-bottom: 10%;
        margin-left: 15%;
        margin-right: 15%;
        text-align: center;
        padding: 0%;
        color: white !important;
    }
    .screens-title{
        padding: 5%;
        padding-bottom: 0%;
        font-size: 2rem;
    }
    .infovideo{
        width: 50%;
        margin-left: 25%;
    }
    .review-title{
        font-size: 2.2rem;
    }
    .reviews{
        padding-bottom: 3%;
    }
    #gold-triangle2{
        width: 0;
        height: 0;
        border-left: 55px solid transparent;
        border-right: 55px solid transparent;
        border-top: 80px solid #c2a92f;
        margin-left: 47.5%;
        margin-top: 0%;
    }
    .seenon-title{
        font-size: 2.2rem;
        text-align: center;
    }
    .logo{
        width: 15%;;
        margin-right: 2.5%;
        margin-left: 2.5%;
    }
    .mobile1{
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-top: 0%;
    }
    .mobile2{
        margin-right: 2.5%;
        margin-left: 2.5%;
        margin-top: 0%;
    }
    .MakeMoney{
        padding: 12% 20% 8% 20%;
        padding-top: 10vh;
    }
    .FAQs{
        padding: 5% 15% 2% 15%;
        text-align: center;
        color: black
    }
    .OurStory{
        padding: 10% 25% 10% 25%;
        font-family: 'Roboto Slab', serif;
        
    }
    .os-img{
        margin-top: 5%;
        width: 30%;
        margin-left: 35%;
    }


    .Footer{
        font-family: 'Roboto Slab', serif;
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 3% !important;
        vertical-align: top;
        background: rgb(235,235,235);
    }
    .f-div1{
        display: inline-block;
        width: 40%;
        vertical-align: top;
    }
    .f-div{
        display: inline-block;
        width: 20%;
        vertical-align: top;
        margin-top: 0%;
    }
    .f-img-div{
    
    }
    .f-img{
        width: 25%;
    }
    .f-div-about{
        padding-right: 10%;
        padding-top: 5%;
        padding-bottom: 5%;
        font-size: 1.2rem;
    }
    .f-div-address{
        padding-right: 10%;
        font-size: 1.2rem;
    }
    .f-div-head{
        font-size: 1.5rem;
        font-weight: 600;
    }
    .f-div-item{
        font-size: 1.2rem;
        font-weight: 400;
        padding-top: 5%;
    }
    .f-icon-div{
    
    }
    .f-icon{
        display: inline-block;
        margin-right: 2%;
    }
    .fa{
        font-size: 1.75rem;
        
    }
    .fa-facebook{
        color: gray;
        width: 100%;
    }
    .fa-instagram{
        color: gray;
    }
    .fa-twitter{
        color: gray;
    }
    .fa-linkedin{
        color: gray;
    }
    .f-copyright{
        text-align: center;
    }

    .h-div{
        width: 23%;
        display: inline-block;
        text-align: center;
        margin-right: 5%;
        margin-left: 5%
    }

    .seenon-articles{
        
        width: 60%;
        margin-left: 20%;
    }

}
.gray2{
    background: rgb(235,235,235) !important
}
.Analysis{
    padding: 3%;
    padding-top: 5vh;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 20%;
    color: black !important;
}
.anal-title{
    font-size: 1rem;
    font-weight: 900;
}
.anal-title-2{
    font-size: 2rem;
    font-weight: 900;
}
.anal-sub{
    font-size: .75rem;
    font-weight: 400;
}
.blk-text{
    color: black !important;
}

/*Two Analysis*/
.TwoAnalysis{
    padding-top: 6vh;
    background-color: rgb(249,249,249);
    min-height: 100vh;
    padding-bottom: 10vh;
}
.TA-Indexes{
    overflow-y: scroll;
    white-space: nowrap;
}
.TA-IndexCard{
    width: 31%;
    font-size: .8rem;
    margin: 3%;
    display: inline-block;
    border-right: 1px solid lightgray;
    margin-right: 1.5%;
    padding-right: 1.5%;
}
.TA-IC-Level{

}
.TA-IC-Left{
    display: inline-block;
    width: 60%;
}
.TA-IC-Right{
    display: inline-block;
    width: 35%;
    text-align: right;
}
.taic-name{
    font-weight: 600;
    font-size: 1rem;
}
.taic-total{
    font-weight: 300;
}
.taic-line{
    display: inline-block;
    /*font-weight: 200;
    font-size: 3rem;
    color: gray;*/
    border-left: 1px solid black;
    height: 100%;
}
.red-text{
    color: red;
}
.TA-Game-Title{
    margin-left: 5%;
    font-weight: 500;
    background-color: rgb(249,249,249);
    border: none;
}
.TAGT-on{
    font-weight: 600;

}
.TAGT-off{
    font-weight: 400;
    color: gray;
    font-size: .9rem;
}
.TA-Game-hr{
    padding: 0%;
    margin: 0%;
    margin-left: 4%;
    margin-bottom: 1%;
}
.TA-Games{
    overflow-y: scroll;
    white-space: nowrap;
}
.TA-GameCard{
    background-color: white;
    width: 29.5%;
    padding: 1% 3%;
    margin-left: 1%;
    display: inline-block;
}
.TA-GC-Innings{
    font-size: .7rem;
    color: gray;
    text-align: left;
}
.TA-GC-Team{
    font-weight: 600;
}
.TA-GC-TeamName{
    display: inline-block;
    width: 60%;
}
.TA-GC-TeamScore{
    display: inline-block;
    width: 40%;
    text-align: right;
}
.TA-Analysis{
    background-color: white;
    margin: 1%;

}
.TA-A-Title{
    font-size: 1.5rem;
    color: gray;
    font-weight: 300;
    font-style: italic;
    padding: 2%;
}
.TA-A-Card{
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
}
.TA-A-hr{
    padding: 1%;
    margin: 0%;
    padding-top: 1%;
    padding-bottom: 1%;
}
.TA-A-btn{
    width: 40%;
    border-radius: 1rem;
    margin-bottom: 3%;
    background-color: white;
    border: none;
    font-style: italic;
}

.TA-News{
    margin-top: 5%!important;
}

.TA-Tables{
    overflow-y: scroll;
    white-space: nowrap;
}

.TA-BiggestMovers{
    background-color: white;
    margin: 0%;
    padding: 2% 8% 2% 8%;
    width: 100%;
    display: inline-block;
}
.TA-BM-Title{
    font-size: 1.35rem;
    font-weight: 600;
}
.TA-BM-hr{
    width: 42%;
    padding: 1%;
    margin: 1%;
}
.green-text{
    color: green
}
.red-text{
    color: red
}
.TA-BM-Table{
    padding-left: 1%;
    padding-right: 1%;
}
.TA-BM-T-changecolumn{
    text-align: center;
    width: 25%;
    display: inline-block;
}
.TA-BM-T-pricecolumn{
    text-align: center;
    width: 25%;
    display: inline-block;
}
.TA-BM-T-teamcolumn{
    width: 50%;
    display: inline-block;
}
.bold-text{
    font-weight: 800;
}
.TA-BM-T-hr{
    margin-top: 0%;
    margin-bottom: 2%;
}
.TA-BM-btn-div{
    overflow-y: scroll;
    white-space: nowrap;
    margin-bottom: 3%;
    padding-bottom: 3%;
}
.TA-BM-btn-div-league{
    overflow-y: scroll;
    white-space: nowrap;
    margin-bottom: 3%;
}
.TA-BM-btn{
    font-size: .75rem;
    border-radius: 1rem;
    margin-right: 1%;
    background-color: white;
    border: none;
    color: black;
}
.TA-BM-btn-league{
    font-size: .9rem;
    border-radius: .1rem;
    margin-right: 0%;
    background-color: white;
    border: 1px solid #c5b358;
}
.TA-BM-btn-league-2{
    font-size: .9rem;
    border-radius: 0rem;
    margin-right: 0%;
    background-color: #c5b358;
    border: 1px solid #c5b358;
    color: white;
}
.tabm-selected{
    background-color: red;
    color: white;
}
.tabm-selected-gain{
    background-color: green;
    color: white;
}
.tabm-selected-gain-league{
    background-color: #c5b358;
    color: black;
    font-size: 1.3rem
}
.tabm-selected-gain-league-2{
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 1.3rem;
    border: none;
    border-top: 1px solid #c5b358;
    border-right: 1px solid #c5b358;
    border-left: 1px solid #c5b358;
}
.tabm-selected-traded{
    background-color: gray;
    color: white;
}
.gold-border{
    border: 1px solid #c5b358;
    border-top: none;
    border: none;
}

/*Polls*/
.TA-Polls{
    
    margin: 1%;
}
.TA-P-Title{
    font-size: 1.5rem;
    color: gray;
    font-weight: 300;
    font-style: italic;
    padding: 2%;
    padding-left: 10%;
}
.TA-P-Container{
    margin-bottom: 3%;
    padding-bottom: 3%;
}
.TA-P-Item{
    display: inline-block;
    margin-right: 1%;
    width: 100%;
    padding: 2%;
}
.TAPI-Question{
    font-size: 1.25rem;
    width: 39%;
    display: inline-block;
    font-weight: 600;
    vertical-align: top;
    padding-top: 1%;
    color: gray;
}
.TAPI-Results{
    width: 59%;
    display: inline-block;
    text-align: center;
}
.TAPI-R-Left{
    width: 50%;
    display: inline-block;
    color: white;
    height: 100%;
    
}
.TAPI-R-Right{
    width: 50%;
    display: inline-block;
    color: white;
    height: 100%;
}
.TAPI-R-Option{
    font-size: .8rem;
    color: black;
    background-color: white;
}
.TAPI-R-Result{
    font-size: 1.15rem;
    font-weight: 800;
    border-radius: 1rem;
}
.TAPI-R-Votes{
    font-size: .8rem;
}
.TA-P-hr{
    height: 2px;
    margin: 2%;
}
.gray-text{
    color: gray;
}

/* Make it a marquee */
.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    background-color: black;
}
.marqueDesktop{
    display: none;
}

.marquee span {
display: inline-block;
padding-left: 1%;
animation: marquee 180s linear infinite;
}
.marqueeDesktop span {
    display: none;
    padding-left: 1%;
    animation: marqueeDesktop 180s linear infinite;
}
.marquee-lineDesktop{
    background-color: #c5b358;
    margin: 1% 0% 0% 0%;
    padding: 0%;
    display: none;
    }

/* Make it move */
@keyframes marquee {
0% {
    transform: translate(0, 0);
}
100% {
    transform: translate(-100%, 0);
}
}
.marquee-text{
color:  #c5b358;
padding-top: 1%;
}
.marquee-line{
background-color: #c5b358;
margin: 1% 0% 0% 0%;
padding: 0%;
}

/* Article */
.Article{
    padding: 3%;
    padding-top: 6vh;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 20%;
    color: black !important;
}


/*SimCFB */
.conferenceDiv{
    padding: 3%;
    margin-left: 20%;
}
.conferenceTitle{
    font-size: 1.2rem;
    font-weight: 600;
}

@media only screen and (min-width: 1024px) {
    .TwoAnalysis{
        padding-top: 0%;
    }
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
    .anal-title{
        font-size: 1.5rem;
    }
    .anal-title-2{
        font-size: 2.25rem;
    }
    .anal-sub{
        font-size: 1.25rem;
    }
    .marqueeDesktop{
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        background-color: black;
    }
    /* Make it move */
    @keyframes marqueeDesktop {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0);
        }
    }
    .marqueeDesktop span {
        display: inline-block;
        padding-left: 1%;
        animation: marqueeDesktop 210s linear infinite;
        padding-bottom: 1%;
    }
    .marquee{
        display: none;
    }
    .marquee-lineDesktop{
        background-color: #c5b358;
        margin: 1% 0% 0% 0%;
        padding: 0%;
        display: inline;
        }
    .TA-IndexCard{
        width: 12.25%;
        font-size: .8rem;
        margin: 1%;
        margin-bottom: 0%;
        display: inline-block;
    }
    .TA-GameCard{
        background-color: white;
        width: 13.85%;
        padding: 1% 3%;
        margin-left: 1%;
        display: inline-block;
    }
    .TA-BiggestMovers{
        background-color: white;
        margin: 0%;
        padding: 2% 8% 2% 8%;
        width: 50%;
        display: inline-block;
    }
    .TA-News{
        margin-top: 0%!important;
    }
    /*SimCFB */
    .conferenceDiv{
        padding: 3%;
        display: inline-block;
        width: 80%;
        margin-left: 0%;
        vertical-align: top;
    }
    .conferenceTitle{
        font-size: 1.2rem;
        font-weight: 600;
    }
}
.NavBar2 {
    background-color: white;
    color: black;
    max-height: 9.9vh;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}



.nav-logo2{
    width: 100%;
}
.nav-toggle2{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 5px 15px 0px;
}

.nav-drop-size2{
    font-size: 1rem !important;
    text-align: center;
    font-weight: 100 !important;
    /*background-color: black !important;*/
    background-color: white;
    color: black;
}
.nav-drop-size2:hover {
    /*color: black !important;
    background-color: rgba(95, 91, 91, 0.068) !important;*/
    color: white !important;
    background-color: gray !important;
    text-decoration: none;
}
.nav-size2{
    font-size: 1.3rem;
    text-align: center;
    background-color: white;
    /*border: 1px solid gray;*/
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.roboNormal2 {
    /*color: white;*/
    color: black;
    background-color: white;
    font-size: 1rem !important;
    text-decoration: none !important;
}

.login-btn2{
    border: 2px solid black;
    border-radius: .2em;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 0px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px;
}
.login-btn3{
    border: 2px solid black;
    border-radius: .2em;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 0px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 5px;
}
.signup-btn2{
    border-radius: .2em;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 29px 0px;
    background-color: black;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    margin: 5px;
}

.Top{
    padding-top: 6vh;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
}
.Top-CTA{
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1%;
    padding-top: 3vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.Top-CTA-title{
    font-size: 3.1em;
    font-weight: bold;
    margin-bottom: 3%;
    width: 100%;
    color: white;
    background-color: black;
}

.Top-CTA-title2{
    font-size: 2.25em;
    font-weight: bold;
    margin-bottom: 3%;
    margin-top: 4vh;
    text-align: center;
    color: white;
}
.Top-CTA-subtitle{
    font-weight: 300;
    font-size: 1.2em;
    margin-bottom: 5%;
    color: white;
    width: 100%;
}
.Top-CTA-subtitle2{
    font-weight: 300;
    font-size: .9em;
    width: 90%;
    margin-left: 5%;
    margin-top: 5px;
    margin-bottom: 15px;
    line-height: 1.75;
    text-align: center;
    color: white;
}
.Top-CTA-email{
    font-size: .8em;
    font-weight: 300;
    margin-bottom: 3px;
    margin-left: 2px;
}
.Top-CTA-input{
    width: 68%;
    margin-left: 0%;
    padding: 12px;
    border-radius: .2em;
    border: 2px solid rgb(200,200,200);
    margin-bottom: 5px;
}
.Top-CTA-btn{
    width: 25%;
    margin-left: 5%;
    margin-right: 0%;
    padding: 12px;
    border-radius: .3em;
    background-color: rgb(0,0,0,.9);
    color: white;
    border: none;
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-input5{
    width: 100%;
    margin-left: 0%;
    padding: 12px;
    border-radius: .2em;
    border: 2px solid rgb(200,200,200);
    margin-bottom: 5px;
}
.Top-CTA-btn5{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    padding: 12px;
    margin-bottom: 5px;
    border-radius: .3em;
    background-color: rgb(0,0,0,.9);
    color: white;
    border: none;
    outline: none;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    
}
.Top-CTA-btn2{
    width: 95%;
    margin-left: 2.5%;
    margin-right: 0%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: .3em;
    background-color: #e0cb07;
    color: black;
    font-weight: 500;
    font-size: .9rem;
    border: none;
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-btn2:hover{
    font-size: 1rem;
    -webkit-filter: grayscale(.3);
            filter: grayscale(.3);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    text-decoration: none;
}
.Top-CTA-btn3{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: .3em;
    background-color: #e0cb07;
    color: black;
    font-weight: 500;
    font-size: .9rem;
    border: none;
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-btn3:hover{
    font-size: 1rem;
    -webkit-filter: grayscale(.3);
            filter: grayscale(.3);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    text-decoration: none;
}
.Top-CTA-free{
    font-weight: 400;
    color: white;
    font-size: .8em;
}
.Top-CTA-free2{
    font-weight: 400;
    color: white;
    font-size: .8em;
    text-align: center;
}
.Top-Img{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-bottom: 3%;
    text-align: center;
    padding-top: 1vh;
    max-width: 100%;
}

.op-img {
    height: 33rem;
    width: 35rem;
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width: 450px) {
    .op-img {
        height: 20rem;
        width: 20rem;   
    }
    .Top {
        height: 120vh;
        width:100%;
        max-height: 120vh;
    }
    .Top-Img {
        max-width: 100%;
    }
}
@media screen and (max-width: 375px) and (min-width: 320px) {
    .op-img {
        height: 20rem;
        width: 20rem;   
    }
    .Top {
        height: 150vh;
        width:100%;
    }
    .Top-Img {
        max-width: 100%;
    }

    .top-top {
        display: flex;
        flex-direction: column;
    }
}

.SocialProof{
    background-color: black;
    color: white;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
}
.SocialProof-title{
    width: 90%;
    margin-left: 5%;
    font-weight: 400;
}
.SocialProof-subtitle{
    margin-top: 20px;
    margin-bottom: 40px;
    color: rgb(220,220,220);
    font-weight: 300;
}
.SocialProof-Div{
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
}
.SocialProof-Item{
    display: inline;
    width: 100%;
}
.SocialProof-Item-Title{
    font-size: 3em !important;
    font-weight: 700;
}
.spanTitle{
    font-size: 1rem !important; 
    font-weight: 400 !important;
}
.SocialProof-hr{
    width: 25%;
    background-color: rgb(90,90,90);
    margin-top: 0;
    margin-bottom: 5px;
}
.SocialProof-Item-Desc{
    margin-bottom: 50px;
}

.landing-signup{
    width: 95%;
    margin-left: 2.5%;
}

.top-container {
    width: 90%;
    height: 75vh;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

@media screen and (max-width: 1215px) {
    .top-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-header {
        margin-right: 30%;
        width: 100%;
    }
    .top-body {
        margin-right: 30%;
        width: 100%;
    }
    .top-bottom {
        margin-right: 30%;
        width: 100%;
    }
    .top-container-left {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-container-right {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .top-img {
        width: 100%;
        
        height: 100%;
    }
    
}

.top-container-left {
    max-width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    background-color: black;
}

.top-container-right {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.top-header {
    width: 55%;
    font-size: 3rem;
    margin-left: 30%;
}

.top-body {
    width: 55%;
    font-size: 1.4rem;
    margin-left: 30%;
}

.top-bottom {
    width: 55%;
    font-size: 1.7rem;
    margin-left: 30%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    grid-column-gap: 3.5%;
    -webkit-column-gap: 3.5%;
            column-gap: 3.5%;
}

.svg {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: .2rem;
    border-radius: 5px;
    border: 1px solid white;
    width: 9rem;
}

.go-to {
    width: 11rem;
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.top-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 2.3rem;
    align-items: center;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    margin-top: 5%;
}


.svg:hover {
    border: 1px solid #c4b454;
    cursor: pointer;
    
}


@media only screen and (min-width: 594px) {
    .Top{
        padding-left: 10%;
        padding-right: 10%;
        
    }
    .Top-CTA-title{
        font-size: 3.9em;
        font-weight: 600;
        margin-bottom: 3%;
        margin-left: 20%;
    }
    .Top-CTA-subtitle{
        font-weight: 300;
        font-size: 1.5em;
        margin-bottom: 5%;
        margin-left: 20%;
    }
    .Top-CTA-title2{
        font-size: 3.9em;
        font-weight: 600;
        margin-bottom: 3%;
        margin-left: 20%;
    }
    .Top-CTA-subtitle2{
        font-weight: 300;
        font-size: 1.5em;
        margin-bottom: 5%;
        margin-left: 20%;
    }
    .svg {
        margin-left: 20%;
    }
}

@media only screen and (min-width: 740px) {
    .SocialProof-title{
        width: 70%;
        margin-left: 15%;
    }
}

@media only screen and (min-width: 990px) {
    .Top{
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;
        height: 80vh;
    }
    
    
    .Top-CTA-title{
        font-size: 3em;
        font-weight: 600;
        margin-bottom: 5%;
    }
    .Top-CTA-title2{
        font-size: 3em;
        font-weight: 600;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 990px) {
    .NavBar2{
        padding-right: 10%;
        padding-left: 10%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .nav-logo2{
        height: 100%;
        margin-top: -15px;
        padding-top: -15px;
        margin-bottom: -15px;
        padding-bottom: -15px;
    }
    .nav-drop2{
        background-color: white;
        color: black;
    }
    .nav-drop-size2{
        font-size: 1rem !important;
        text-align: center;
        font-weight: 100 !important;
        background-color: white !important;
        color: black;
    }
    .nav-drop-size2:hover {
        color: white !important;
        text-decoration: none;
        background-color: gray !important;
    }
    .nav-size2{
        font-size: 1.3rem;
        text-align: center;
        background-color: white !important;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    }
    .roboNormal2 {
        color: black;
        font-size: 1rem !important;
        text-decoration: none !important;
        background-color: white;
    }
    .Top{
        padding-top: 8vh;
        vertical-align: top;
    }
    .Top-CTA{
        display: inline-block !important;
        width: 50%;
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 7%;
        padding-top: 10vh;
        vertical-align: top;
    }
    .Top-CTA-title{
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 3%;
        width: 70%;
    }
    .Top-CTA-subtitle{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
        width: 70%;
    }
    .Top-CTA-title2{
        font-size: 3em;
        font-weight: 600;
        margin-bottom: 3%;
    }
    .Top-CTA-subtitle2{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
    }
    .Top-CTA-email{
        font-size: .8em;
        font-weight: 300;
        margin-bottom: 3px;
        margin-left: 2px;
    }
    .Top-CTA-input{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-input5{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn5{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        margin-bottom: 0px;
        padding: 12px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-btn2{
        width: 90%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: #e0cb07;
        color: black;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-free{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-CTA-free2{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-Img{
        display: inline-block;
        width: 50%;
        padding-left: 1%;
        padding-right: 1%;
        padding-bottom: 3%;
        text-align: center;
        padding-top: 7vh;
    }
    .top-img-src{
        width: 90%;
    }

    .SocialProof-Div{
        padding-left: 5%;
        padding-right: 5%;
        text-align: left;
    }
    .SocialProof-Item{
        display: inline-block;
        width: 31.3%;
        margin-left: 2%;
    }
}
@media only screen and (min-width: 1024px) {
    .Top-CTA-title{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 5%;
    }
    .Top-CTA-title2{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 1314px) {
    .Top{
        padding-top: 12vh;
        vertical-align: top;
    }
    .Top-CTA{
        display: inline-block;
        width: 45%;
        padding-left: 7%;
        padding-right: 2%;
        padding-bottom: 7%;
        padding-top: 15vh;
        height: 83vh;
        vertical-align: top;
    }
    .Top-CTA-title{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 3%;
    }
    .Top-CTA-subtitle{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
    }
    .Top-CTA-title2{
        font-size: 3.5em;
        font-weight: 600;
        margin-bottom: 3%;
    }
    .Top-CTA-subtitle2{
        font-weight: 300;
        font-size: 1.2em;
        margin-bottom: 10%;
    }
    .Top-CTA-email{
        font-size: .8em;
        font-weight: 300;
        margin-bottom: 3px;
        margin-left: 2px;
    }
    .Top-CTA-input{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-input5{
        width: 68%;
        margin-left: 0%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .Top-CTA-btn5{
        width: 25%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        margin-bottom: 0px;
        border-radius: .3em;
        background-color: rgb(0,0,0,.9);
        color: white;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-btn2{
        width: 90%;
        margin-left: 5%;
        margin-right: 0%;
        padding: 12px;
        border-radius: .3em;
        background-color: #e0cb07;
    color: black;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-free{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-CTA-free2{
        font-weight: 400;
        color: gray;
        font-size: .8em;
    }
    .Top-Img{
        display: inline-block;
        width: 50%;
        padding-left: 1%;
        padding-right: 3%;
        padding-bottom: 3%;
        text-align: center;
        padding-top: 3vh;
    }
    .top-img-src{
        width: 90%;
    }


    
    .SocialProof{
        background-color: black;
        color: white;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    .SocialProof-title{
        width: 70%;
        margin-left: 15%;
        font-weight: 400;
    }
    .SocialProof-subtitle{
        margin-top: 20px;
        margin-bottom: 40px;
        color: rgb(220,220,220);
        font-weight: 300;
    }
    .SocialProof-Div{
        padding-left: 10%;
        padding-right: 10%;
        text-align: left;
    }
    .SocialProof-Item{
        display: inline-block;
        width: 31.3%;
        margin-left: 2%;
    }
    .SocialProof-Item-Title{
        font-size: 3em !important;
        font-weight: 700;
    }
    .spanTitle{
        font-size: 1rem !important; 
        font-weight: 400 !important;
    }
    .SocialProof-hr{
        width: 15%;
        background-color: rgb(90,90,90);
        margin-left: 1%;
    }
}
.a-item{
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
    font-size: 1.25rem;
    margin-bottom: 3%;
    margin-left: 10%;
    color: black;
}
.a-btn{
    border-radius: .8rem;
    text-align: center;
    width: 80%;
}
.ContactUs{
    padding: 2% 10% 10% 10%;
    font-family: 'Roboto Slab', serif;
    padding-top: 7vh;
}
.c-Head{
    font-size: 2rem;
}
.c-txt{

}
.c-name{

}
.c-email{

}
.c-message{

}
.c-btn-div{
    text-align: center;
}
.c-btn{
    border-radius: .8rem;
}
.c-input{
    width: 100%;
    border-radius: .8rem;
    text-align: center;
}

/* User and Edit Account  */
.UserAccount{
    padding: 2% 0% 10% 0%;
    font-family: 'Roboto Slab', serif;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
}
.ua-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
}
.ua-left{
    display: inline-block;
    width: 35%;
    font-weight: 600;
}
.ua-right{
    display: inline-block;
    width: 65%;
    text-align: right;
    font-weight: 400;
}
.ua-edit-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    text-align: center;
}
.EditAccount{
    padding: 2% 0% 10vh 0%;
    font-family: 'Roboto Slab', serif;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
}
.ua-div-e{
    background-color: white;
    margin-bottom: 0%;
    padding: 3% 2% 3% 2%;
    font-size: 1.1rem;
}
.ua-hr{
    padding:0%;
    margin: 0%;
}


/* Header */
.Header{
    background-color: black;
    width: 100%;
    height: 6vh;
    color: white;
    z-index: 1000;
    position: fixed;
    top: 0;
    text-align: center;
    font-size: 1.75rem;
    font-family: 'Roboto Slab', serif;
}
.header-btn{
    position: fixed;
    top: 0;
    left: 0;
    height: 6vh;
    color: gray;
    background-color: black;
    font-size: 1rem;
}
.fa-angle-left{
    color: white;
}


/* Transfers */
.Transfers{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.transfer-deposit{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    text-align: center;
}
.transfer-deposit-head{
    font-size: 2rem;
    font-weight: 600;
}
.transfer-deposit-body{
    padding-top: 2%;
    padding-bottom: 2%;
}
.transfer-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    text-align: center;
}
.transfer-deposit-input{
    border-radius: 1rem;
    padding: 5% 0% 5% 0%;
    text-align: center;
    font-size: 1.5rem;
    width: 40%;
    margin-bottom: 5%;
}
.transfer-promo-input{
    border-radius: 1rem;
    padding: 3% 2% 3% 2%;
    text-align: center;
    font-size: 1.5rem;
    width: 80;
    margin-bottom: 2%;
}
.transfer-depsoit-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}
.reoccurringDepositDiv{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
}
.reoccurringDepositSpan{
    font-size: 1rem;
    font-weight: 400;
}

/* Withdraw */
.Withdraw{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.withdraw-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.withdraw-head{
    font-size: 2rem;
    font-weight: 600;
}
.withdraw-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.withdraw-body-input{
    border-radius: 1rem;
    font-size: 1.2rem;
    padding: 2.5% 0% 2.5% 0%;
    text-align: center;
}
.withdraw-cash{
    font-size: .8rem;
}
.withdraw-input{
    border-radius: 1rem;
    padding: 5% 0% 5% 0%;
    text-align: center;
    font-size: 1.5rem;
    width: 40%;
    margin-bottom: 5%;
}
.withdraw-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}
.withdraw-textarea{
    width: 80%;
    height: 20vh;
    border-radius: 1rem;
}

/* RequestPennant */
.RequestPennant{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.rp-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.rp-head{
    font-size: 2rem;
    font-weight: 600;
}
.rp-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.rp-cash{
    font-size: .8rem;
}
.rp-input{
    border-radius: 1rem;
    padding: 5% 0% 5% 0%;
    text-align: center;
    font-size: 1.25rem;
    width: 50%;
    margin-bottom: 5%;
}
.rp-option{
    font-size: 1rem;
}
.rp-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}


/* Subscription */
.Subscription{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.subscription-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.subscription-head{
    font-size: 2rem;
    font-weight: 600;
}
.subscription-body{
    padding-top: 1%;
    padding-bottom: 1%;
}
.subscription-btn{
    border-radius: 1rem;
    padding: 3% 5% 3% 5%;
    background-color: lightgray;
}
.cancelDiv{
    margin-top: 2%;
    background-color: white;
    text-align: center;
    padding: 3%;
}

/* Subscription Two */
.SubscriptionTwo{
    padding: 2% 3% 20% 3%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.subtwo-title{
    font-size: 1.5rem;
    text-align: left;
    font-weight: 600;
    margin-bottom: 1%;
}
.subtwo-subtitle{
    font-size: 1rem;
    text-align: left;
    font-weight: 200;
    margin-bottom: 5%;
    color: gray;
}
.subtwo-box{
    text-align: left;
    background-color: white;
    padding: 5%;
    width: 100%;
    margin-left: 0%;;
    box-shadow: 0 0 5px 2px rgb(200, 200, 200);
    border-radius: .5rem;
    margin-bottom: 5%;
}
.subtwo-box-head{
    font-weight: 800;
    padding-bottom: 2%;
    font-size: 3rem;
    color: #c5b358;
    -webkit-text-stroke: .5px black;
    text-align: center;
}
.subtwo-box-price{
    font-weight: 400;
    font-size: 2rem;
    color: black;
    text-align: center;
    margin-top: 0%;
}
.subtwo-box-active{
    font-weight: 200;
    font-size: .8rem;
    color: rgb(0, 160, 0);
    text-align: center;
    margin-top: 0%;
    -webkit-text-stroke: .5px rgb(0, 160, 0);
}
.subtwo-box-cancel{
    font-weight: 200;
    font-size: .8rem;
    color: red;
    text-align: center;
    margin-top: 0%;
    -webkit-text-stroke: .5px red;
}
.subtwo-box-length{
    font-weight: 400;
    font-size: 1rem;
    color: black;
    text-align: center;
    margin-top: 0%;
}
.subtwo-box-img-div{

}
.subtwo-box-img{
    width: 50%;
    margin-left: 25%;
}
.subtwo-buy-btn-div{
    text-align: center;
    margin-bottom: 5%;
}
.subtwo-buy-btn{
    text-align: center;
    border-radius: 1rem;
    background-color: #c5b358;
    padding: 2% 4% 2% 4%;
    width: 40%;
}
.subtwo-box-body{
    font-weight: 400;
    font-size: 1.35rem;
    white-space: normal;
    white-space: initial;
}
.subtwo-box-item{
    padding-bottom: 3%;
}
.subtwo-box-item-body{
    font-weight: 200;
    font-size: 1rem;
    padding-left: 6%;
}
.subtwo-section{
    font-size: 0.8rem;
}
.subtwo-receipt-view{
    text-align: center;
}
.subtwo-receipt-view-btn{
    color: black;
    border: none;
    outline: none;
    background-color: white;
    text-decoration: underline;
}
.subtwo-receipt-table{
    width: 100%;
    text-align: center;
    margin-top: 3%;
}
.subtwo-receipt-table-headers{

}
.subtwo-receipt-head-hr{
    background-color: black;
    margin-top: 0%;
    margin-bottom: 2%;
}
.subtwo-receipt-hr{
    background-color: black;
    margin-top: 0%;
    margin-bottom: 2%;
    opacity: .2;
}
.subtwo-cancel-div{
    width: 70%;
    margin-left: 15%;
    text-align: center;
    margin-top: 5%;
    font-size: 1.1rem;
    padding-bottom: 5%; 
}
.subtwo-cancel-body{
    width: 100%;
    margin-left: 2.5%;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    padding-top: 3%; 
    text-decoration: line-through;
}
.subtwo-cancel-btn{
    border-radius: .8rem;
    padding: 1% 2%;
}

/* Referrals */
.Referrals{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.referrals-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.referrals-head{
    font-size: 2rem;
    font-weight: 600;
}
.referrals-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.ref-create-btn{
    border-radius: 1rem;
    padding: 1% 3% 1% 3%;
}
.ref-copy-btn{
    border-radius: 1rem;
    padding: 1% 3% 1% 3%;
}

/* Notifications */
.Notifications{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
}
.notifications-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.notifications-head{
    font-size: 2rem;
    font-weight: 600;
}
.notifications-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.notifications-item{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    font-size: 1.1rem;
    vertical-align: top;
}
.notifications-left{
    display: inline-block;
    width: 85%;
    font-weight: 600;
}
.notifications-right{
    display: inline-block;
    width: 15%;
    text-align: right;
    font-weight: 400;
    vertical-align: top;
}



/* GiveReview */
.GiveReview{
    padding: 2% 0% 20% 0%;
    padding-top: 7vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
}
.givereview-div{
    background-color: white;
    margin-bottom: 1%;
    padding: 2% 2% 2% 2%;
    text-align: center;
}
.givereview-head{
    font-size: 2rem;
    font-weight: 600;
}
.givereview-body{
    padding-top: 10%;
    padding-bottom: 10%;
}
.givereview-left{
    display: block;
    text-align: center;
    font-weight: 600;
}
.givereview-right{
    display: block;
    text-align: center;
    font-weight: 400;
    width: 100%;
    font-size: 1.5rem;
}
.givereview-select{
    width: 20%;
    background-color: rgb(237, 237, 237);
}
.givereview-txt{
    width: 95%;
    margin-top: 5%;
}


/*Paypal */
.Transfer-Paypal-Sheet{
    padding: 5%;
    margin-top: 7vh;
    text-align: center;
    font-family: 'Roboto Slab', serif;
}
.Transfer-Paypal-Sheet-Div{
    
}
.Transfer-Paypal-Sheet-Title{
    font-weight: 600;
    font-size: 3rem;
}
.Transfer-Paypal-Sheet-Amount{
    display: inline-block;
    width: 50%;
    text-align: right;
}
.Transfer-Paypal-Sheet-Amount-txt{
    display: inline-block;
    width: 50%;
    text-align: left;
}


/* PayPal */
.paypal-subscription-amount{
    padding: 5%;
    font-size: 2rem;
}

/* Tutorial */
.Tutorial{
    padding: 2% 0% 20% 0%;
    padding-top: 6vh;
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    font-family: 'Roboto Slab', serif;
    font-weight: 600;
    text-align: center;
}
.tutorial-div{
    margin-top: 3%;
}
.tutorial-div-title{
    font-size: 2rem;
}
.tutorial-video{
    width: 100%;
    margin-left: 0%;
    border-radius: 1rem;
    margin-top: 1%;
    margin-bottom: 5%;
}
.tutorial-contents{
    text-align: left;
    padding-left: 3%;
    font-size: 1.25rem;
}
.tutorial-contents-item{
    padding-left: 5%;
    font-size: 1rem;
    text-decoration: underline;
}


/*Deposit*/
.deposit-btn{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 1px solid gray;
    font-size: 1.5em;
    color: gray;
    border-radius: 0.1em;
}

.deposit-btn-gold{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 3px solid #c5b358;
    font-size: 1.5em;
    color: #c5b358;
    border-radius: 0.1em;
    font-weight: 500;
}



@media only screen and (min-width: 1024px) {
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
    .Account{
        padding-top: 5%;
        width: 100%;
    }
    .Header{
        height: 9vh;
        font-size: 3.5rem;
        padding-bottom: 2%;
        display: none;
    }
    .header-btn{
        height: 9vh;
        font-size: 1.5rem;
        width: 10%;
        display: none;
    }
    .SubscriptionTwo{
        padding-left: 33%;
        padding-right: 33%;
    }
}
.PTotal{
    padding: 3%;
    padding-top: 7vh;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 0%;
}
.p-total-txt{
    display: inline-block;
    width: 60%;
    font-size: 2rem;
    font-weight: 900;
}
.p-total-amt{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 2rem;
    font-weight: 900;
} 
.p-total-txt2{
    display: inline-block;
    width: 60%;
    font-size: 1.25rem;
    font-weight: 400;
}
.p-total-amt2{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 1.25rem;
    font-weight: 400;
} 
.p-total-account{
    text-align: center;
    width: 100%;
    font-weight: 900;
    padding-bottom: 0%;
    margin-bottom: 1%;
}
.p-total-hr{
    width:20%;
    padding-top: 0%;
    margin-top: 0%;
    padding-bottom: 0%;
    margin-bottom: 0%;
}
.p-total-value{
    font-size: 3rem;
    font-weight: 900;
    padding-top: 0%;
    margin-top: 0%;
    text-align: center;
    color:  #c5b358;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 2.5px 2.5px #d1d1d1;
}
.p-total-cash{
    text-align: center;
    font-weight: 200;
}
.p-total-cash-amt{
    color:  #c5b358;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}
.p-graph{
    width: 100%;
    
}
.p-graph-return{
    color: #c5b358;
    padding-left: 3%;
}
.p-graph-top{
    width: 94%;
}
.PReturn{
    padding: 3%;
    font-family: 'Roboto Slab', serif;
}
.p-return-txt{
    display: inline-block;
    width: 60%;
    font-size: 1.25rem;
    font-weight: 400;
}
.p-return-amt{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 1.25rem;
    font-weight: 400;
} 
.p-return-txt2{
    display: inline-block;
    width: 60%;
    font-size: 1rem;
    font-weight: 400;
}
.p-return-amt2{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 1rem;
    font-weight: 400;
} 
.PTransfer{
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 0%;
}
.p-transfer-txt{
    width: 100%;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;
}
.p-transfer-amt{
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
} 
.transfer-btn{
    width: 60%;
    margin-left: 20%;
    border-radius: 1rem;
    background-color:rgb(187, 187, 187);
    font-size: 1.1rem;
    padding: 1%;
    box-shadow: 2.5px 2.5px #d1d1d1;
}
.p-transfer-hr{
    padding-bottom: 0%;
    margin-bottom: 0%;
}


/*PHistory*/
.PHistory{
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 20%;
    padding-top: 0%;
}
.p-history{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: black;
}
.p-selected{
    font-size: 1.5rem;
    font-weight: 900;
}
.gray-back{
    background-color: rgb(237, 237, 237);
    padding-left: -3%;
    padding-right: -3%;
}
.p-history-title{
    display: inline-block;
    width: 80%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.p-history-icon-div{
    display: inline-block;
    width: 10%;
}
.p-history-hr{
    margin: 0%;
    padding: 0%;
}


/*PPayout*/
.PPayout{
    margin-top: 5%;
    font-family: 'Roboto Slab', serif;
}
.p-payouts-div{
    text-align: center;
    margin-top: 5%;
}
.p-payouts-btn{
    border-radius: 1rem;
    font-size: 1.3rem;
    width: 100%;
    background-color: #c5b358;
    box-shadow: 2.5px 2.5px  2.5px 2.5px #d1d1d1;
}


/*Collect Payouts*/
.CollectPayouts{
    background-color: rgb(237, 237, 237);
    z-index: 10000;
    width: 95vw;
    height: 80vh;
    margin-left: 1.5vh;
    margin-top: 7.5vh;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 1rem;
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    overflow-y: scroll;
    padding-bottom: 10%;
}
.CollectPayouts2{
    background-color: rgb(184, 184, 184);
    z-index: 10000;
    width: 92.5vw;
    height: 80vh;
    margin-left: 2.5vh;
    margin-top: 5vh;
    position: fixed;
    top: 0;
    left: 0;
    outline: 1px black solid;
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    overflow-y: scroll;
}
.cp-close{
    text-align: right;
}
.cp-title{
    font-size: 1.75rem;
    font-weight: 900;
    text-align: center;
    text-decoration: underline;
}
.cp-title-num{
    font-size: 1.5rem;
    text-align: center;
    font-weight: 200;
}
.cp-card-holder{
    padding: 3%;
    padding-top: 10%;
    min-height: 30vh;
}
.cp-card-left{
    display: inline-block;
    width: 85%;
}
.cp-card-date{
    font-size: 0.75rem;
}
.cp-card-right{
    display: inline-block;
    width: 15%;
}
.cp-card-total{
    padding: 1.5%;
    font-weight: 900;
}
.cp-card-total-left{
    display: inline-block;
    width: 50%;
    text-align: left;
}
.cp-card-total-right{
    display: inline-block;
    width: 50%;
    text-align: right;
}
.cp-card-total-hr{
    background-color: black;
    border: 0 none;
    color: #eee;
    height: 1px;
}
.align-bottom{
    
}
.cp-question-div{
    padding: 3%;
}
.cp-question-div-open{
    padding: 3%;
    text-align: center
}
.cp-question{
    font-weight: 600;
}
.cp-question-open{
    font-weight: 600;
}
.cp-answers{
    padding: 3%;
}
.cp-answers-input{ /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
    transform: scale(2);
    padding: 10px;
}
.cp-answers-input-open{
    width: 90%;
    border-radius: 1rem;
    margin: 1%;
    text-align: center;
}
.cp-answers-txt{
    padding-left: 5%;
}
.cp-card-btn-div{
    text-align: center;
}
.cp-card-btn{
    border-radius: 1rem;
    font-size: 1rem;
    width: 75%;
    background-color: #c5b358;
    box-shadow: 2.5px 2.5px #d1d1d1;
    padding: 2% 5% 2% 5%;
}
.cp-card-btn-2{
    border-radius: 1rem;
    font-size: 1.5rem;
    width: 95%;
    background-color: #c5b358;
    box-shadow: 2.5px 2.5px #d1d1d1;
    padding: 5% 12% 5% 12%;
}

/*Colors*/
.green{
    color: green;
}
.red{
    color: red;
}

/* Bonus */
.Bonus{

}
.bonus-btn{
    width: 60%;
    margin-left: 20%;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: #c5b358;
}
.collect-bonus-div{
    text-align: center;
    width: 100%;
}
.collect-bonus-btn{
    width: 80%;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: #c5b358;
}

.p-announcement{
    text-align: center;
    padding: 2%;
}
.p-a-under{
    text-decoration: underline;
}

/* Trades */
.PTrade-Card{
    width: 100%;
}
.PTrade-Card-Left{
    width: 100%;
    display: inline-block; 
    background-color: white;
    width: 8%;
    height: 100%; 
    padding-bottom: 100%;
    margin-bottom: -100%; 
    vertical-align: top;
    font-size: 1.4rem;
    font-weight: 200;
    text-align: center;
    padding-top: 12px;
}
.PTrade-Card-Right{
    display: inline-block;
    background-color: white;
    width: 92%;
}
.PTrades{
    background-color: rgb(245,245,245);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
}
.pt-head{
    text-align: center;
    font-weight: 200;
    background-color: white;
    color: gray;
    font-size: 1.2rem;
}
.pt-view{
    text-align: center;
    font-weight: 200;
    background-color: white;
    color: gray;
    font-size: 1.2rem;
    margin-top: 3px;
}
/* End Trades */

@media only screen and (min-width: 1024px) {
    .Portfolio{
        padding-left: 25%;
        padding-right: 25%;
    }
    .CollectPayouts{
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;
    }
    .CollectPayouts2{
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;
    }
    .cp-close{
        margin-right: 27%;
        text-align: center;
    }
    .fa-times-circle{
        margin-right: 27%;
        margin-top: 5%;
    }
    .cp-card-btn-2{
        border-radius: 1rem;
        font-size: 1rem;
        width: 75%;
        background-color: #c5b358;
        box-shadow: 2.5px 2.5px #d1d1d1;
        padding: 5% 12% 5% 12%;
    }
    .loading-div{
        background-color: white;
        height: 50vh;
        width: 50vw;
        position: absolute;
        top: 50;
        left: 50;
        padding-left: 25%;
        padding-right: 25%;
        margin-left: 25%;
        margin-top: 15%;
        opacity: .5;
    }

    /* Trades */
    .PTrade-Card{
        display: inline-block;
        width: 49%;
        margin-left: 0.5%;
        margin-right: 0.5%;
    }
    .PTrade-Card-Left{
        width: 100%;
        display: inline-block; 
        background-color: white;
        width: 10%;
        height: 100%; 
        padding-bottom: 100%;
        margin-bottom: -100%; 
        vertical-align: top;
        font-size: 1.3rem;
        font-weight: 200;
        text-align: center;
        padding-top: 6px;
        text-align: right;
    }
    .PTrade-Card-Right{
        display: inline-block;
        background-color: white;
        width: 90%;
    }
    .PTrades{
        background-color: white;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
    .pt-head{
        text-align: center;
        font-weight: 200;
        background-color: white;
        color: gray;
        font-size: 1.1rem;
    }
    .pt-view{
        text-align: center;
        font-weight: 200;
        background-color: white;
        color: gray;
        font-size: 1.1rem;
        margin-top: 3px;
    }
    /* End Trades */

}
.desktopOnly{
    display: none;
}
.desktopOnly2{
    display: none;
}
.Holdings{
    background-color: rgb(237, 237, 237);
    min-height: 100vh;
    padding-bottom: 8.5vh;
    padding-top: 6vh;
    font-family: 'Roboto Slab', serif;
}
.Bar{
    width: 100%;
    min-height: 8.5vh;
    position: fixed;
    bottom: 0;
    background-color: white;
    outline: #c5b358 1px solid;
    z-index: 100;
    
}
.Bar-div{
    display: inline-block;
    width: 20%;
    text-align: center;
    font-size: 100%;
    height: 100%;

    color: black;
}
.Bar-div2{
    display: inline-block;
    width: 20%;
    text-align: center;
    font-size: 100%;
    height: 100%;

    color: black;
}
.bar-selected{
    background-color: darkgray;
}
.bar-white{
    color: white;
}
.Bar-img{
    width: 40%;
    padding-left: 5%;
    padding-top: 15%;
}
.desktopOnly-Account{
    display: none;
}
.showTeams{
    display: none;
}
.sm-txt{
    text-align: center;
    font-size: .75rem;
}
.holdings-offers-div{
    width: 100%;
    background-color: white;
    margin-bottom: 1%;
    text-align: center;
    font-size: 1.3rem;
    padding: 3%;
}
.waiting-img{
    width: 100%;
    margin-left: 0%;
    margin-top: 0%;
}
.fa-times-circle{
    z-index: 100;
}

/*OpenOffers*/
.holdings-offers-team-div{
    width: 100%;
    margin-bottom: 1%;
    padding: 3%;
    background-color: white;
    
}
.holdings-offers-team-right{
    display: inline-block;
    width: 50%;
    text-align: right;
}
.holdings-offers-team-name{
    display: inline-block;
    width: 50%;
    font-size: 1.2rem;
    font-weight: 600;
}
.holdings-offers-team-btn{
    border-radius: 1rem;
    box-shadow: .75px 1.5px .75px 1.5px #afafaf;
}
.holdings-offers-team-info{
    margin-right: 3%;
}
.offers-blue-bid{
    background-color: rgb(85, 139, 255);
    border-radius: .5rem;
    padding: 1%;
    margin-right: 1%;
    color: white;
    font-weight: 400;
}
.offers-orange-ask{
    background-color: orange;
    border-radius: .5rem;
    padding: 1%;
    margin-right: 1%;
    color: white;
    font-weight: 400;
}
.blk-txt{
    color: black;
    font-weight: 600;
}

.Portfolio-Team-Name{
    text-align: center;
    width: 100%;
}
.Bar-Logo{
    display: none;
}
.Bar-Logo2{
    display: none;
}

/* Holdings Menu Bar */
.TBmenu-holdings{
    font-size: 1.25rem;
    color: black;
    margin-bottom: 1%;
    background-color: white;
    width: 100%;
    display: inline-block;
}
.fa-filter{
    width: 10vw;
    text-align: center;
    vertical-align: middle;
    color: black;
}
.black{
    color: black;
}
div.scrollmenu-holdings {
    overflow: auto;
    white-space: nowrap;
}
  
div.scrollmenu-holdings span {
    display: inline-block;
    text-align: center;
    padding: 1% 2% 1% 2%;
    text-decoration: none;
    width: 30vw;
}
  
div.scrollmenu-holdings span:hover {
    background-color: #777;
    color: white;
}
.selected{
    background-color: #777;
    color: white;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.h-menu-item{
    width: 20%;
}


@media only screen and (min-width: 1024px) {
    .Bar{
        width: 100%;
        min-height: 10vh;
        background-color: black;
        outline: #c5b358 1px solid;
        z-index: 100;
        position: relative;   
        padding-left: 10%;
        font-family: 'Roboto Slab', serif;
        position: sticky;
        padding-top: 0%;
        margin-top: 0%;
        vertical-align: top;
        position: -webkit-sticky;
        top: 0;
        bottom: 100;
        vertical-align: top;
    }
    .Bar-div{
        width: 13%;
        font-size: 1.2rem;
        color: white;
        text-align: center;
        padding-top: 0%;
        margin-top: 0%;
    }
    .Bar-div2{
        width: 13%;
        font-size: 1.2rem;
        color: white;
        text-align: center;
        padding-top: 0%;
        margin-top: 0%;
    }
    .sm-txt{
        font-size: 1.2rem;
    }
    .showTeams{
        display: inline-block;
    }
    .desktopOnly{
        width: 15%;
        font-size: 1.25rem;
        color: black;
        text-align: center;
        margin-top: 0%;
        display: inline;
        display: initial;
    }
    .desktopOnly2{
        width: 15%;
        font-size: 1.25rem;
        color: black;
        text-align: center;
        margin-top: 0%;
        display: inline;
        display: initial;
    }
    .desktopOnly-Account{
        display: inline-block;
    }
    .simbull-txt{
        color: #c5b358;
        font-weight: 200;
        font-size: 1.25rem;
        width: 50%;
        display: inline-block;
        text-align: left;
    }
    .Bar-desktop{
        width: 100%;
        height: 8.5vh;
        background-color: black !important;
        outline: #c5b358 1px solid;
        z-index: 100;
        position: relative;   
        padding-left: 5%;
        font-family: 'Roboto Slab', serif;
        position: -webkit-sticky;
        position: sticky;
        padding-top: 0%;
        margin-top: 0%;
        vertical-align: top;
    }
    .Bar-div-desktop{
        width: 15%;
        font-size: 2rem;
        color: black !important;
        text-align: center;
        padding-top: -10%;
        margin-top: 0%;
        display: inline-block
    }
    .center{
        text-align: center;
        width: 10%;
        padding-bottom: 1%;
        color: black;
    }
    .Bar-Logo{
        width: 50%;
        padding-bottom: 1%;
        display: inline-block;
    }
    .Bar-Logo2{
        width: 50%;
        padding-bottom: 1%;
        display: inline-block;
    }
    .Bar-img{
        display: none;
        padding-top: 0%;
        width: 0%;
        height: 0%;
        padding-left: 0%;
    }
    .Holdings{
        padding-left: 10%;
        padding-right: 10%;
    }
    .holdings-offers-team-div{
        width: 31%;
        margin-left: 1%;
        margin-right: 1%;
        display: inline-block;
    }
    .bar-selected{
        text-decoration: underline;
        background-color: white;
    }
    div.scrollmenu-holdings span {
        display: inline-block;
        text-align: center;
        padding: 1% 2% 1% 2%;
        text-decoration: none;
        width: 18vw;
    }
    div.scrollmenu-holdings span {
        width: 12vw;
    }
}
.TopBar{
    width: 100%;
    height: 6vh;
    position: fixed;
    top: 0;
    background-color: black;
    z-index: 1;
}
.tb-logo{
    height: 60%;
    margin-top: 20%;;
}
.tb-logo2{
    height: 90%;
}
.tb-user-icon{
    width: 10%;
    display: inline-block;
    height: 6vh;
    text-align: center;
}
.tb-logo-div{
    width: 90%;
    display: inline-block;
    height: 6vh;
    text-align: center;
    padding-right: 10%;
}

.close{
    width: 12%;
    float: left;
}
@media only screen and (min-width: 1024px) {
    .TopBar{
        display: none;
    }
    .TopBarDesktop{
        z-index: 10001;
        margin-top: 15%;
    }
    .close-div-desktop{
        width: 20%;
    }
    .close-desktop{
        width: 5%;
        float: none;
    }
    .tb-user-icon-desktop{
        color: black;
        
    }
    .blk-txt-desktop{
        color: black;
        font-weight: 400;
    }
}
.leftbar {
    height: 100vh;
    background-color: black;
    position: fixed;
    left: 0;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    display: flex;
    border-right: .5px solid gray;
}

.home:hover {
    background-color: #f2f2f2;
}

.row-board {
    /* style={{width: "100%", height: "15%", backgroundColor: "black", alignItems: "center", justifyContent: "flex-start", display: "flex", flexDirection: "row",}}
     */
    width: 100%;
    margin-top: 8px;
    background-color: black;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
}

.row-board:hover {
    border-right: 3px solid #c4b454;
    cursor: pointer;
}

.row-board:hover .icon {
    color: #c4b454;
}

.row-board:hover .row-txt {
    color: #c4b454;
}

.row-txt {
    /* style={{color: "white", fontSize: "1rem", fontFamily: "'Lato', sans-serif", fontWeight: 400, marginTop: "3%", alignSelf: "center"}} */
    color: white;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    margin-top: 3%;
    align-self: center;
    font-size: 12px;
}



.icon {
    /* style={{color: "white", fontSize: "1.2rem", marginRight: "7%", marginLeft: "25%"}} */
    color: white;
    margin-right: 7%;
    margin-left: 25%;
    height: 10px;
    width: 5px;
}

.home {
    /* style={{width: "100%", height: "15%", display: "flex", backgroundColor: "transparent", alignItems: "flex-start"}} */
    width: 100%;
    height: 15%;
    display: flex;
    background-color: transparent;
    align-items: flex-start;
}

.rightbar {
    height: calc(100vh - 90px);
    background-color: black;
    position: fixed;
    right: 0;
    width: 300px;
    bottom: 0;
    border-left: 1px solid gray;
    overflow: hidden;
}

.topbar {
    width: calc(100% - 200px);
    height: 90px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.topbar-title {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    background-color: black;
    
}

.topbar-middle {
    width: 100%;
    display: flex;
    background-color: black;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    color: white;
    padding-right: 84px;
}

.free-stuff {
    height: 25px;
    width: 250px;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 240px;
}

.free-stuff-item-stock {
    background-color: #c4b454;
    width: 45%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.free-stuff-item-transfer {
    width: 45%;
    height: 100%;
    border-radius: 20px;
    background-color: #212121;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    
}

.topbar-right {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    flex-direction: row;
    grid-column-gap: 1vh;
    -webkit-column-gap: 1vh;
            column-gap: 1vh;
    position: absolute;
    right: 0;
    
}

.account-section {
    height: 55%;
    width: 75%;
    background-color: #171717;
    border-radius: 15px;
    border: 1px solid rgb(196, 180, 84, .5);
    border-width: thin;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-inner {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account-main {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.account-img {
    height: 36px;
    width: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid white;
}

.account-info {
    height: 100%;
    width:70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 10px;
}

.account-outer {
    height: 100%;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rightbar-ad {
    
    width: 100%;
    border-bottom: 2px solid rgba(33, 33, 33, 0.7);
}

.rightbar-movers {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.movers-title {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px
}

.ads {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ads-header {
    width: 100%;
    
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.ads-arrows {
    display: flex;
    width: 25%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 7%;
}

.ads-close {
    margin-right: 7%;
}

.ads-content {
    
    width: 86%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.ads-content-left {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
}


.ads-content-right {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.ads-content-inner {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.ads-content-middle {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ads-content-button {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ads-btn {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #c4b454;
}

.mover-card {
    width: 87%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

.mover-img {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #171717;
    border: 0.25px solid #4B4B4B;
}

.mover-info {
    width: 60%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mover-name {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.mover-league {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    
}

.mover-price {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mover-top {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.mover-info-top {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mover-info-bottom {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.movers-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 10px;
    row-gap: 10px;
}

@media (max-width: 1100px) {
    .leftbar {
        display: none;
    }
    .rightbar {
        display: none;
    }
    .topbar {
        display: none;
    }
}

@media (max-width: 1524px) {
    .topbar-middle {
        width: 100%;
    }
}

/* style={{width: 200, height: "100vh", backgroundColor: "black", position: "fixed", left: 0}} */
.Portfolio{
  font-size: 100%;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid green;
}
.graph-btn1{
    border-radius: 1rem;
    width: 20%;
    margin-left: 0%;
    margin-right: 0%;
    background-color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    color: #c5b358;
    font-size: 1rem;
    padding: 0;
    border: none;
    background: none;
    margin-bottom: 0
}
.NotificationBox{
  box-shadow: 0px 4px 8px 2px rgba(0,0,0,0.2);
  border-radius: 0.3em;
  border: 1px solid rgb(180,180,180);
  padding: 10px;
  display: inline-block;
  margin: 10px;
  min-width: 90%;
  max-width: 95%!important;
  height: 100%!important;
  padding-bottom: 100%;
  margin-bottom: -100%;
}

.portfolioDiv{
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 7px;
    vertical-align: top;
}
.portfolioBox{
    background-color: white;
    padding: 2% 3%;
    display: inline-block;
    border-radius: .5rem;
    vertical-align: top;
    margin-right: 7px;
    border: .5px solid rgb(200,200,200);
}

.recurring{
    background-color: white;
    padding: 2% 3%;
    width: 99%;
    margin-left: .5%;
    margin-top: 10px;
    display: inline-block;
    border-radius: .5rem;
    vertical-align: top;
    margin-right: 7px;
    border: .5px solid #c5b358;
    text-align: center;
    font-weight: 300;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.2);

}

.modalOpen {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.team-img-port2{
  width: 100%;
  max-height: 6vh;
}

.holding-img{
  width: 15%;
  margin-top: -5px
}

.buyTeam-btn-div{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 9vh;
  text-align: center;
}
.buyTeam-btn{
  box-shadow: 0px 16px 32px 8px rgba(0,0,0,0.2);
  border-radius: 0.3em;
  font-size: 1.7em;
  padding: 1%;
  background-color: #c5b358;
  width: 98%;
  border: none;
  font-weight: 200;
}

.p-payouts-div-2{
    text-align: center;
    margin-top: 5px;
}
.p-payouts-btn-2{
    border-radius: .3rem;
    border: none;
    outline: none;
    font-size: 1.3rem;
    background-color: #c5b358;
    box-shadow: 0px 2.5px 10px 5px #d1d1d1;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fullOrderBook-desktop{
    display: none;
  }

#spinner {
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count:1;
  animation-duration: 1s;
  /*transform-style: preserve-3d;*/

}
@keyframes spinner {
from {
    transform: scale(0.1);
}
to {
    transform: scale(1);
}
}



.Value{
  padding-top: 7vh;
  padding-left: 5px;
  padding-right: 5px;
}

/*Canva*/
.simbull16{
  position: relative;
  width: 80%;
  margin-left: 10%;
  height: 0;
  padding-top: 133.333%;
  padding-bottom: 48px;
  box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform;
}
.sim16-body{
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

@media only screen and (min-width: 768px) {
  .team-img-port2{
    width: 75%;
    max-height: 10vh;
  }
}

@media only screen and (min-width: 1024px) {
  .Portfolio{

  }
  .Value{
    padding-top: 1vh;
    padding-left: 5px;
    padding-right: 5px;
  }
  .team-img-port2{
    width: 50%;
    max-height: 10vh;
  }
  .DesktopContainer{
      width: 100%;

  }
  .TeamPageDiv{
    padding-top: 1vh;
    padding-bottom: 16vh;
    font-size: 100%;
    background-color: 'black';
    padding-left: 200px;
      padding-right: 300px;
  }
  .holding-img{
    width: 7%
  }
  .buyTeam-btn-div{
    left: 0;
    padding-bottom: 3vh;
  }
  .buyTeam-btn{
    box-shadow: 0px 16px 32px 8px rgba(0,0,0,0.2);
    border-radius: 0.3em;
    font-size: 1.8em;
    background-color: #c5b358;
    width: 40%;
    border: none;
    font-weight: 200;

  }

  .fullOrderBook-mobile{
    display: none;
  }
  .fullOrderBook-desktop{
    display: inline;
  }
}

.signup-wrapper {
  overflow-x: hidden;
}

.signup-container {
  display: flex;
  width: 300vw;
  flex-direction: row;
  margin-top: 0px;
  padding-top: 0px;
  transition: transform .2s ease-out;
}

.signup-container > div {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: calc(5vh + 0px);
  padding-left: calc(15vw - 22px);
  padding-right: calc(15vw - 22px);

  background-color: #000;
  color: #FFF;
}

.signup-title {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 1em;
}

.signup-subtitle {
  font-weight: 800;
  font-size: 1.4em;
  color: #C4B454;
}

.signup-highlight {
  color: #C4B454;
}

.signup-title p {
  font-family: 'serif';
  font-size: 0.8em;
}

/* region Generic Inputs */

.signup-form-input {
  width: 100%;
  text-align: left;
  position: relative;
  margin-bottom: 1.5em;

  border: 2px solid #FFF;
  border-radius: 6px;

  transition: border-color 0.2s ease-out;
}

.signup-code-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 1.5em;
}

.signup-code-container:hover .signup-code-input,
.signup-code-container:focus-within .signup-code-input {
  border-color: #8f8341;
}

.signup-code-input {
  width: 50px;
  height: 50px;
  font-size: 2em;
  border: 2px solid #FFF;
  border-left: none;
  border-right: none;

  text-align: center;
  background-color: #000;
  color: #c5c5c5;
  caret-color: #C4B454;

  outline: none !important;
  transition: border-color .3s ease-out, color .2s ease-out;
}

.signup-code-input:focus {
  border-color: #C4B454 !important;
  color: #FFF;
}

.signup-code-input::-webkit-input-placeholder {
  text-align: center;
  color: #bbbbbb;
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}

.signup-code-input:-ms-input-placeholder {
  text-align: center;
  color: #bbbbbb;
  -ms-transition: color .2s ease;
  transition: color .2s ease;
}

.signup-code-input::placeholder {
  text-align: center;
  color: #bbbbbb;
  transition: color .2s ease;
}

.signup-code-input:hover::-webkit-input-placeholder, .signup-code-input:focus::-webkit-input-placeholder {
  color: #EDEDED;
}

.signup-code-input:hover:-ms-input-placeholder, .signup-code-input:focus:-ms-input-placeholder {
  color: #EDEDED;
}

.signup-code-input:hover::placeholder,
.signup-code-input:focus::placeholder {
  color: #EDEDED;
}

.signup-code-input:first-child {
  border-left: 2px solid;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.signup-code-input:last-child {
  border-right: 2px solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* Validation */
.signup-form-input .invalid-feedback {
  position: absolute;
  bottom: -1.5em;
}

/* Icon */
.signup-form-input .input-group-text {
  min-width: 48px;

  border: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  color: #FFF;
  background-color: #000;
  font-size: 1.2em;
  text-align: center;

  justify-content: center;
}

/* Input */
.signup-form-input .form-control {
  padding: .6rem .75rem .6rem 0;
  height: calc(1.5em + 1.2rem + 2px);

  border: none;
  outline: none !important;
  box-shadow: none !important;

  color: #c5c5c5;
  background-color: #000;
  caret-color: #C4B454;

  transition: color 0.2s ease-out;
}

.signup-form-input input:-webkit-autofill {
  background-color: #000 !important;
  background-clip: content-box !important;
}

/* Left align date input placeholder */
.signup-form-input input::-webkit-date-and-time-value {
  text-align: left;
}

.signup-form-input:hover,
.signup-form-input:hover .form-control.secondary{
  border-color: #8f8341 !important;
}

.signup-form-input:focus-within,
.signup-form-input:focus-within .form-control.secondary{
  border-color: #C4B454 !important;
}

.signup-form-input .form-control:hover,
.signup-form-input .form-control:focus {
  color: #FFF;
}

.signup-form-input .form-control::-webkit-input-placeholder {
  color: #bbbbbb;
  text-align: left;
}

.signup-form-input .form-control:-ms-input-placeholder {
  color: #bbbbbb;
  text-align: left;
}

.signup-form-input .form-control::placeholder {
  color: #bbbbbb;
  text-align: left;
}

.signup-form-input .form-control.secondary {
  padding-left: .6rem;
  border-left: 2px solid white;
  transition: border-color .2s ease-out;
  z-index: 4;
}

.signup-form-input .btn-form-signup {
  width: auto;
  border-radius: 0 4px 4px 0;
  margin: 0;
}

/* endregion */
/* region Checkbox */

.signup-form-checkbox {
  width: 100%;

  display: block;
  position: relative;

  color: #949494;

  padding-left: 30px;
  cursor: pointer;
  font-size: 0.8em;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signup-form-checkbox label {
  cursor: pointer;
}

.signup-form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.signup-form-checkbox span {
  position: absolute;
  top: 6px;
  bottom: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #000;
  border: 2px solid #C4B454;
  border-radius: 8px;

  transition: background-color 0.2s ease-out;
}

.signup-form-checkbox:hover input ~ span {
  background-color: #5e531f;
}

.signup-form-checkbox input:checked ~ span {
  background-color: #C4B454;
}

.signup-form-checkbox span:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c"; /* Checkmark */
  position: absolute;
  display: block;
  top: -2px;
  font-size: 1.25em;
  color: white;
  opacity: 0;

  transition: opacity .2s ease-out;
}

.signup-form-checkbox input:checked ~ span:after {
  opacity: 1;
}

/* endregion */
/* region Signup Button */


/* Cant use btn-signup or it'll clash */
.btn-form-signup {
  padding: 0.25em .75em;
  width: 100%;
  border: 2px solid transparent;
  background-color: #C4B454;
  border-radius: 3em;
  color: black;
  font-weight: bold;
  font-family: 'Roboto Slab', serif;
  margin-bottom: 3em;

  box-shadow: none;

  transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
}

.btn-form-signup:focus,
.btn-form-signup:hover,
.btn-form-signup:disabled,
.btn-form-signup.disabled,
.btn-form-signup:active {
  color: black;
  background-color: #C4B454 !important;
  border-color: #C4B454 !important;
}

.btn-form-signup:not(:disabled):not(.disabled):hover,
.btn-form-signup:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 180, 84, 0.4) !important;
  color: #FFF !important;
}

/* endregion */
/* region Legal */

.signup-form-info {
  display: flex;
  width: 100%;
  font-size: 0.8em;
  flex-direction: row;
  justify-content: space-between;
  margin-top: .25em;
  margin-bottom: 2em;
  color: #949494;
}

.signup-form-info a,
.signup-form-info button {
  color: #8d8d8d;
  text-decoration: underline;
  transition: all .15s ease-out;
}

.signup-form-info a:hover,
.signup-form-info button:hover {
  color: #aeaeae;
}

.signup-form-info button {
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border: none !important;
}

.signup-form-info a:first-child {
  margin-right: .3em;
  text-align: right;
  flex-basis: 0;
  flex-grow: 1;
}

.signup-form-info a:last-child {
  margin-left: .3em;
  text-align: left;
  flex-basis: 0;
  flex-grow: 1;
}

.signup-form-feedback {
  color: #dc3545;
  font-size: 90%;
  text-align: center;
  display: block;
  margin-bottom: .5rem;
}

.signup-referral-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  text-align: center;
}

.signup-referral-info p {
  color: #d3d3d3;
}

.signup-referral-panel {
  width: 100%;
}

.signup-referral-panel .signup-form-input {
  margin-bottom: 0;
  border-radius: 6px 6px 0 0;
  width: 100%;
}

.signup-referral-panel .form-control {
  text-align: center;
}

.signup-referral-panel .btn-form-signup {
  width: 100%;
}

.signup-referral-panel .col-12:not(:first-child):last-child .btn-form-signup {
  border-radius: 0 0 6px 6px;
}

.signup-referral-panel .col-6:not(:last-child) .btn-form-signup {
  border-radius: 0 0 0 6px;
  border-right: 1px solid #333;
}

.signup-referral-panel .col-6:last-child .btn-form-signup {
  border-radius: 0 0 6px 0;
  border-left: 1px solid #333;
}

/* region Media Queries */

@media (min-width: 600px) {
  .signup-referral-info p {
      max-width: 80%;
  }
}

@media (min-width: 600px) and (max-width: 989.9px) {
  .signup-form-input, .signup-form-checkbox, .signup-form-info {
      width: 60vw;
  }

  .btn-form-signup, .signup-referral-panel {
      width: 40vw;
  }
}

@media (min-width: 990px) {
  .signup-form-input, .signup-form-checkbox, .signup-form-info {
      width: 40vw;
  }

  .btn-form-signup, .signup-referral-panel {
      width: 20vw;
  }
}

/* endregion */
/* region Rewards */

.btn-sb-stack {
    border-radius: 2rem;
    width: 75%;
    padding: 16px;
    color: black;
    background-color: #c4b454;
    border: 2px solid transparent;
    outline: none;
    font-weight: 500;
    font-size: 1.2rem;
    transition: all 0.25s ease-in-out;
}

.btn-sb-stack:not(.disabled):not([disabled]):hover,
.btn-sb-stack:not(.disabled):not([disabled]):focus {
    background-color: #000;
    color: #fff;
    border: 2px solid #c4b454 !important;
}

.btn-sb-stack.disabled,
.btn-sb-stack[disabled] {
  background-color: #8f8439;
}

.btn-sb-stack.stack-mid {
    border-radius: 0;
}

.btn-sb-stack.stack-top {
    border-radius: 0;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom: 2px solid #000;
}

.btn-sb-stack.stack-bottom {
    border-radius: 0;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top: 2px solid #000;
}

.btn-sb-stack.stack-small {
    font-size: 1rem;
    padding: 4px;
}

.btn-sb-pill {
  padding: 2px 10px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 0.75em;
  font-size: 1em;
  background-color: #9a934f;
  /*background-color: #ececec;*/
  font-weight: bolder;
  border: 2px solid #9a934f;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}

.btn-sb-pill:active,
.btn-sb-pill.active {
  background-color: #f5e46e;
  border-color: #f5e46e;
}

.btn-sb-pill:focus,
.btn-sb-pill:hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: #FFF;
}

.btn-anchor-link {
  color: inherit;
  background-color: transparent;
}

.btn-anchor-link:hover,
.btn-anchor-link:focus {
  text-decoration: underline;
}

.select-sb-input {
  width: 100%;
  max-width: 300px;
  background-color: #000;
  padding: 0.5em 0.7em;
  color: #FFF;
  margin-top: 1em;
  position: relative;
}

@media (max-width: 768px) {
  .select-sb-input {
    max-width: 100%;
  }
}

.redeem-swap-receipt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;

  background-color: #323232;
  border-radius: 6px;
  margin-bottom: 20px;

  font-size: 1.2rem;
}

.redeem-swap-receipt img {
  margin-right: 16px;
  height: 35px;
  width: 35px;
}

/* endregion */

.PTotal{
    padding: 3%;
    padding-top: 7vh;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 0%;
}
.p-total-txt{
    display: inline-block;
    width: 60%;
    font-size: 2rem;
    font-weight: 900;
}
.p-total-amt{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 2rem;
    font-weight: 900;
} 
.p-total-txt2{
    display: inline-block;
    width: 60%;
    font-size: 1.25rem;
    font-weight: 400;
}
.p-total-amt2{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 1.25rem;
    font-weight: 400;
} 
.p-total-account{
    text-align: center;
    width: 100%;
    font-weight: 900;
    padding-bottom: 0%;
    margin-bottom: 1%;
}
.p-total-hr{
    width:20%;
    padding-top: 0%;
    margin-top: 0%;
    padding-bottom: 0%;
    margin-bottom: 0%;
}
.p-total-value{
    font-size: 3rem;
    font-weight: 900;
    padding-top: 0%;
    margin-top: 0%;
    text-align: center;
    color:  #c5b358;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: 2.5px 2.5px #d1d1d1;
}
.p-total-cash{
    text-align: center;
    font-weight: 200;
}
.p-total-cash-amt{
    color:  #c5b358;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}
.p-graph{
    width: 100%;
    
}
.p-graph-return{
    color: #c5b358;
    padding-left: 3%;
}
.p-graph-top{
    width: 94%;
}
.PReturn{
    padding: 3%;
    font-family: 'Roboto Slab', serif;
}
.p-return-txt{
    display: inline-block;
    width: 60%;
    font-size: 1.25rem;
    font-weight: 400;
}
.p-return-amt{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 1.25rem;
    font-weight: 400;
} 
.p-return-txt2{
    display: inline-block;
    width: 60%;
    font-size: 1rem;
    font-weight: 400;
}
.p-return-amt2{
    display: inline-block;
    width: 40%;
    text-align: right;
    font-size: 1rem;
    font-weight: 400;
} 
.PTransfer{
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 0%;
}
.p-transfer-txt{
    width: 100%;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;
}
.p-transfer-amt{
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
} 
.transfer-btn{
    width: 60%;
    margin-left: 20%;
    border-radius: 1rem;
    background-color:rgb(187, 187, 187);
    font-size: 1.1rem;
    padding: 1%;
    box-shadow: 2.5px 2.5px #d1d1d1;
}
.p-transfer-hr{
    padding-bottom: 0%;
    margin-bottom: 0%;
}


/*PHistory*/
.PHistory{
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 20%;
    padding-top: 0%;
}
.p-history{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: black;
}
.p-selected{
    font-size: 1.5rem;
    font-weight: 900;
}
.gray-back{
    background-color: rgb(237, 237, 237);
    padding-left: -3%;
    padding-right: -3%;
}
.p-history-title{
    display: inline-block;
    width: 80%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.p-history-icon-div{
    display: inline-block;
    width: 10%;
}
.p-history-hr{
    margin: 0%;
    padding: 0%;
}


/*PPayout*/
.PPayout{
    margin-top: 5%;
    font-family: 'Roboto Slab', serif;
}
.p-payouts-div{
    text-align: center;
    margin-top: 5%;
}
.p-payouts-btn{
    border-radius: 1rem;
    font-size: 1.3rem;
    width: 100%;
    background-color: #c5b358;
    box-shadow: 2.5px 2.5px  2.5px 2.5px #d1d1d1;
}


/*Collect Payouts*/
.CollectPayouts{
    background-color: rgb(237, 237, 237);
    z-index: 10000;
    width: 95vw;
    height: 80vh;
    margin-left: 1.5vh;
    margin-top: 7.5vh;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 1rem;
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    overflow-y: scroll;
    padding-bottom: 10%;
}
.CollectPayouts2{
    background-color: rgb(184, 184, 184);
    z-index: 10000;
    width: 92.5vw;
    height: 80vh;
    margin-left: 2.5vh;
    margin-top: 5vh;
    position: fixed;
    top: 0;
    left: 0;
    outline: 1px black solid;
    padding: 3%;
    font-family: 'Roboto Slab', serif;
    overflow-y: scroll;
}
.cp-close{
    text-align: right;
}
.cp-title{
    font-size: 1.75rem;
    font-weight: 900;
    text-align: center;
    text-decoration: underline;
}
.cp-title-num{
    font-size: 1.5rem;
    text-align: center;
    font-weight: 200;
}
.cp-card-holder{
    padding: 3%;
    padding-top: 10%;
    min-height: 30vh;
}
.cp-card-left{
    display: inline-block;
    width: 85%;
}
.cp-card-date{
    font-size: 0.75rem;
}
.cp-card-right{
    display: inline-block;
    width: 15%;
}
.cp-card-total{
    padding: 1.5%;
    font-weight: 900;
}
.cp-card-total-left{
    display: inline-block;
    width: 50%;
    text-align: left;
}
.cp-card-total-right{
    display: inline-block;
    width: 50%;
    text-align: right;
}
.cp-card-total-hr{
    background-color: black;
    border: 0 none;
    color: #eee;
    height: 1px;
}
.align-bottom{
    
}
.cp-question-div{
    padding: 3%;
}
.cp-question-div-open{
    padding: 3%;
    text-align: center
}
.cp-question{
    font-weight: 600;
}
.cp-question-open{
    font-weight: 600;
}
.cp-answers{
    padding: 3%;
}
.cp-answers-input{ /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
    transform: scale(2);
    padding: 10px;
}
.cp-answers-input-open{
    width: 90%;
    border-radius: 1rem;
    margin: 1%;
    text-align: center;
}
.cp-answers-txt{
    padding-left: 5%;
}
.cp-card-btn-div{
    text-align: center;
}
.cp-card-btn{
    border-radius: 1rem;
    font-size: 1rem;
    width: 75%;
    background-color: #c5b358;
    box-shadow: 2.5px 2.5px #d1d1d1;
    padding: 2% 5% 2% 5%;
}
.cp-card-btn-2{
    border-radius: 1rem;
    font-size: 1.5rem;
    width: 95%;
    background-color: #c5b358;
    box-shadow: 2.5px 2.5px #d1d1d1;
    padding: 5% 12% 5% 12%;
}

/*Colors*/
.green{
    color: green;
}
.red{
    color: red;
}

/* Bonus */
.Bonus{

}
.bonus-btn{
    width: 60%;
    margin-left: 20%;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: #c5b358;
}
.collect-bonus-div{
    text-align: center;
    width: 100%;
}
.collect-bonus-btn{
    width: 80%;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: #c5b358;
}

.p-announcement{
    text-align: center;
    padding: 2%;
}
.p-a-under{
    text-decoration: underline;
}

/* Trades */
.PTrade-Card{
    width: 100%;
}
.PTrade-Card-Left{
    width: 100%;
    display: inline-block; 
    background-color: white;
    width: 8%;
    height: 100%; 
    padding-bottom: 100%;
    margin-bottom: -100%; 
    vertical-align: top;
    font-size: 1.4rem;
    font-weight: 200;
    text-align: center;
    padding-top: 12px;
}
.PTrade-Card-Right{
    display: inline-block;
    background-color: white;
    width: 92%;
}
.PTrades{
    background-color: rgb(245,245,245);
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
}
.pt-head{
    text-align: center;
    font-weight: 200;
    background-color: white;
    color: gray;
    font-size: 1.2rem;
}
.pt-view{
    text-align: center;
    font-weight: 200;
    background-color: white;
    color: gray;
    font-size: 1.2rem;
    margin-top: 3px;
}
/* End Trades */

@media only screen and (min-width: 1024px) {
    .Portfolio{
        background-color: black;
        padding-left: 200px;
        padding-right: 300px;
        min-height: 100vh;
    }
    .CollectPayouts{
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;
    }
    .CollectPayouts2{
        margin-left: 25%;
        margin-right: 25%;
        width: 50%;
    }
    .cp-close{
        margin-right: 27%;
        text-align: center;
    }
    .fa-times-circle{
        margin-right: 27%;
        margin-top: 5%;
    }
    .cp-card-btn-2{
        border-radius: 1rem;
        font-size: 1rem;
        width: 75%;
        background-color: #c5b358;
        box-shadow: 2.5px 2.5px #d1d1d1;
        padding: 5% 12% 5% 12%;
    }
    .loading-div{
        background-color: white;
        height: 50vh;
        width: 50vw;
        position: absolute;
        top: 50;
        left: 50;
        padding-left: 25%;
        padding-right: 25%;
        margin-left: 25%;
        margin-top: 15%;
        opacity: .5;
    }

    /* Trades */
    .PTrade-Card{
        display: inline-block;
        width: 49%;
        margin-left: 0.5%;
        margin-right: 0.5%;
    }
    .PTrade-Card-Left{
        width: 100%;
        display: inline-block; 
        background-color: white;
        width: 10%;
        height: 100%; 
        padding-bottom: 100%;
        margin-bottom: -100%; 
        vertical-align: top;
        font-size: 1.3rem;
        font-weight: 200;
        text-align: center;
        padding-top: 6px;
        text-align: right;
    }
    .PTrade-Card-Right{
        display: inline-block;
        background-color: white;
        width: 90%;
    }
    .PTrades{
        background-color: white;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
    .pt-head{
        text-align: center;
        font-weight: 200;
        background-color: white;
        color: gray;
        font-size: 1.1rem;
    }
    .pt-view{
        text-align: center;
        font-weight: 200;
        background-color: white;
        color: gray;
        font-size: 1.1rem;
        margin-top: 3px;
    }
    /* End Trades */

}
.Ticker{
    padding-top: 6vh;
}
.trade-teamcard{
    display: inline-block;
    background-color: white;
    margin: .5%;
    margin-left: 2%;
    margin-right: 0.5%;
    border-radius: .4rem;
    min-width: 20%;
    max-width: 31%;
    min-height: 10vh;
    display: inline-block;
    text-align: left;
    font-size: 0.9rem;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    padding-top: 2%;
    text-align: 'center';
    padding-left: 4px;
    overflow-y: hidden;
}
.trade-card{
    font-size: 0.7rem;
    color: darkred;
    font-weight: 300;
}
.trade-header-left{
    display: inline-block;
    width: 70%;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 1.1em
}
.trade-header-right{
    text-align: right;
    display: inline-block;
    font-weight: 300;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    font-size: 0.9em;
    color: gray
}
.green{
    color: green;
}
.red{
    color: #ff7d7d;
}
.trade-card-type{
    display: inline-block;
    margin-right: 0%;
    width: 44%;
    padding-left: 20px;
}
.trade-card-team{
    display: inline-block;
}
.trade-card-price{
    display: inline-block;
    width: 56%;
    text-align: right;
    padding-right: 20px
}

.Search{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    background-color: rgb(244,244,244);
    z-index: 1;
    padding-bottom: 9vh;
}

.LeagueFilter{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    background-color: rgb(244,244,244);
    z-index: 1;
    padding-bottom: 9vh;
}
.leagueFilter-btn{
    width: 28%;
    margin-left: 2.6%;
    border: none;
    outline: none;
    color: gray;
    margin-bottom: 5px;
    background-color: rgb(244,244,244);
    font-size: 1.1em
}
.leagueFilter-btn-selected{
    width: 28%;
    margin-left: 2.6%;
    border: none;
    outline: none;
    font-weight: 600;
    color: #c5b358;
    margin-bottom: 5px;
    background-color: rgb(244,244,244);
    font-size: 1.1em
}

.allTrades-img{
    width: 100%;
    margin-top: 7px
}

.full-name{
    width: 38.5%;
    padding-left: 1%;
    display: inline-block;
    font-size: 1.08em;
    font-weight: 400;
}
.full-price{
    text-align: center;
    width: 36.5%;
    display: inline-block;
    font-weight: 300;
    font-size: 1em;
}
.full-percent{
    text-align: right;
    width: 19%;
    padding-right: 2px;
    display: inline-block;
    font-size: 1.06em;
    font-weight: 300;
}
.full-span{
    font-size: .75em;
    color: gray;
    font-weight: 200;
    display: inline-block;
}
.full-img{
    width: 100%;
}

@media only screen and (min-width: 800px) {
.DesktopContainer{
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;
  }
}

@media only screen and (min-width: 1024px) {
    .Ticker{
        padding-top: 0vh;
    }
  .DesktopContainer{
      width: 100%;
      padding-left: 20%;
      padding-right: 20%;
  }
  .Search{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1%;
        background-color: rgb(244,244,244);
        z-index: 1;
        padding-bottom: 9vh;
    }
    .LeagueFilter{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1%;
        background-color: rgb(244,244,244);
        z-index: 1;
        padding-bottom: 3vh;
        padding-left: 25%;
        padding-right: 25%;
    }
    .trade-teamcard{
        max-width: 26%
    }
    .allTrades-img{
        width: 50%;
        margin-top: 7px;
        margin-left: 25%;
    }
    .full-name{
        width: 50%;
        padding-left: 1%;
        display: inline-block;
        font-size: 1.1em;
        font-weight: 400;
    }
    .full-price{
        text-align: center;
        width: 25%;
        display: inline-block;
        font-weight: 300;
        font-size: 1em;
    }
    .full-percent{
        text-align: right;
        width: 15%;
        padding-right: 5px;
        display: inline-block;
        font-size: 1.07em;
        font-weight: 300;
    }
    .full-span{
        font-size: .75em;
        color: gray;
        font-weight: 200;
        display: inline-block;
    }
    .full-img{
        width: 70%;
    }
}
.Portfolio{
  font-size: 100%;
}
.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid green;
}
.graph-btn1{
    border-radius: 1rem;
    width: 20%;
    margin-left: 0%;
    margin-right: 0%;
    background-color: white;
    padding-top: 2%;
    padding-bottom: 2%;
    color: #c5b358;
    font-size: 1rem;
    padding: 0;
    border: none;
    background: none;
    margin-bottom: 0
}
.NotificationBox{
  box-shadow: 0px 4px 8px 2px rgba(0,0,0,0.2);
  border-radius: 0.3em;
  border: 1px solid rgb(180,180,180);
  padding: 10px;
  display: inline-block;
  margin: 10px;
  min-width: 90%;
  max-width: 95%!important;
  height: 100%!important;
  padding-bottom: 100%;
  margin-bottom: -100%;
} 

.portfolioDiv{
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 7px;
    vertical-align: top;
}
.portfolioBox{
    background-color: white;
    padding: 2% 3%;
    display: inline-block;
    border-radius: .5rem;
    vertical-align: top;
    margin-right: 7px;
    border: .5px solid rgb(200,200,200);
}

.recurring{
    background-color: white;
    padding: 2% 3%;
    width: 99%;
    margin-left: .5%;
    margin-top: 10px;
    display: inline-block;
    border-radius: .5rem;
    vertical-align: top;
    margin-right: 7px;
    border: .5px solid #c5b358;
    text-align: center;
    font-weight: 300;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.2);
    
}

.modalOpen {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.team-img-port2{
  width: 100%;
  max-height: 6vh;
}

.holding-img{
  width: 15%;
  margin-top: -5px
}

.buyTeam-btn-div{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding-bottom: 9vh;
  text-align: center;
}
.buyTeam-btn{
  box-shadow: 0px 16px 32px 8px rgba(0,0,0,0.2);
  border-radius: 0.3em; 
  font-size: 1.7em;
  padding: 1%;
  background-color: #c5b358;
  width: 98%;
  border: none;
  font-weight: 200;
}

.p-payouts-div-2{
    text-align: center;
    margin-top: 5px;
}
.p-payouts-btn-2{
    border-radius: .3rem;
    border: none;
    outline: none;
    font-size: 1.3rem;
    background-color: #c5b358;
    box-shadow: 0px 2.5px 10px 5px #d1d1d1;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fullOrderBook-desktop{
    display: none;
  }

#spinner {
  animation-name: spinner;
  animation-timing-function: linear;
  animation-iteration-count:1;
  animation-duration: 1s;
  /*transform-style: preserve-3d;*/
  
}
@keyframes spinner {
from {
    transform: scale(0.1);
}
to {
    transform: scale(1);
}
}

.TeamPageDiv{
  padding-top: 7vh; 
  padding-bottom: 19vh;
  font-size: 100%;
}

.Value{
  padding-top: 7vh;
  padding-left: 5px;
  padding-right: 5px;
}

/*Canva*/
.simbull16{
  position: relative;
  width: 80%;
  margin-left: 10%;
  height: 0;
  padding-top: 133.333%;
  padding-bottom: 48px; 
  box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em; 
  overflow: hidden;
  border-radius: 8px; 
  will-change: transform;
}
.sim16-body{
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

@media only screen and (min-width: 768px) {
  .team-img-port2{
    width: 75%;
    max-height: 10vh;
  }
}

@media only screen and (min-width: 1024px) {
  .Portfolio{

  }
  .Value{
    padding-top: 1vh;
    padding-left: 5px;
    padding-right: 5px;
  }
  .team-img-port2{
    width: 50%;
    max-height: 10vh;
  }
  .DesktopContainer{
      width: 100%;
      padding-left: 25%;
      padding-right: 25%;
  }
  .TeamPageDiv{
    padding-top: 1vh; 
    padding-bottom: 16vh;
    font-size: 100%;
  }
  .holding-img{
    width: 7%
  }
  .buyTeam-btn-div{
    left: 0;
    padding-bottom: 3vh;
  }
  .buyTeam-btn{
    box-shadow: 0px 16px 32px 8px rgba(0,0,0,0.2);
    border-radius: 0.3em; 
    font-size: 1.8em;
    background-color: #c5b358;
    width: 40%;
    border: none;
    font-weight: 200;
    
  }

  .fullOrderBook-mobile{
    display: none;
  }
  .fullOrderBook-desktop{
    display: inline;
  }
}
.Friends {
    font-family: 'Roboto Slab', serif;
    background-color: rgb(245, 245, 245);
    padding-bottom: 5%;
    min-height: 100vh;
    padding-bottom: 10vh;
}
.F-Title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5%;
  color: rgb(245, 245, 245);
  display: none;
}
.F-Card {
  width: 98%;
  background-color: white;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: black;
}
.cards-teammates{
  font-size: 1.7rem;
  margin-left: 5%;
}
.FC-user {
  display: inline-block;
  width: 98%;
  padding-left: 2%;
  font-size: 1.5rem;
  color: black;
}
.FC-user-img{
  width: 10%;
  height: 15%;
}
.FC-user-span{
  padding-left: 3%;
}
.FC-amount {
  display: inline-block;
  width: 38%;
  text-align: right;
  padding-right: 2%;
  font-weight: 200;
}
/* Cards Extended*/
.Friends-Extended {
  background: #fff;
  overflow: hidden;
  color: #000;
  line-height: 50px;

  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0);
}
.Friends-Extended:target {
  transform: scaleY(1);
}
.FC-E-user {
  font-size: 1.75rem;
  text-align: center;
  font-weight: 600;
  padding-bottom: 4%;
  color: black;
  display: inline-block;
  width: 95%;
}
.FC-E-edit{
  display: inline-block;
  width: 5%;
  font-size: 1.75rem;
  color: gray;
}

.FC-E-hr {
  width: 85%;
  opacity: 0.5;
  padding: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}
.FC-E-items {
  padding-top: 4%;
  padding-bottom: 4%;
}
.FC-E-item-name {
  width: 57%;
  display: inline-block;
  padding-left: 3%;
  text-align: left;
  font-size: 1rem;
}
.FC-E-item-amount {
  width: 37%;
  display: inline-block;
  padding-right: 3%;
  text-align: right;
  font-size: 1.1rem;
  font-weight: 800;
}
.FC-E-item-returnBTN{
  border-radius: 1rem;
  margin-left: 2%;
  padding-top: 0%;
  padding-bottom: 0%;
  padding-left: 7%;
  padding-right: 7%;
  color: #c5b358;
}
.fa-caret-down{
  font-size: 1.4rem;
}
.FC-E-return-div{
  width: 50%;
  margin-left: 0%;
}
.FC-E-item-return-item {
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 1.2rem;
  opacity: 1;
  border-radius: 1rem;
  border: 1px solid #c5b358;
  font-weight: 800;
}
.block {
  display: inline-block;
}
.FC-E-Overlay{
  background-color: red;
}
.FC-E-item-full {
  text-align: center;
  font-weight: 200;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.2rem;
}
/*Shares */
.FC-S-TC-Div{
  vertical-align: middle;
  background-color: white;
}
.FC-S-TC-Div-Left{
  display: inline-block;
  width: 10%;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  height: 100%;
  vertical-align: top;
  margin-top: 5%;
  
}
.FC-S-TC-Div-Right{
  display: inline-block;
  width: 90%;
}
.FC-S-TC-hr{
  padding: 0%;
  margin: 0%;
}
.buySell{
  transform: rotate(90deg);
}

/* Add Friend */
.FC-Add {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-top: 5%;
  margin-bottom: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}
.AddFriend {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-top: -2%;
  margin-bottom: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}
.AF-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 10%;
  font-weight: 400;
}
.AF-Label {
  display: inline-block;
  padding-bottom: 3%;
  font-weight: 800;
  font-size: 1.5rem;
}
.AF-Input {
  text-align: center;
  padding:3%;
  padding-left: 3%;
  width: 70%;
  margin-left: 3%;
  border-radius: 1rem;
  border: 2px solid #c5b358;
  font-size: 1.5rem;

}
.AF-Input-Btn{
  margin-top: 2%;
  border: 2px solid #c5b358;
  color: #c5b358;
  background-color: white;
  font-size: 1.5rem;
  border-radius: 12rem;
  padding: 1% 10% 1% 10%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
}
.AF-Check-title{
  text-align: left;
  font-size: 1.1rem;
  padding-left: 5%;
}
.AF-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check {
  font-size: 2rem;
  border: 2px solid #c5b358;
  /* Double-sized Checkboxes */ /* IE */ /* FF */ /* Safari and Chrome */ /* Opera */
  transform: scale(1.75);
  padding: 10px;
}

.AF-Check-Type {
  padding-left: 3%;
  padding-bottom: 2%;
  margin-left: 3.5%;
  font-size: 1.1rem;
  font-weight: 600;
}
.AF-Check-Desc {
  font-size: 0.8rem;
  font-weight: 200;
  padding-bottom: 2%;
}
.AF-hr {
  width: 70%;
  opacity: 0.5;
  padding: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}
.AF-btn {
  font-size: 1.1rem;
  padding: 2% 7% 2% 7%;
  width: 80%;
  border-radius: 0.75rem;
  border: 2px solid #c5b358;
  font-weight: 800;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* Requests */
.FR-Extended {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-bottom: 5%;
  margin-top: -6%;
  padding-top: 0%;
  padding-bottom: 3%;
  text-align: center;
}
.FR-E-Card {
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
}
.FR {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-top: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}
.FR-hr-top {
  margin-left: 3%;
  margin-right: 3%;
}
.FR-E-Card-name {
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  color: black;
}
.FR-E-C-E-name {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
}
.FR-E-C-E-Share {
  padding: 5%;
  background-color: rgb(240,240,240);
  border-radius: 0.8rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  margin-bottom: 5%;
}
.FR-E-C-E-S-title {
  font-size: 0.9rem;
  text-align: left;
}
.FR-E-C-E-S-items {
  padding-left: 3%;
}
.FR-E-C-E-S-item {
  font-size: 0.8rem;
  font-style: italic;
}
.itemShared {
  color: green;
}
.itemNotShares {
  color: red;
  text-decoration: line-through;
}
.bthSpace{
  font-size: 1.1rem;
  padding: 2% 7% 2% 7%;
  width: 80%;
  border-radius: 0.75rem;
  border: 2px solid #c5b358;
  font-weight: 800;
  margin-top: 2%;
  background-color: #c5b358;
  margin-bottom: 3%;
  border: 2px black solid;
  font-weight: 700;
  font-size: 1.2rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  
}
.bthDecline{
  padding: 2% 7% 2% 7%;
  border-radius: 0.75rem;
  font-weight: 400;
  border: 1px rgb(245, 245, 245) solid;
  font-size: .9rem;
  width: 70%;
}

/* Other */
.green{
  color: green;
}
.red{
  color: red;
}

/* WhatAre */
.WhatAre{
  background-color: white;
  margin: 3%;
}
.WA-Title{
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  padding: 5%;
  padding-bottom: 1%;
}
.WA-Body1{
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.2rem;
  font-style: italic;
  padding-bottom: 5%;
  text-align: center;
}
.WA-Body2{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.1rem;
  text-align: left;
}
.WA-Body2-middle{
  text-align: center;
  font-size: .8rem;
}
.WA-hr{
  margin: 10% 10% 3% 10%;
}
.WA-Body3{
  padding-top: 0%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 5%;
}
.WA-Body3-li{
  margin-left: 5%;
}
.WA-Body2-img{
  width: 15%;
  display: inline-block;
  vertical-align: top;
  padding-top: 2%;
}
.WA-Body2-words{
  width: 85%;
  padding-left: 3%;
  display: inline-block;
}

/* Create Username */
.CU-Div{
  background-color: white;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  padding: 1%;
  margin-top: -3%;
  padding-bottom: 5%;
}
.CU-Input{
  border-radius: 1rem;
  text-align: center;
  width: 75%;
  margin-bottom: 2%;
  font-size: 2rem;
  border: 2px #c5b358 solid;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.CU-Btn{
  border-radius: 1rem;
  padding: 2% 10% 2% 10%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  font-weight: 600;
}

.bottom5{
  margin-bottom: 5%;
}
.whiteBack{
  background-color: white;
  padding: 1%;
  margin: 1%;
  font-size: 1.2rem;
  font-weight: 200;
}

/* Edit */
.EditFriendHodler{
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(230,230,230);
  outline: 2px black solid;
  overflow-y: auto;
}
.EditFriend{
  top: 0;
  left: 0;
  margin-left: 5vw;
  margin-top: 5vh;
  width: 90vw;
  height: 80vh;
  background-color: white;
  outline: 1px black solid;
  box-shadow: 0px 28px 46px 0px rgba(0,0,0,0.2);
  padding: 5%;
  padding-top: 2%;
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
.Edit-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check-Type-currently{
  font-weight: 200;
  margin-left: 2%;
  font-size: .9rem;
}
.Edit-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 2%;
  font-weight: 400;
}
.Edit-Label {
  display: inline-block;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2rem;
}
.Edit-deleteBtn{
  border-radius: .8rem;
  background-color: white;
  margin-top: 2%;
  font-weight: 200;
  padding: 0;
  border: none;
  background: none;
  font-size: 0.8rem;
}
.edit-close-btn{
  text-align: right;
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}

.viewMoreBtn{
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}
.username_friends{
  border: none;
  background: none;
  background-color: rgb(245,245,245);
  width: 90%;
  margin-left: 0%;
  font-size: 1.2rem;
  margin-top: 2%;
  box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
}

@media screen and (min-width: 600px) {
  .Friends {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media screen and (min-width: 768px) {
  .Friends {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
  .FC-E-item-return-item {
    background-color: #f9f9f9;
    min-width: 75%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 1.2rem;
    opacity: 1;
    border-radius: 1rem;
    border: 1px solid #c5b358;
    font-weight: 800;
  }
  .FC-S-TC-Div-Right{
    display: inline-block;
    width: 90%;
  }
  .F-Title{
    color: rgb(245,245,245);
  }
 
}


/* Pop Up */
.PopUp{
  position: fixed;
  top: 0;
  left: 0;
  margin: 6vh 0vw 5vh 0vw;
  width: 100vw;
  height: 85vh;
  background-color: white;
  overflow-y: scroll;
  padding-bottom: 3vh;
}
.popup-copy{
  text-align: center;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  outline: 1px solid black;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 2rem;
}

/*TwoFriendCard*/
.TwoFriendCard{
  padding-top: 7vh;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  background-color: rgb(249,249,249);
  min-height: 100vh;
  padding-bottom: 10vh;
}
.TFC-ProfilePic{
  
}
.TFC-PP-Left{
  display: inline-block;
  width: 10%;
  vertical-align: top;
}
.TFC-PP-Center{
  display: inline-block;
  width: 78%;
}
.TFC-PP-Right{
  display: inline-block;
  width: 10%;
  vertical-align: top;
}
.TFC-Img{
  width: 10%;
  border-radius: 100rem;
  margin-left: 0%;
}
.TFC-username{
  font-size: 1.5rem;
  color: black;
}
.TFC-Gold{
  font-size: 1.30rem;
  font-weight: 200;
  color: #c5b358;
}
.TFC-member{
  font-size: .75rem;
}
.TFC-Values{
  padding-top: 0%;
  margin-top: 0%;
}
.TFC-Value{
  font-size: 2.85rem;
  font-weight: 800;
  text-shadow: 1.5px 1.5px 1.5px #c5b358;
}
.fa-caret-square-up{
  margin-right: 3%;
}
.TFC-change{
  font-size: 1.25rem;
  color: green;
  margin-left: 5%;
}
.TFC-leagues{

}
.TFC-leagues button{
  border-radius: 1rem;
  width: 24%;
  margin-left: .5%;
  margin-right: .5%;
  background-color: rgb(249,249,249);
  border: none;
  margin-top: 3%;
}
.tfc-l-selected{
  background-color: gray !important; 
  color: rgb(249, 249, 249)
}
.TFC-Payouts{
  /*background-color: white;*/
  width: 70%;
  margin-left: 15%;
  border-radius: .5rem;
  margin-top: 3%;
}
.tfc-p-title{
  font-size: 1.45rem;
  font-weight: 200;
  /*text-decoration: underline;*/
}
.tfc-p-amount{
  font-size: 1.05rem;
  font-weight: 600;
}
.TFC-Info{
  margin-top: 5%;
  font-size: 1.15rem;
}
.tfc-i-Head{
  display: inline-block;
  width: 33%;
  text-align: center;
  background-color: rgb(249,249,249);
  padding: 0%;
  border: none;
}
.tfci-hr{
  margin: 0%;
  padding: 0%;
  margin-top: 1%;
}
.tfci-trade{

}
.tfci-selected{
  background-color: #c5b358;
  height: 5px;
}
.tfci-shares{
  
}
.tfci-content{
  
}

.tfc-i-line{
  width: 0.5%;
  display: inline-block;
  font-weight: 200;
  font-size: 1.5rem
}

.TFC-Content{
  padding-top: 13%;
}
.TFC-c-btn{
  margin-top: 5%;
  border-radius: .5rem;
  background-color: #c5b358;
  padding: 3%;
  font-size: 0.85rem;
}
.TFC-edit{
  display: inline-block;
  width: 100%;
  font-size: 1.75rem;
  color: gray;
  padding-right: 3%;
}

/* Scroller */ 

.T-Scroller{
  font-size: 1.15rem;
  /*background-color: rgb(146,140,108, 0.2);*/
  background-color: rgb(245, 245, 245);
  padding-top: 6vh;
}
.T-Scroller-Head{
  display: inline-block;
  width: 50%;
  text-align: center;
  padding: 0%;
  border: none;
}
.T-Scroller-hr{
  margin: 0%;
  padding: 0%;
  margin-top: 1%;
}

.T-Scroller-selected{
  background-color: #c5b358;
  height: 5px;
}

/*Start My Card*/
.MyCard{
  padding: 3%;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  margin: 3%;
  vertical-align: top;
  font-size: 1.5rem;
  margin-top: 5%;
  margin-bottom: 5%;
  color: black;
}
.MC-img-div{
  display: inline-block;
  width: 10%;
  
}
.MC-img{
  display: inline-block;
  width: 100%;
  border-radius: 1rem;
}
.MC-username{
  display: inline-block;
  padding-left: 3%;
  width: 65%;
  text-align: left;
  vertical-align: top;
  padding-top: 1.5%;
  font-weight: 500;
}
.MC-value{
  display: inline-block;
  padding-left: 3%;
  width: 14%;
  padding-right: 3%;
  text-align: right;
  vertical-align: top;
  padding-top: 2.25%;
  font-size: 1.2rem;
  font-weight: 200;
}
/*End My Card*/

/*Start Activity*/
.Teammate-Activity-title{
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 1%;
}
.Teammate-Activity-subtitle{
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  margin-bottom: 1%;
  color: gray;
}
.Teammate-Activity{
  overflow-y: scroll;
  white-space: nowrap;
  vertical-align: top;
  margin-bottom: 5%;

}
.T-Activity-Card{
  background-color: white;
  width: 34.5%;
  padding: 3%;
  margin : 1%;
  display: inline-block;
  border-radius: .5rem;
  min-height: 10vh;;
  box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
  vertical-align: top;
  white-space: normal;
  text-align: center;
  color: black;
}
.T-Activity-Card-body{
  font-size: 1.15rem;
  padding-top: 2%;
  font-weight: 200;
}
/*End Activity*/

/*Start Teammates*/
.Teammate-Teammates-title{
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 1%;
  padding-left: 2%;
}
.Teammate-Teammates-div{
  padding: 3%;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  margin: 3%;
  vertical-align: top;
  font-size: 1.1rem;
  margin-bottom: 5%;
}
.T-Teammates-img-div{
  display: inline-block;
  width: 10%;
  
}
.T-Teammates-img{
  display: inline-block;
  width: 100%;
  border-radius: 1rem;
}
.T-Teammates-username{
  display: inline-block;
  padding-left: 3%;
  width: 45%;
  text-align: left;
  vertical-align: top;
  padding-top: 1.5%;
  font-weight: 500;
  color: black;
}
.T-Teammates-value{
  display: inline-block;
  padding-left: 3%;
  width: 44%;
  padding-right: 3%;
  text-align: right;
  vertical-align: top;
  padding-top: 2.25%;
  font-size: 1rem;
  font-weight: 200;
  color: black;
}
/*End Teammates*/

/*Start Activity*/
.Teammate-Signing-title{
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 1%;
  padding-left: 2%;
}
.Teammate-Signing-subtitle{
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  margin-bottom: 1%;
  color: gray;
  padding-left: 2%;
}
.Teammate-Signing-div{
  padding: 3%;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  margin: 3%;
  vertical-align: top;
  font-size: 1.1rem;
  margin-bottom: 5%;
}
.Teammate-Signing-code-div{
  width: 100%;
  text-align: center;
  font-weight: 200;
  font-size: 0.8rem;
  color: gray;
}
.Teammate-Signing-code{
  font-weight: 600;
  font-size: 1.2rem;
  color: black;
}
.Teammate-Signing-hr{
  border: .1px solid rgb(235,235,235);
}
.Teammate-Signing-progress{
  text-align: center;
  font-weight: 200;
  margin-top: 5%;
  margin-bottom: 5%;
}
.Teammate-Signing-progress-line-green{
  border: 5px solid green;
  margin-left: 10%;
  display: inline-block;
  width: 45%;
  padding-left: 0%;
  padding-right: 0%
}
.Teammate-Signing-progress-line-red{
  border: 5px solid red;

  margin-right: 10%;
  display: inline-block;
  width: 28%;
  padding-left: 0%;
  padding-right: 0%
}
.T-S-P-Numbers{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
}
.T-S-P-Num{
  display: inline-block;
  text-align: center;
  width: 20%;
}




@media screen and (min-width: 1024px) {

 /* Edit */
.EditFriendHodler{
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(230,230,230);
  outline: 2px black solid;
  overflow-y: auto;
}
.EditFriend{
  top: 0;
  left: 0;
  margin-left: 10vw;
  margin-top: 15vh;
  width: 80vw;
  height: 80vh;
  background-color: white;
  outline: 1px black solid;
  box-shadow: 0px 28px 46px 0px rgba(0,0,0,0.2);
  padding: 5%;
  padding-top: 2%;
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
.Edit-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check-Type-currently{
  font-weight: 200;
  margin-left: 2%;
  font-size: .9rem;
}
.Edit-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 2%;
  font-weight: 400;
}
.Edit-Label {
  display: inline-block;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2rem;
}
.Edit-deleteBtn{
  border-radius: .8rem;
  background-color: white;
  margin-top: 2%;
  font-weight: 200;
  padding: 0;
  border: none;
  background: none;
  font-size: 0.8rem;
}
.edit-close-btn{
  text-align: right;
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}
}
@media screen and (min-width: 1200px) {
  .DesktopOnly{
    padding-left: 25%;
    padding-right: 25%;
  }
  .Friends {
    width: 100%;
    padding-left: 30%;
    padding-right: 30%;
  }
  .WA-Body2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.4rem;
    text-align: left;
  }
  .WA-Title{
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    padding: 5%;
    padding-bottom: 1%;
  }
  .WA-Body1{
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.5rem;
    font-style: italic;
    padding-bottom: 5%;
    text-align: center;
  }
  .WA-Body2-words{
    padding-top: 3%;
  }
  .FC-E-item-return-item {
    display: block;
    background-color: #f9f9f9;
    min-width: 30%;
    margin-left: 30%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.2rem;
    opacity: 1;
    border-radius: 1rem;
    border: 1px solid #c5b358;
    font-weight: 800;
  }
  .PopUp{
    position: fixed;
    top: 0;
    left: 0;
    margin: 6vh 0vw 5vh 0vw;
    width: 100vw;
    height: 95vh;
    padding-left: 25vw;
    padding-right: 25vw;
    background-color: white;
    overflow-y: scroll;
    padding-bottom: 0vh;
  }
  .popup-copy{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    outline: 1px solid black;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 2rem;
  }

 /* Edit */
.EditFriendHodler{
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(230,230,230);
  outline: 2px black solid;
  overflow-y: auto;
}
.EditFriend{
  top: 0;
  left: 0;
  margin-left: 25vw;
  margin-top: 15vh;
  width: 50vw;
  height: 80vh;
  background-color: white;
  outline: 1px black solid;
  box-shadow: 0px 28px 46px 0px rgba(0,0,0,0.2);
  padding: 5%;
  padding-top: 2%;
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
.Edit-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check-Type-currently{
  font-weight: 200;
  margin-left: 2%;
  font-size: .9rem;
}
.Edit-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 2%;
  font-weight: 400;
}
.Edit-Label {
  display: inline-block;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2rem;
}
.Edit-deleteBtn{
  border-radius: .8rem;
  background-color: white;
  margin-top: 2%;
  font-weight: 200;
  padding: 0;
  border: none;
  background: none;
  font-size: 0.8rem;
}
.edit-close-btn{
  text-align: right;
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}
.T-Scroller{
  background-color: rgb(245, 245, 245);
  padding-top: 0vh;
}

.T-Teammates-img-div{
  display: inline-block;
  width: 6%;
  
}


}
.Ticker{
    padding-top: 6vh;
}
.trade-teamcard{
    display: inline-block;
    background-color: white;
    margin: .5%;
    margin-left: 2%;
    margin-right: 0.5%;
    border-radius: .4rem;
    min-width: 20%;
    max-width: 31%;
    min-height: 10vh;
    display: inline-block;
    text-align: left;
    font-size: 0.9rem;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    padding-top: 2%;
    text-align: 'center';
    padding-left: 4px;
    overflow-y: hidden;
}
.trade-card{
    font-size: 0.7rem;
    color: darkred;
    font-weight: 300;
}
.trade-header-left{
    display: inline-block;
    width: 70%;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 1.1em
}
.trade-header-right{
    text-align: right;
    display: inline-block;
    font-weight: 300;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    font-size: 0.9em;
    color: gray
}
.green{
    color: green;
}
.red{
    color: #ff7d7d;
}
.trade-card-type{
    display: inline-block;
    margin-right: 0%;
    width: 44%;
    padding-left: 20px;
}
.trade-card-team{
    display: inline-block;
}
.trade-card-price{
    display: inline-block;
    width: 56%;
    text-align: right;
    padding-right: 20px
}

.Search{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    background-color: rgb(244,244,244);
    z-index: 1;
    padding-bottom: 9vh;
}

.LeagueFilter{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    background-color: rgb(244,244,244);
    z-index: 1;
    padding-bottom: 9vh;
}
.leagueFilter-btn{
    
    margin-left: 2.6%;
    border: none;
    outline: none;
    color: gray;
    margin-bottom: 5px;
    background-color: black;
    font-size: 1.1em
}
.leagueFilter-btn-selected{
    
    margin-left: 2.6%;
    border: none;
    border-bottom: 2px #c5b358 solid;
    outline: none;
    font-weight: 600;
    color: #c5b358;
    margin-bottom: 5px;
    background-color: black;
    font-size: 1.1em;
    
}

.allTrades-img{
    width: 100%;
    margin-top: 7px
}

.full-name{
    width: 38.5%;
    padding-left: 1%;
    display: inline-block;
    font-size: 1.08em;
    font-weight: 400;
}
.full-price{
    text-align: center;
    width: 36.5%;
    display: inline-block;
    font-weight: 300;
    font-size: 1em;
}
.full-percent{
    text-align: right;
    width: 19%;
    padding-right: 2px;
    display: inline-block;
    font-size: 1.06em;
    font-weight: 300;
}
.full-span{
    font-size: .75em;
    color: gray;
    font-weight: 200;
    display: inline-block;
}
.full-img{
    width: 100%;
}

@media only screen and (min-width: 800px) {
.DesktopContainer{
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;
  }
}

@media only screen and (min-width: 1024px) {
    .Ticker{
        padding-top: 0vh;
    }
  .DesktopContainer{
      width: 100%;
      padding-left: 20%;
      padding-right: 20%;
  }
  .Search{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1%;
        background-color: rgb(244,244,244);
        z-index: 1;
        padding-bottom: 9vh;
    }
    .LeagueFilter{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1%;
        background-color: rgb(244,244,244);
        z-index: 1;
        padding-bottom: 3vh;
        padding-left: 25%;
        padding-right: 25%;
    }
    .trade-teamcard{
        max-width: 26%
    }
    .allTrades-img{
        width: 50%;
        margin-top: 7px;
        margin-left: 25%;
    }
    .full-name{
        width: 50%;
        padding-left: 1%;
        display: inline-block;
        font-size: 1.1em;
        font-weight: 400;
    }
    .full-price{
        text-align: center;
        width: 25%;
        display: inline-block;
        font-weight: 300;
        font-size: 1em;
    }
    .full-percent{
        text-align: right;
        width: 15%;
        padding-right: 5px;
        display: inline-block;
        font-size: 1.07em;
        font-weight: 300;
    }
    .full-span{
        font-size: .75em;
        color: gray;
        font-weight: 200;
        display: inline-block;
    }
    .full-img{
        width: 70%;
    }
}
.noScroll{margin: 0; height: 100%; overflow: hidden}
.normal{height: 100%;}
.no-display{
    display: none;
}

.loading-div{
    background-color: white;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 5%;
    padding-bottom: 25%;
    z-index: 1000;
    opacity: .75;
}

.TradePopup{
    height: 110vh;
    position:fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color:white;
    margin-left: 0vw;
    padding-top: 3vh;
    outline: black solid 5px;
    font-family: 'Roboto Slab', serif;
    z-index: 101;
    overflow: auto;
}
.z1000{
    z-index: 1000;
}
.SellShare{
    padding: 5%;
    padding-bottom: 15vh;
}
.fa-times-circle{
    float: right;
    font-size: 3rem;
    position: fixed;
    right: 0;
    top: 0;
    margin-top: 4vh;
    margin-right: 2vw;
    color: gray;
}
.ss-title{
    font-size: 2.5rem;
    text-align: center;
    font-weight: 600;
}
.ss-img{
    width: 10%;
}
.ss-team{
    margin-top: 2%;
    font-weight: 600;
    font-size: 1.75rem;
    text-align: center;
}
.ss-team-price{
    font-weight: 200;
    font-size: 1.75rem;
}
.ss-shares{
    margin-top: 5%;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
}
.ss-cash{
    margin-top: 5%;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
}
.ss-shareAmt{
    font-size: 3.5rem;
    text-align: center;
    margin-top: 2%;
    font-weight: 200;

}
.ss-shareAmt2{
    font-size: 3.5rem;
    text-align: right;
    margin-top: 2%;
    font-weight: 200;
    display: inline-block;
    width: 33%;

}
.ss-shareAmt-underline{
    text-decoration: underline;
}
.ss-share-label{
    font-size: 1.2rem;
    font-weight: 400;
}
.fa-undo{
    position: fixed;
    left: 0;
    margin-top: 10%;
    margin-left: 10%;
    font-size: 2rem;
    color: black;
}
.ss-btn-div{
    text-align: right;
    margin-top: 5%;
}
.ss-submit{
    width: 100%;
    background-color:  #c5b358;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 1rem;
    text-align: center;
}
.center{
    text-align: center;
}
.ss-btn{
    width: 33%;
    font-size: 2rem;
    background-color: white;
    border: white solid 1px;
}
.ss-btn-2{
    width: 33%;
    font-size: 2rem;
    text-align: center;
    border: lightgray solid 1px;
}
.underline{
    text-decoration: underline;
}
.fa-backspace{
    color: black;
    background-color: black;
    text-align: center;
}

/* Offers Buttons */
.offers-overlay{
    width: 100%;
}
.trade-offer-btns-div{
    width: 100%;
    background-color: black;
}
.trade-offer-btns{
    width: 100%;
    background-color: #c5b358;
    border-radius: 1rem;
    font-size: 1.3rem;
    margin-bottom: 3%;
    margin-top: 3%;
}

/* Bid Ask Stuff on Offers */
.h-bidask{
    text-align: center;
    font-size: 0.6rem;
}
.h-bid{
    width: 33%;
    display: inline-block;
    text-align: right;
}
.h-price{
    font-weight: 200;
    font-size: 1.75rem;
    width: 33%;
    display: inline-block;
}
.h-ask{
    width: 33%;
    display: inline-block;
    text-align: left;
}
.h-bid-p{
    display: inline-block;
}
.h-ask-p{
    display: inline-block;
}
.green{
    color: green;
}
.red{
    color: red;
}
.gray{
    color: lightgray;
}

@media only screen and (min-width: 1024px) {
    .TradePopup{
        padding-left: 25%;
        padding-right: 25%;
    }
    .ss-submit{
        width: 100%;
        background-color:  #c5b358;
        font-size: 1.5rem;
        font-weight: 600;
        border-radius: 1rem;
        text-align: center;
    }
    .center-function{
        text-align: center;
        width: 100%;
    }
    .loading-div{
        background-color: white;
        height: 50vh;
        width: 50vw;
        position: absolute;
        top: 50;
        left: 50;
        padding-left: 25%;
        padding-right: 25%;
        margin-left: 25%;
        margin-top: 15%;
        opacity: .5;
    }
}
/*Purchase Receipts*/
.transaction-recipt{
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    
    font-family: 'Roboto Slab', serif;
}
.transaction-recipt-modal{
    position:fixed;
    background: white;
    width: 80%;
    max-height: 80%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    overflow-y: auto;
    text-align: center;
    outline: 10px black solid;
    padding-bottom: 5%;
}
.transaction-recipt-head{
    padding: 5%;
    padding-bottom: 3%;
    font-size: 2em;
}
.transaction-recipt-body{
    padding: 5%;
    font-size: 1.2em;
}
.transaction-recipt-img{
    padding: 5%;
}
.transaction-recipt-share{
    margin-top: 5%;
    padding: 5%;
}
.share-buttons-div{
    width: 100%;
}
.share-btn{
    width: 90%;
    margin-left: 2.5%;
    background-color: #c5b358;
    margin-top: 2%;
    border-radius: 1rem;
    padding: 1% 0% 1% 0%
}
.share-close-btn{
    width: 90%;
    margin-left: 2.5%;
    background-color: rgb(197, 197, 197);
    margin-top: 2%;
    border-radius: 1rem;
    padding: 1% 0% 1% 0%
}
.tr-name-sim{
    font-style: italic;
}


@media only screen and (min-width: 1024px) {
    .transaction-receipt{
        overflow-y: auto;
    }
    .transaction-recipt-modal{
        position: fixed;
        background: white;
        width: 60%;

        transform: translate(-50%,-50%);
        overflow-y: auto;
        text-align: center;
        outline: 10px black solid;
        padding-bottom: 5%;
    }
    .transaction-recipt-head{
        padding: 5%;
        padding-bottom: 1%;
        font-size: 2em;
    }
    .transaction-recipt-body{
        padding: 5%;
        padding-bottom: 1%;
        font-size: 1.2em;
    }
    .transaction-recipt-img{
        padding: 5%;
        padding-bottom: 1%;
    }
    .transaction-recipt-share{
        margin-top: 2%;
        padding: 5%;
        padding-bottom: 1%;
    }
}
.Analysts{
    font-family: 'Roboto Slab', serif;
}    
.WelcomeTour{
    padding: 2% 5% 2% 5%;
    font-family: 'Roboto Slab', serif;
}
.welcome-Head{
    font-size: 2rem;
}
.welcome-btns {
    margin-top: 2%;
    text-align: center;
} 

.input-desc{
    font-size: 0.7em;
    color: gray;
    margin-left: 10%;
}
.input-input{
    font-size: 1em;
    color: black;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 5px;
    border: 1px solid gray;
    padding-left: 5px;
    text-align: center;
    border-radius: 0.2rem;
}

.makemoney{
    margin-top: 11px;
    text-align: center;
}
.trade-box{
    text-align: center;
    border: 1px solid black;
    border-radius: 0.8em;
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
}

.deposit-input{
    font-size: 1.5em;
    color: black;
    width: 78.5%;
    margin-bottom: 5px;
    border: 1px solid gray;
    padding-left: 5px;
    text-align: center;
    border-radius: 0.2rem;
}
.deposit-btn{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 1px solid gray;
    font-size: 1.5em;
    color: gray;
    border-radius: 0.1em;
}

.deposit-btn-gold{
    width:46%;
    margin-left: 2%; 
    margin-right: 2%;
    margin-bottom: 7px;
    background-color: white;
    border: 3px solid #c5b358;
    font-size: 1.5em;
    color: #c5b358;
    border-radius: 0.1em;
    font-weight: 500;
}

@media only screen and (min-width: 1024px) {
    .WelcomeTour{
        padding-left: 33%;
        padding-right: 33%;

    }


}
.GettingStarted{
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 20%;
    margin-top: 10vh;
    text-align: center;
}
.gs-head{
    font-size: 2rem;
    font-weight: 900;
}
.gs-sub{

}
.gs-img{
    width: 50%;
}
.gs-car-txt{
    text-align: center;
    font-size: .9rem;
}
@media only screen and (min-width: 1024px) {
    .gs-img{
        width: 15%;
    }
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}
.Login{
    min-height: 40vh;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    padding-bottom: 15%;
}
.login-head{
    font-size: 2rem;
    padding-top: 12%;
    padding-bottom: 10%;
    font-weight: 900;
    
}
.login-div{
    padding-bottom: 5%;
}
.login-div-head{
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: underline;
}
.login-div-input{
    border-radius: .75rem;
    width: 80%;
    margin: 1%;
    text-align: center;
    font-size: 1.2rem;
}
.login-div-check{
    -webkit-appearance:none;
    width:20px;
    height:20px;
    background:white;
    border-radius:5px;
    border:2px solid #555;
}
input[type='checkbox']:checked {
    background: black;
}
.login-div-btn{
    display: block;
    width: 60%;
    margin-left: 20%;
    border-radius: 0.75rem;
    margin-top: 2%;
    background-color: lightgray;
    color: black
}
.login-switch{
    padding: 5%;
}
.login-forgot{
    border-radius: 1rem;
}
.loginPage{
    margin-top: 9vh;
}
@media only screen and (min-width: 1024px) {
    .loginPage{
        margin-top: 12vh;
    }
}
.signup-wrapper {
    overflow-x: hidden;
}

.signup-container {
    display: flex;
    width: 300vw;
    flex-direction: row;
    margin-top: 0px;
    padding-top: 0px;
    transition: transform .2s ease-out;
}

.signup-container > div {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: calc(10vh + 0px);
    padding-left: calc(15vw - 22px);
    padding-right: calc(15vw - 22px);

    background-color: #000;
    color: #FFF;
}

.signup-title {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px;
}

.signup-subtitle {
    font-weight: 800;
    font-size: 1.4em;
    color: #C4B454;
}

.signup-highlight {
    color: #C4B454;
}

.signup-title p {
    font-family: 'serif';
    font-size: 0.8em;
}

/* region Generic Inputs */

.signup-form-input {
    width: 100%;
    text-align: left;
    position: relative;
    margin-bottom: 1.5em;

    border: 2px solid #FFF;
    border-radius: 6px;

    transition: border-color 0.2s ease-out;
}

.signup-code-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 1.5em;
}

.signup-code-container:hover .signup-code-input,
.signup-code-container:focus-within .signup-code-input {
    border-color: #8f8341;
}

.signup-code-input {
    width: 50px;
    height: 50px;
    font-size: 2em;
    border: 2px solid #FFF;
    border-left: none;
    border-right: none;

    text-align: center;
    background-color: #000;
    color: #c5c5c5;
    caret-color: #C4B454;

    outline: none !important;
    transition: border-color .3s ease-out, color .2s ease-out;
}

.signup-code-input:focus {
    border-color: #C4B454 !important;
    color: #FFF;
}

.signup-code-input::-webkit-input-placeholder {
    text-align: center;
    color: #bbbbbb;
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
}

.signup-code-input:-ms-input-placeholder {
    text-align: center;
    color: #bbbbbb;
    -ms-transition: color .2s ease;
    transition: color .2s ease;
}

.signup-code-input::placeholder {
    text-align: center;
    color: #bbbbbb;
    transition: color .2s ease;
}

.signup-code-input:hover::-webkit-input-placeholder, .signup-code-input:focus::-webkit-input-placeholder {
    color: #EDEDED;
}

.signup-code-input:hover:-ms-input-placeholder, .signup-code-input:focus:-ms-input-placeholder {
    color: #EDEDED;
}

.signup-code-input:hover::placeholder,
.signup-code-input:focus::placeholder {
    color: #EDEDED;
}

.signup-code-input:first-child {
    border-left: 2px solid;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.signup-code-input:last-child {
    border-right: 2px solid;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

/* Validation */
.signup-form-input .invalid-feedback {
    position: absolute;
    bottom: -1.5em;
}

/* Icon */
.signup-form-input .input-group-text {
    min-width: 48px;

    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    color: #FFF;
    background-color: #000;
    font-size: 1.2em;
    text-align: center;

    justify-content: center;
}

/* Input */
.signup-form-input .form-control {
    padding: .6rem .75rem .6rem 0;
    height: calc(1.5em + 1.2rem + 2px);

    border: none;
    outline: none !important;
    box-shadow: none !important;

    color: #c5c5c5;
    background-color: #000;
    caret-color: #C4B454;

    transition: color 0.2s ease-out;
}

.signup-form-input input:-webkit-autofill {
    background-color: #000 !important;
    background-clip: content-box !important;
}

/* Left align date input placeholder */
.signup-form-input input::-webkit-date-and-time-value {
    text-align: left;
}

.signup-form-input:hover,
.signup-form-input:hover .form-control.secondary{
    border-color: #8f8341 !important;
}

.signup-form-input:focus-within,
.signup-form-input:focus-within .form-control.secondary{
    border-color: #C4B454 !important;
}

.signup-form-input .form-control:hover,
.signup-form-input .form-control:focus {
    color: #FFF;
}

.signup-form-input .form-control::-webkit-input-placeholder {
    color: #bbbbbb;
    text-align: left;
}

.signup-form-input .form-control:-ms-input-placeholder {
    color: #bbbbbb;
    text-align: left;
}

.signup-form-input .form-control::placeholder {
    color: #bbbbbb;
    text-align: left;
}

.signup-form-input .form-control.secondary {
    padding-left: .6rem;
    border-left: 2px solid white;
    transition: border-color .2s ease-out;
    z-index: 4;
}

.signup-form-input .btn-form-signup {
    width: auto;
    border-radius: 0 4px 4px 0;
    margin: 0;
}

/* endregion */
/* region Checkbox */

.signup-form-checkbox {
    width: 100%;

    display: block;
    position: relative;

    color: #949494;

    padding-left: 30px;
    cursor: pointer;
    font-size: 0.8em;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signup-form-checkbox label {
    cursor: pointer;
}

.signup-form-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.signup-form-checkbox span {
    position: absolute;
    top: 6px;
    bottom: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #000;
    border: 2px solid #C4B454;
    border-radius: 8px;

    transition: background-color 0.2s ease-out;
}

.signup-form-checkbox:hover input ~ span {
    background-color: #5e531f;
}

.signup-form-checkbox input:checked ~ span {
    background-color: #C4B454;
}

.signup-form-checkbox span:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c"; /* Checkmark */
    position: absolute;
    display: block;
    top: -2px;
    font-size: 1.25em;
    color: white;
    opacity: 0;

    transition: opacity .2s ease-out;
}

.signup-form-checkbox input:checked ~ span:after {
    opacity: 1;
}

/* endregion */
/* region Signup Button */


/* Cant use btn-signup or it'll clash */
.btn-form-signup {
    padding: 0.25em .75em;
    width: 100%;
    border: 2px solid transparent;
    background-color: #C4B454;
    border-radius: 3em;
    color: black;
    font-weight: bold;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 3em;

    box-shadow: none;

    transition: border-color 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
}

.btn-form-signup:focus,
.btn-form-signup:hover,
.btn-form-signup:disabled,
.btn-form-signup.disabled,
.btn-form-signup:active {
    color: black;
    background-color: #C4B454 !important;
    border-color: #C4B454 !important;
}

.btn-form-signup:not(:disabled):not(.disabled):hover,
.btn-form-signup:not(:disabled):not(.disabled):focus {
    box-shadow: 0 0 0 0.2rem rgba(196, 180, 84, 0.4) !important;
    color: #FFF !important;
}

/* endregion */
/* region Legal */

.signup-form-info {
    display: flex;
    width: 100%;
    font-size: 0.8em;
    flex-direction: row;
    justify-content: space-between;
    margin-top: .25em;
    margin-bottom: 2em;
    color: #949494;
}

.signup-form-info a,
.signup-form-info button {
    color: #8d8d8d;
    text-decoration: underline;
    transition: all .15s ease-out;
}

.signup-form-info a:hover,
.signup-form-info button:hover {
    color: #aeaeae;
}

.signup-form-info button {
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border: none !important;
}

.signup-form-info a:first-child {
    margin-right: .3em;
    text-align: right;
    flex-basis: 0;
    flex-grow: 1;
}

.signup-form-info a:last-child {
    margin-left: .3em;
    text-align: left;
    flex-basis: 0;
    flex-grow: 1;
}

.signup-form-feedback {
    color: #dc3545;
    font-size: 90%;
    text-align: center;
    display: block;
    margin-bottom: .5rem;
}

.signup-referral-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    text-align: center;
}

.signup-referral-info p {
    color: #d3d3d3;
}

.signup-referral-panel {
    width: 100%;
}

.signup-referral-panel .signup-form-input {
    margin-bottom: 0;
    border-radius: 6px 6px 0 0;
    width: 100%;
}

.signup-referral-panel .form-control {
    text-align: center;
}

.signup-referral-panel .btn-form-signup {
    width: 100%;
}

.signup-referral-panel .col-12:not(:first-child):last-child .btn-form-signup {
    border-radius: 0 0 6px 6px;
}

.signup-referral-panel .col-6:not(:last-child) .btn-form-signup {
    border-radius: 0 0 0 6px;
    border-right: 1px solid #333;
}

.signup-referral-panel .col-6:last-child .btn-form-signup {
    border-radius: 0 0 6px 0;
    border-left: 1px solid #333;
}

/* region Media Queries */

@media (min-width: 600px) {
    .signup-referral-info p {
        max-width: 80%;
    }
}

@media (min-width: 600px) and (max-width: 989.9px) {
    .signup-form-input, .signup-form-checkbox, .signup-form-info {
        width: 60vw;
    }

    .btn-form-signup, .signup-referral-panel {
        width: 40vw;
    }
}

@media (min-width: 990px) {
    .signup-form-input, .signup-form-checkbox, .signup-form-info {
        width: 40vw;
    }

    .btn-form-signup, .signup-referral-panel {
        width: 20vw;
    }
}

/* endregion */
.TeamReport{
    padding-top: 9vh;
    background-color: rgb(245, 245, 245);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10vh;
}

.Reports-title{
    font-size: 1rem;
    font-weight: 600;
}
.Reports-item{
    font-size: 1.5rem;
    font-weight: 200;
}
.Reports-body{
    font-weight: 200;
}

.InputReport{
    padding: 3%;
    padding-top: 10vh;
    min-height: 100vh;
    padding-bottom: 10vh;
    background-color: rgb(245, 245, 245);
    
}
.InputReport-head{
    font-size: .8rem;

}
.InputReport-input{
    width: 90%;
    margin-left: 5%;
    margin-bottom: 7px;
    font-size: 1.2rem;
    padding: 2px;
}
.InputReport-submit{
    padding: 5px;
    border-radius: 0.8rem;
    min-width: 60%;
    margin-left: 20%;
    margin-bottom: 7px;
    font-size: 1.2rem;
    border: none;
    outline: none;
    background-color: #c5b358;

}

@media only screen and (min-width: 1024px) {
    .TeamReport{
        padding-top: 5px;
    }
}
.SimBullSix{
        padding-left: 5%;
        padding-right: 5%
    }
@media only screen and (min-width: 768px) {
    .SimBullSix{
        padding-left: 25%;
        padding-right: 25%
    }
}
.playoff-title{
    font-size: 3em;
    text-align: center;
    font-weight: 600;
    padding-top: 6vh;
    margin-right: 7.5px;
    margin-left: 7.5px;
}
.playoff-subtitle{
    font-size: 1.5em;
    text-align: center;
    margin-right: 7.5px;
    margin-left: 7.5px;
    font-weight: 300
}
.playoff-desc{
    font-size: 1em;
    text-align: center;
    font-weight: 200;
    margin-right: 7.5px;
    margin-left: 7.5px;
}

.left-team{
    display:inline-block;
    width: 45%;
    margin-bottom: 5px;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
}
.middle-team{
    display:inline-block;
    width: 10%;
    margin-bottom: 5px;
    font-size: 1.1rem;
    text-align: center;
    color: gray;
    vertical-align: top;
    padding-top: 5px
}
.right-team{
    display:inline-block;
    width: 45%;
    margin-bottom: 5px;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
}

.banner-img{
    width: 90%;
    border-radius: .7em;
    margin-top: 6vh;
}
.mobile{
    display:  inline;
    display:  initial
}
.desktop{
    display:none
}

.jersey-img{
    width: 25%
}

.game-div{
    background-color: white;
    width: 90%;
    margin-left: 5%;
    border-radius: .7em;
    margin-top: 20px;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    padding-bottom: 1px;
}

.team-btn{
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: .6em;
    width: 60%;
    background-color: rgb(235,235,235);
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200)
}

.logoImg{
    width: 30%;
}

.blk-back{
    background-color: black;
    width: 90%;
    margin-left: 5%;
    border-radius: .7em;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-top: 6vh;
}


.login-div{
    width: 90%;
    margin-left: 5%;
    margin-top: 25px;
    border: .75px solid gray;
    padding: 20px 40px 20px 40px;
}

.login-type{
    font-size: .8em;
    color: gray;
}

.login-input{
    width: 100%;
}

.submit-btn{
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: .6em;
    width: 30%;
    background-color: #c5b358;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200)
}

.share-btn{
    width: 90%;
    background-color: rgb(245,245,245);
    margin-top: 2%;
    border-radius: .7rem;
    padding: 1% 0% 1% 0%;
    margin-left: 5%;
    border: 1px solid gray;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
}
.share-social{
    width: 90%;
    background-color: rgb(245,245,245);
    margin-top: 2%;
    border-radius: .7rem;
    padding: 1% 0% 1% 0%;
    margin-left: 5%;
    text-align: center;
    font-weight: 300;
}

/*Start Spinner*/
.fixed_width{
    width: 1000px !important
}
.right-card{

  text-align: 'center'
}
.container {
  position: relative;
  margin: 10px auto;
  width: 80%;
  min-height: 300px;
  z-index: 1;
  perspective: 1000;
  

}
.innercontainer {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1.0s linear;
}
.container:hover .innercontainer {
  transform: rotateY(180deg);
  box-shadow: -5px 5px 5px #aaa;
}
.face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: 0 0 2.5px 2px rgb(200, 200, 200);
}
.face.back {
  display: block;
  transform: rotateY(180deg);
  box-sizing: border-box;
  padding: 1px;
  padding-top: 5px;
  color: black;
  text-align: center;
  background-color: white;
  min-height: 300px;
  border: 1px solid gray;
}
.face.front{
  background-color: black;
  color: #c5b358;
  text-align: center;
  padding: 20px;
  border-radius: .7em;
  min-height: 300px;
  
}
.simbull{
    width: 50%;
}
.card-title-spin{
    font-size: 2.9em;
    font-weight: 300;
}
.card-team-spin{
    font-size: 1.4em;
    font-weight: 200;
}

.Spinner{
    border-radius: .7em;
    margin-top: 25px;
    background-color: white;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    width: 90%;
    margin-left: 5%;
    padding: 0%;
    padding-top: 25px;
    padding-bottom: 25px;
    
    

}
.spin-btn{
    margin-top: 10px;
    font-weight: 500;
    background-color: rgb(240,240,240);
    border: 2px solid #c5b358;
    border-radius: .7em;
    font-size: 1.8em;
    width: 60%;
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    margin-bottom: 20px;
}
/*End Spinner */

@media only screen and (min-width: 1024px) {

    .mobile{
        display:  none
    }
    .desktop{
        display:inline;
        display:initial
    }

    .banner-img{
        width: 60%;
        border-radius: .7em;
        margin-top: 0vh;
    }
    .playoff-title{
        padding-top: 15px;
    }
    .left-team{
        display:inline-block;
        width: 40%;
        padding-left: 5%;
        margin-bottom: 5px;
    }
    .middle-team{
        display:inline-block;
        width: 10%;
        margin-bottom: 5px;
        margin-top: 10px
    }
    .right-team{
        display:inline-block;
        width: 40%;
        padding-right: 5%;
        margin-bottom: 5px;
    }

    .game-div{
        width: 60%;
        margin-left: 20%;

    }
    .share-btn{
        width: 50%;
        background-color: rgb(245,245,245);
        margin-top: 5px;
        border-radius: .7rem;
        padding: 5px 0% 5px 0%;
        margin-left: 25%;
        border: 1px solid gray;
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    }

    .share-social{
        width: 50%;
        background-color: rgb(245,245,245);
        margin-top: 5px;
        border-radius: .7rem;
        padding: 0px 0% 0px 0%;
        margin-left: 25%;
        text-align: center;
        font-weight: 300;
    }

    .logoImg{
    width: 10%;
    }

    .blk-back{
        background-color: black;
        width: 70%;
        margin-left: 15%;
        border-radius: .7em;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .login-div{
        width: 30%;
        margin-left: 35%;
        margin-top: 25px;
    }
    .team-btn{
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: .6em;
        width: 30%;
        background-color: rgb(235,235,235);
        box-shadow: 0 0 2.5px 1px rgb(200, 200, 200)
    }

    /* Spinner */
    .container{
        width: 40%;
        min-height: 250px;
    }
    .face.back {
    min-height: 250px;
    }
    .face.front{
min-height: 250px;
    }

    .simbulll{
        width: 20%;
    }
    .card-title-spin{
        font-size: 2.4em;
        font-weight: 300;
    }
    .card-team-spin{
        font-size: 1em;
        font-weight: 200;
    }
    .Spinner{
        width: 40%;
        margin-left: 30%;
    
    }
    .spin-btn{
        margin-top: 10px;
        font-weight: 500;
        background-color: rgb(240,240,240);
        width: 50%;
    }
}
.email-capture{
    padding: 15px;
    padding-top: 6vh;
}
.back-btn{
    display: block;
    outline: none;
    border: none;
    background: white;
    text-decoration: underline;
    color: rgb(100,100,100);
    margin-bottom: 50px;
}
.reset-head{
    margin-bottom: 15px;
    font-weight: 700;
}
.reset-body{
    color: gray;
    margin-bottom: 25px;
}
.reset-input-head{
    color: gray;
}
.reset-input-div{
    margin-left: 2px;
    width: 100%;
}
.reset-input{
    width: 100%;
    border-radius: .8em;
    margin-bottom: 10px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
    padding-left: 10px;
}
.reset-input-btn{
    width: 100%;
    background-color: #c5b358;
    color: white;
    padding: 8px;
    border: none;
    outline: none;
    border-radius: .95rem;
    font-weight: 600;
}

.check{
    text-align: center;
}
.check-head{
    padding-top: 20vh;
    font-weight: 700;
}
.check-div{
    min-width: 50%;
    color: #c5b358;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    
    outline: none;
    border-radius: .9rem;
    font-weight: 800;
    margin-bottom: 15px;
    font-size: 1.75rem;
}
.skip-btn{
    min-width: 40%;
    background-color: white;
    color: gray;
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    outline: none;
    border-radius: .9rem;
    font-weight: 600;
    background-color: white;
    margin-bottom: 40px;
}
.check-under{
    font-size: .9em;
    color: gray;
}

.pass-head{
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 2.2rem;
}
.pass-input{
    width: 100%;
    border-radius: .8em;
    margin-bottom: 0px;
    padding: 7px;
    outline: none;
    border: 1px solid gray;
    padding-left: 10px;
}
.pass-under{
    font-size: .75em;
    color: gray;
    margin-bottom: 15px;
    margin-left: 3px;
}

@media only screen and (min-width: 1024px) {
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}
/*
 *  Flex Layout Specifics
*/
main{
  display:flex;
  flex-direction:row;
  font-size: .85rem;
}
.round{
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:25%;
  list-style:none;
  padding:0;
}
.firstRound{
    width: 25%;
}
.roundFinal{
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:50% !important;
  list-style:none;
  padding:0;
}
.round .spacer{ flex-grow:1; }

.round .spacer:first-child,
.round .spacer:last-child{ 
    flex-grow:.5; }

.round .game-spacer{
    flex-grow:.75;
}
.round .gameRight-spacer{
    flex-grow:.75;
}
.final-four{
    width: 50%;
}

/*
 *  General Styles
*/


li.game{
    padding-left:3px;
    font-weight: 400;
}

li.game.winner{
    font-weight:bold;
}
.gameSpan{
    margin-left: 3px;
}
.gameRightSpan{
    
}
.seed{
    color: gray;
    font-size: .8em;
    font-weight: 300;
}
li.gameRight{
    /*float:left;*/
    margin-right:3px;
}

li.game-top{ 
    border-bottom:1.25px solid black; 
    white-space: nowrap;
    overflow: visible!important;
    z-index: 100;
    font-weight: 500;
    direction: ltr;
    width: 100%;
}

li.game-spacer{ 
    border-right:1px solid black;
    min-height: 30px;
    background-color: white;
    background-image: linear-gradient(to right, rgb(240,240,240) , white);
}
li.gameRight-spacer{ 
    border-left:1px solid black;
    min-height: 30px;
    background-image: linear-gradient(to left, rgb(240,240,240) , white);
}

li.game-bottom{ 
    border-top:1.25px solid black;
    white-space: nowrap;
    overflow: visible !important;
    direction: ltr;
    z-index: 100;
    width: 100%;
    /*box-shadow: 0px 5px 5px -5px #c5b358,
    0px -5px 3px -3px rgb(200,200,200);*/
    font-weight: 500;
}
.rightAlign{
    text-align: right;
    width: 100%;
    overflow: visible;
    direction: rtl;
}
.rules-canva{
    position: relative; width: 100%; height: 0; padding-top: 75.0000%;
    padding-bottom: 48px; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
    border-radius: 8px; will-change: transform;
    padding-left: 15px;
    padding-right: 15px;
}
.iframe-canva-rules{
    position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

.Bracket-signup{
    width: 95%;
    margin-left: 2.5%;
}

.Top-CTA-btn-signup{
    width: 98%;
    margin-left: 1%;
    margin-right: 0%;
    padding: 12px;
    font-size: .8em;
    padding-left: 2px;
    padding-top: 17.5px;
    padding-right: 2px;
    border-radius: .3em;
    background-color: #e0cb07;
    color: black;
    border: none;
    outline: none;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.Top-CTA-input-signup{
    width: 100%;
    margin-left: 0%;
    padding: 12px;
    border-radius: .2em;
    border: 2px solid rgb(200,200,200);
    margin-bottom: 5px;
}

.picks-submit-btn{
    background-color: #c5b358;
    border: none;
    border-radius: .3em;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    border: none;
    outline: none;
}
.picks-submit-btn:hover{
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: #e0cb07;
    box-shadow: 0 8px 36px rgba(0, 0, 0, 0.2);
}

.viewpicks{
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 6vh;
    background: rgba(224, 224, 224, .8);
    font-weight: 500;
}
.viewpicks_nouser{
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(224, 224, 224, .8);
    font-weight: 500
}
.z10000{
    z-index: 10000;
}

.viewp{
    display: block;
}
.hidep{
    display: none;
}

@media only screen and (min-width: 1024px) {

    .viewpicks{
        top: 0;
        right: 0;
        margin-top: 11vh;
        background: rgba(224, 224, 224, .8);
        font-weight: 500;

    }
    .viewpicks_nouser{
        top: 0;
        right: 0;
        background: rgba(224, 224, 224, .8);
        font-weight: 500;
    }
    
    .round{
        width: 17.5%;
    }
    .firstRound{
        width: 30%;
    }
    .Top-CTA-btn-signup{
        width: 27.5%;
        margin-left: 0%;
        margin-right: 0%;
        font-size: 1em;
        padding: 12px;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
        border-radius: .3em;
        background-color: #e0cb07;
        color: black;
        border: none;
        outline: none;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    }
    .Top-CTA-input-signup{
        width: 70%;
        margin-left: 0%;
        margin-right: 2.5%;
        padding: 12px;
        border-radius: .2em;
        border: 2px solid rgb(200,200,200);
        margin-bottom: 5px;
    }
    .visible{
        width: 50%;
        margin-left: 25%;
    }
}
.Payouts{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background-color: white;
    z-index: 100;
    
}
/*#spinner {
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count:1;
    animation-duration: 2s;
    /*transform-style: preserve-3d;
    text-align:center;
}
@keyframes spinner {
from {
    transform: rotateX(0deg);
}
to {
    transform: rotateX(-360deg);
}
}*/

@media only screen and (min-width: 1024px) {

    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}

.timeline {
    position: relative;
    padding: 100px 0;
    padding-bottom: 5px;
    padding-top: 20px;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-weight: 400;
    background-color: black;
    width: 90%;
    margin-bottom: 0px;
    margin-left: 5%;
}
.timeline::before {
    content: '';
    background: rgb(240,240,240);
    width: 2px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0px;
}
.timeline-item {
    width: 100%;
    margin-bottom: 70px;
}
.timeline-item:nth-child(even) .timeline-content {
    float: right;
    padding: 40px 30px 10px 30px;
}
.timeline-item:nth-child(even) .timeline-content .date {
    right: auto;
    left: 0;
}
.timeline-item::after {
    content: '';
    display: block;
    clear: both;
}
.timeline-content {
    position: relative;
    width: 45%;
    border-radius: 4px;
    display: inline-block;
    /* margin-left: 5%; */
    position: relative;
    padding: 10px 30px;
    /* left: 25%; */
}
.timeline-content2 {
    position: relative;
    width: 45%;
    margin-left: 10%;
    padding: 10px 30px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
}
.timeline-content2left {
    position: relative;
    width: 45%;
    padding: 10px 30px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
    text-align: right;
}

.timeline-img {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 5px;
    margin-left: -15px;
    border: 2px solid rgb(240,240,240);
    text-align: center;
    font-size: 1.1em;
}
.timeline-a {
    background: #3f51b5;
    color: black;
    padding: 8px 20px;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    display: inline-block;
    border-radius: 2px;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);
}
.timeline-card {
    padding: 0 !important;
}
.timeline-card p {
    padding: 0 20px;
}
.timeline-card a {
    margin-left: 20px;
}
.timeline-item .timeline-img-header {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .4)), url('https://picsum.photos/1000/800/?random') center center no-repeat;
    background-size: cover;
}
.timeline-img-header {
    height: 200px;
    position: relative;
    margin-bottom: 20px;
}
.timeline-img-header h2 {
    color: black;
    position: absolute;
    bottom: 5px;
    left: 20px;
}


.bottom-btn{
    padding: 15px 20px;
    border-radius: .4em;
    background-color: #3d3d3d;
    color: white;
    font-family: 'Lato', sans-serif !important;
    text-align: center;
    font-size: 14px;
    margin-top: 30px;
    font-weight: 600;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    border: none;
    outline: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 70px;
}
.bottom-btn:hover{
    background-color: #202020;
    margin-top: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    margin-bottom: 75px;
}

.Footer3{
    font-family: 'Roboto Slab', serif;
    background-color: black/*#21242c*/ !important;
    padding-left: 5%;
    color: white !important;
    padding-right: 5%;
    vertical-align: top;
    padding-top: 50px;
}
.f-div-item2{
    font-size: 0.98rem !important;
    font-weight: 300 !important;
    padding-top: 5%;
    color: white;
}

.fa-facebook{
    color: white;
    width: 100%;
}
.fa-instagram{
    color: white;
}
.fa-twitter{
    color: white;
}
.fa-linkedin{
    color: white;
}
.white{
    color: white !important;
}

.headerImage{
    width: 30%;
}

.signup_modal{
    width: 40vw
}

@media screen and (max-width: 768px) {
    .timeline::before {
        left: 0px;
   }
    .timeline .timeline-img {
        left: 0px;
   }
    .timeline .timeline-content {
        max-width: 100%;
        width: auto;
   }
    .timeline .timeline-item:nth-child(even) .timeline-content {
        float: none;
   }
   .timeline-content2{
       width: 100%;
       margin-left: 0%;
       margin-right: 0%;
       margin-bottom: 20px;
   }
   .timeline-content2left{
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 20px;
        text-align: left;
   }
   .Footer3{
        font-family: 'Roboto Slab', serif;
        padding-left: 15%;
        padding-right: 15%;
        padding-top: 3% !important;
        vertical-align: top;
        background: black;
    }
     .f-div-item2{
        font-size: 1.2rem;
        font-weight: 400;
        padding-top: 5%;
    }

    .headerImage{
        width: 70%;
    }

    .signup_modal{
        width: 90vw
    }
}

#arrow {
    position: relative;
    top: 0;
    transition: top ease 0.25s;
  }
#arrow:hover {
    top: -2px;
    text-decoration: underline;
    transform: scale(1.1)
}
#arrow i:hover {
    transform: scale(1.1)
}
.NumberProof{
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 10%;
    -webkit-column-gap: 10%;
            column-gap: 10%;
    height: 25vh;
    border-top: 0.25px solid rgb(31, 31, 31);
    border-bottom: 0.25px solid rgb(31, 31, 31);
}
.NP-title{
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-column-gap: 10%;
    -webkit-column-gap: 10%;
            column-gap: 10%;
}
.NP-div{
    opacity: 1;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    grid-column-gap: 7%;
    -webkit-column-gap: 7%;
            column-gap: 7%;
}

.pff {
    /* style={{height: 100, width: 180}} */
    height: 100px;
    width: 180px;
}

.sportstechie {
    /* style={{height: 100, width: 200}} */
    height: 100px;
    width: 200px;
}

.techstars {
    /* style={{height: 100, width: 200}} */
    height: 45px;
    width: 195px;
}

@media screen and (max-width: 768px) {
    .NumberProof{
        background-color: black;
        padding: 25px;
        text-align: center;
        height: 100%;
    }

    .pff {
        /* style={{height: 100, width: 180}} */
        height: 80px;
        width: 144px;
    }

    .sportstechie {
        /* style={{height: 100, width: 200}} */
        height: 80px;
        width: 160px;
    }

    .techstars {
        /* style={{height: 100, width: 200}} */
        height: 36px;
        width: 156px;
    }
    .NP-title{
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 50px;
        opacity: .8;
        width: 90%;
        margin-left: 5%;
    }
    .NP-div{
        opacity: 1;
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
    }
    .NP-number{
        font-size: 2.25rem;
        font-weight: 600;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 40px;
        display: inline-block;
    }
    .NP-span{
        font-size: 1rem;
        font-weight: 400;
        margin-left: 10px;
    }
    .NP-hr{
        width: 15%;
        background-color: black;
        opacity: .8;
        margin-left: 1%;
        padding: .5px;
    }
}
.Reviews2{
    background-color: black;
    padding: 20px;
    font-family: 'Lato', sans-serif !important;
    line-height: 1.6;
    color: #3d3d3d;
}
.rev-title{
    font-family: 'Lato', sans-serif !important;
    font-size: 2em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    font-weight: 700;
    line-height: 1.3;
    color: white;
    text-align: center;
    margin-bottom: 60px;
    width: 100%;
}

.rev-logos{
    margin-left: 7%;
    margin-right: 7%;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    max-width: 100%;
}

.rev-logo-div{
    width:  20%;
    display: inline-block;
    text-align: center;
}
.rev-logo{
    height: 34px;
}
.review-card{
    background-color: white !important;
    margin-left: 25%;
    margin-right: 20%;
    width: 50%!important;
    border-radius: .5em;
    padding: 35px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 35px;
}
.review-img{
    height: 50px;
    margin-bottom: 15px;
}
.review-body{
    font-weight: 400;
    margin-bottom: 15px;
}
.review-author{
    color: gray;
    font-weight: 400;
    font-size: .9em;
    margin-bottom: 15px;
}
.review-link{
    font-size: .8em;
    font-weight: 600!important;
}
.custom-dot-list-style{
    color: gold;
}

@media screen and (max-width: 768px) {
    .rev-logo-div{
        width:  50%;
        display: inline-block;
        text-align: center;
    }
    .rev-logo{
        max-height: 60px;
        margin-bottom: 20px;
        -webkit-filter: grayscale(1);
                filter: grayscale(1);
    }

    .review-card{
        background-color: white !important;
        margin-left: 2%;
        margin-right: 20%;
        width: 96%!important;
        border-radius: .5em;
        padding: 15px;
        text-align: left;
        margin-top: 50px;
        margin-bottom: 35px;
    }
    .rev-title{
        font-size: 1rem;
    }
}
.Questions{
    background-color: black;
    padding: 50px;
    padding-bottom: 25px;
    text-align: center;
    font-family: 'Lato', sans-serif;
}
.q-title{
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 30px;
    color: white;
}
.q-body{
    line-height: 1.5;
    margin-bottom: 50px;
    width: 40%;
    margin-left: 30%;
    color: white;
}
.q-btn-div{
    background-color: black;
}
.q-btn-gold{
     padding: 15px 20px;
    border-radius: .4em;
    background-color: #e0cb07;
    color: black;
    font-family: 'Lato', sans-serif !important;
    text-align: center;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    border: none;
    outline: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 0px;
    margin-right: 3%;
}
.q-btn-gold:hover{
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    margin-bottom: 0px;
    -webkit-filter: grayscale(.3);
            filter: grayscale(.3);
}


.q-btn-white{
     padding: 15px 20px;
    border-radius: .4em;
    background-color: white;
    border: 1px solid black;
    color: black;
    font-family: 'Lato', sans-serif !important;
    text-align: center;
    font-size: 14px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    outline: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 0px;
    margin-left: 3%;
}
.q-btn-white:hover{
    margin-top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 25px;
    margin-bottom: 0px;
}
.q-btn-gold:hover .q-btn-white{

}
.q-btn-white:hover .q-btn-gold{

}

@media screen and (max-width: 768px) {
    .q-btn-gold{
        display: inline-block;
        margin-right: 0px;
        width: 90%;
    }
    .q-btn-white{
        display: inline-block;
        margin-left: 0px;
        width: 90%;
        margin-top: 10px;
    }
    .q-body{
        width: 100%;
        margin-left: 0%;
    }
    .q-btn-gold:hover{
        font-size: 16px;
    }
    .q-btn-white:hover{
        font-size: 16px;
        margin-top: 10px
    }
}
.news-container {
    height: 20rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-box {
    height: 55%;
    width: 50rem;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 740px) {
    .news-box {
        width: 90%;
        height: 80%;
        display: flex;
        flex-direction: column;
    }
    
    .input-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .news-left {
        width: 90%;
        height: 30% !important;
        margin-top: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-right {
        width: 90%;
        height: 70% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .input-box {
        width: 90%;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }
    .news-input {
        height: 3rem !important;
        width: 100% !important; 
    }
    .news-title {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-body {
        font-size: 1.1rem !important;
        font-weight: 600;
        color: gray !important;
        margin-bottom: 20px;
        text-align: center;
    }
    .news-main {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-head {
        font-size: 2rem !important;
        font-weight: 600;
        color: #000;
        margin-bottom: 20px;
    }
    .news-container {
        height: 30rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-button {
        width: 100% !important;
        height: 3rem !important
    }
}

@media screen and (max-width: 859px) {
    .news-box {
        width: 90%;
    }
}


@media screen and (max-width: 425px) {
    .news-box {
        width: 90%;
        height: 80%;
        display: flex;
        flex-direction: column;
    }
    
    .input-box {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .news-left {
        width: 90%;
        height: 30% !important;
        margin-top: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-right {
        width: 90%;
        height: 70% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .input-box {
        width: 90%;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column !important;
    }
    .news-input {
        height: 3rem !important;
        width: 100% !important; 
    }
    .news-title {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-body {
        font-size: 1.1rem !important;
        font-weight: 600;
        color: gray !important;
        margin-bottom: 20px;
        text-align: center;
    }
    .news-main {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }
    .news-head {
        font-size: 1.5rem !important;
        font-weight: 600;
        color: #000;
        margin-bottom: 20px;
    }
    .news-container {
        height: 30rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .news-button {
        width: 100% !important;
        height: 3rem !important
    }
}

.news-left {
    min-width: 40%;

    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news-title {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-left: 20px;
}

.news-main {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
}


.news-head {
    font-size: 1.7rem;
    font-weight: 600;
    color: black;
}

.news-body {
    font-size: 1.6vh;
    font-weight: 400;
    color: gray;
    align-self: flex-start;
    display: flex;
}

.news-right {
    width: 80%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.news-input {
    width: 55%;
    height: 30%;
    border: 1px solid rgba(0,0,0,0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.input-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.news-button {
    background-color: #c4b454;
    border-radius: 5px;
    height: 30%;
    border: none;
    width: 30%;
}
.TeammateList{
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 12vh;
}    

.TeammateList-Header{
    font-size: 1.5rem;
    font-weight: 600;
    display: inline-block;
    width: 80%;

}

.TeammateList-SubHeader{
    font-size: 1.25rem;
    font-weight: 300;
    color: gray;
    margin-top: 25px;
}
.Teammate{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 10px;
    /*border: 1px solid rgb(240,240,240);*/
    padding: 10px;
    border-radius: .5em;
    background-color: white;
    color: black;
}
.teammate-left{
    font-weight: 500;
    color: black;
}
.teammate-right{
    text-align: right;
}
.teammate-value{
    font-weight: 200;
    display: inline-block;
}
.teammate-percent{
    font-weight: 500;
    display: inline-block;
}
.greentxt{
    color: green;
}
.redtxt{
    color: red;
}
.graytxt{
    color: gray;
}
.Teammate-TimeSorter{
    vertical-align: bottom;
    display: inline-block;
    width: 20%;
    text-align: right;
}
.Teammate-TimeSorter-Display{
    font-size: 1.2em;
    font-weight: 300;
    margin-right: 5px;
    color: gray;
    
}
.Teammate-TimeSorter-Drop{

}
.Teammate-TimeSorter-Div{
    background-color: white;
    padding: 3px;
    margin-right: 5px;
    border-radius: .3rem;
    border: 1px solid gray;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 20px 80px 30px;
    padding-top: 15px;
}
.Teammate-TimeSorter-Item{
    display: block;
    width: 25vw;
    margin-bottom: 15px;
    margin-right: 5px;
    margin-left: 5px;
    right: 5;
    border-radius: .3rem;
    border: none;
    outline: none;
    font-size: 1.3em;
    background-color: #c5b358;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 20px 10px;
}
.Teammate-TimeSorter-Item:hover{
    background-color: #e0cb07;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 10px 40px 20px;
}
.TeammateRequests{
    background-color: white;    
    margin: 0%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border-radius: .3rem;
    margin-top: 5px;
    margin-bottom: 5px;
    color: gray;
    font-weight: 300;
}
.TeammateRequest-Message{
    display: inline-block;
    width: 90%;
}
.Request-Popup{
    background-color: rgb(245,245,245);
    min-width: 90vw;
    min-height: 50vh;
    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.8) 0px 40px 180px 70px;
    border-radius: .3rem;
    padding: 20px;
}
.Request-Popup-Header{
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
}
.Toggle{
    width: 100%;
    background-color: white;
    margin-top: 5px;
    padding: 6px;
    border-radius: .3rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.Toggle-name{
    display: inline-block;
    width: 60%;
    text-align: left;
    font-size: 1.2rem;
    
}

.Toggle-btns-div{
    display: inline-block;
    width: 40%;
}

.Toggle-btn{
    display: inline-block;
    width: 35%;
    margin: 2.5%;
    color: white;
    font-size: .8rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    padding: 5px;
}
.TeammateProfile{
    padding: 10px;
    padding-top: 0px;
    background-color: #fffafa;
    
}
@media only screen and (min-width: 1024px) {
    .TeammateProfile{
        padding-left: 25%;
        padding-right: 25%;
    }
}
.TeammateProfile-User{
    display: inline-block;
    width: 65%;
    padding-left: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.TeammateProfile-DisplayName{
    text-align: left;
    font-size: 2rem;
    font-weight: 600;

    text-overflow: ellipsis;
}
.TeammateProfile-Username{
    text-align: left;
    font-size: 1rem;
    color: gray;
}
.TeammateProfile-Member{
    text-align: right;
    display: inline-block;
    width: 35%;
    padding-right: 5px;
    vertical-align: top;
}
.TeammateProfile-Member-Color{
    font-weight: 600;
    font-size: 2rem;
}
.TeammateProfile-Member-Follow{

}
.TeammateProfile-Member-Follow-Btn{
    border: 1px solid #e0cb07;
    border-radius: 1em;
    outline: none;
    color: white;
    
    background-color: rgb(180,180,180);
    padding: 3px 20px 3px 20px; 
    margin-right: 1px;
    font-weight: 500;
}
.TeammateProfile-Member-Following-Btn{
    border: 1px solid #e0cb07;
    border-radius: 1em;
    outline: none;
    background-color: #fffafa;
    padding: 3px 20px 3px 20px; 
    margin-right: 1px;
    font-weight: 500;
}

.ProfileShow{
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
}
.profile-show-btn{
    width: 33%;
    border: 1px solid #e0cb07;
    outline: none;
    background-color: #fffafa;
    color: #c5b358;
}
.psb-select{
    background-color: #e0cb07;
    color: white;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}

.TeammateFeed{
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 12vh;
}
.TeammateFeed-Header{
    font-size: 1.5rem;
    font-weight: 600;
}

.activity-shadow{
    border: none;
    outline: none;
    border-radius: .3em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
}
.teammate-item-card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 10px;
    border: 1px solid rgb(240,240,240);
    padding: 10px;
    border-radius: .5em;    
    background-color: white;

}
.tic-user{
    font-size: 1.1rem;
    font-weight: 500;
}
.tic-body{
    padding: 3px 3px 3px 3px;
}
.tic-date{
    font-size: .75em;
    color: gray;
    text-align: right;
}
.TeammateSearch{
    background-color: #fffafa;
    vertical-align: top;
    
}
@media only screen and (min-width: 1024px) {
    .TeammateSearch{
        padding-bottom: 1vh;
    }
}

.Search-Div{
    vertical-align: top;
    background-color: white;
    margin: 2%;
    width: 96%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border-radius: .3rem;
    margin-top: 20px;
}

.Search-Header{
    font-size: 1.5rem;
    font-weight: 600;
    display: inline-block;
    width: 80%;

}

.Search-Input-Div{
    text-align: left;
    margin-bottom: 20px;
}
.Search-Input{
    outline: none;
    border: none;
    width: 84%;
    display:inline-block;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
    font-size: 1.25rem;
    padding: 5px;
    text-align: center;
    font-weight: 300;
}
.Search-btn{
    width: 15%;
    display: inline-block;
    border-radius: 1rem;
    margin-left: 1%;
    outline: none;
    border: none;
    background-color: white;
    font-size: 1.5rem;
    padding: .5px;
    font-weight: 200;
    color: #e0cb07;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}
.Search-btn:hover{
    width: 15%;
    display: inline-block;
    border-radius: 1rem;
    margin-left: 1%;
    outline: none;
    border: none;
    background-color: #c5b358;
    font-weight: 200;
    color: white;
    font-size: 1.25rem;
    padding: 5px;
    box-shadow: none;
    background-color: #e0cb07;
}

.Search-Results{
    max-height: 50vh;
    overflow-y: scroll;
}
.TeammateValue{
    display: inline-block;
    width: 60%;

    padding-left: 5px;
}
.TeammateValue-Span{
    color: gray;
    font-weight: 300;
    font-size: .9em;
}
.TeammateValue-Amount{
    font-size: 1.65rem;
    font-weight: 400;
    margin-left: 0px;
    padding-left: 0px;
}
.TeammateValue-Change-Div{
    display: inline-block;
    width: 40%;
    color: gray;
    text-align: right;
    padding-right: 5px;

}
.TeammateValue-Return-Span{
    font-size: 1.5em;
    font-weight: 500;
}
.TeammateValue-caret-up{
    color: green;
    font-size: 1em;
    margin-right: 5px;
}
.TeammateValue-caret-down{
    color: red;
    font-size: 1em;
    margin-right: 5px;
}
.ProfileFeed{
    padding: 10px;
    padding-bottom: 10px;
    margin: 5px;
}
.ProfileFeed-Header{
    font-size: 1.5rem;
    font-weight: 600;
}


.Teammates{
    padding-top: 7vh;
    background-color: #fffafa;
    min-height: 100vh;
}
@media only screen and (min-width: 1024px) {
    .Teammates{
        padding-top: 2vh;
        padding-left: 25%;
        padding-right: 25%;
        
    }
}
.TeammateShow{
    text-align: center;
}
.teammate-show-btn{
    width: 33%;
    border: 1px solid #e0cb07;
    outline: none;
    background-color: #fffafa;
    color: #c5b358;
}
.tsb-select{
    background-color: #e0cb07;
    color: white;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}


.Sportle{
    text-align: center;
    vertical-align: middle;
    padding-top:7vh;
    padding-bottom:15vh;
    background-color: #fffafa;
    min-height: 100vh;
}

@media only screen and (min-width: 1024px) {
    .Sportle{
        text-align: center;
        vertical-align: middle;
        padding-top:1vh;
        padding-bottom:15vh;
        background-color: #fffafa;
        min-height: 100vh;
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
}

.sportle-heading{
    width: 14.25%;
    color: gray;
    font-weight: 200;
    font-size: .75rem;
    display: inline-block;
}
.sportle-card{
    margin: 10px;
    margin-top: 2px;
    margin-bottom: 3px;
    padding: 5px;
    padding-bottom: 8px;
    background-color: white;
    border-radius: .3em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.sportle-name{
    text-align: left;
    font-size: 1.15rem;
    font-weight: 500;
}
.sportle-correct{
    background-color: #009d3d;
    width: 14.10%;
    margin-right: 0.18%;
    min-height: 6vh;
    color: white;
    font-weight: 600;
    display: inline-block;
    vertical-align: bottom;
    padding: 3px;
    padding-top: 6px;
    margin-bottom: 5px;
    font-size: .95em;
}
.sportle-incorrect{
    background-color: white;
    width: 14.10%;
    margin-right: 0.18%;
    height: 6vh;
    font-weight: 500;
    display: inline-block;
    vertical-align: bottom;
    padding: 3px;
    padding-top: 6px;
    margin-bottom: 5px;
    font-size: .95em;
    color: gray;
}
.sportle-correct div{

    vertical-align: bottom;
}
.sportle-incorrect div{

    vertical-align: bottom;
}
.Guess{
    background-color: #fffafa;
    vertical-align: top;
    text-align: center;
}
@media only screen and (min-width: 1024px) {
    .Guess{
        padding-bottom: 1vh;
    }
}

.Guess-Div{
    vertical-align: top;
    background-color: white;
    margin: 2%;
    width: 96%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 10px;
    border-radius: .3rem;
    margin-top: 10px;
}

.Guess-Header{
    font-size: 1.59rem;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
}

.Guess-Input-Div{
    text-align: left;
    margin-bottom: 5px;
}
.Guess-Input{
    outline: none;
    border: none;
    width: 84%;
    display:inline-block;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
    font-size: 1.25rem;
    padding: 5px;
    text-align: center;
    font-weight: 300;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}
.Guess-btn{
    width: 15%;
    display: inline-block;
    border-radius: 1rem;
    margin-left: 1%;
    outline: none;
    border: none;
    background-color: white;
    font-size: 1.5rem;
    padding: .5px;
    font-weight: 200;
    color: #e0cb07;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 13px 14px, rgba(0, 0, 0, 0.12) 0px -6px 15px, rgba(0, 0, 0, 0.12) 0px 2px 3px, rgba(0, 0, 0, 0.17) 0px 6px 7px, rgba(0, 0, 0, 0.09) 0px -1.5px 2.5px;
}
.Guess-btn:hover{
    width: 15%;
    display: inline-block;
    border-radius: 1rem;
    margin-left: 1%;
    outline: none;
    border: none;
    background-color: #c5b358;
    font-weight: 200;
    color: white;
    font-size: 1.25rem;
    padding: 5px;
    box-shadow: none;
    background-color: #e0cb07;
}

.Guess-Search-Player{
    width: 100%;
    
}
.Guess-Search-Player-btn{
    border: none;
    outline: none;
    background-color: white;
    width: 90%;
    margin-bottom: 3px;
    border-radius: .5em;
    font-size: 1.2rem;
    font-weight: 300;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    
}
.Guess-Search-Player-btn:hover{
    background-color: rgb(240,240,240);
    font-size: 1.25rem;
    font-weight: 400;
    
}
.Status{
    width: 80%;
    margin: 10%;
    background-color: white;
    border-radius: .7em;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    font-size: 1.1em;
}

.Status-Head{
    font-size: 1.25rem;
    text-align: center;
    font-size: 900;
}

.Status-Table{
    width: 100%;
}
.Status-Row{
    width: 100%;
}
.Status-Left{
    text-align: left;
    padding-left: 15px;
    font-weight: 300;
}
.Status-Right{
    text-align: right;
    padding-right: 15px;
    font-size: 1.1rem;
    font-weight: 600;
}
.rewards-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.container-box {
    width: 350px;
    background-color: #424242;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    border-radius: 15px;
}

.container-title-box {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-title {
    font-size: 1.32rem;
    color: white;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.container-title-section {
    width: 80%;
    height: 18%;
    display: flex;
    flex-direction: column;
    /* padding-left: 2.5rem; */
    justify-content: center;
    /* padding-right: 2.5rem; */
}

.section-title {
    font-size: 1rem;
    color: white;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.section-body {
    font-size: .85rem;
    color: white;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
}

.sharebtn {
    width: 70%;
    height: 7%;
    background-color: #c4b454;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 1rem;
    border-radius: 20px;
    outline: none !important;
    border: none !important;
    
}

.refer-code {
    height: 16%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.refer-box {
    height: 55%;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.refer-left {
    width: 45%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.refer-right {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.txtbox {
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-box {
    height: 90%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 10px;
}

.copy-box:hover {
    cursor: pointer;
    border-color: #c4b454;
}

.sharebtn:hover {
    cursor: pointer;
    background-color: #a99b48;
}
