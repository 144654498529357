.Analysis{
    padding: 3%;
    padding-top: 5vh;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 20%;
    color: black !important;
}
.anal-title{
    font-size: 1rem;
    font-weight: 900;
}
.anal-title-2{
    font-size: 2rem;
    font-weight: 900;
}
.anal-sub{
    font-size: .75rem;
    font-weight: 400;
}
.blk-text{
    color: black !important;
}

/*Two Analysis*/
.TwoAnalysis{
    padding-top: 6vh;
    background-color: rgb(249,249,249);
    min-height: 100vh;
    padding-bottom: 10vh;
}
.TA-Indexes{
    overflow-y: scroll;
    white-space: nowrap;
}
.TA-IndexCard{
    width: 31%;
    font-size: .8rem;
    margin: 3%;
    display: inline-block;
    border-right: 1px solid lightgray;
    margin-right: 1.5%;
    padding-right: 1.5%;
}
.TA-IC-Level{

}
.TA-IC-Left{
    display: inline-block;
    width: 60%;
}
.TA-IC-Right{
    display: inline-block;
    width: 35%;
    text-align: right;
}
.taic-name{
    font-weight: 600;
    font-size: 1rem;
}
.taic-total{
    font-weight: 300;
}
.taic-line{
    display: inline-block;
    /*font-weight: 200;
    font-size: 3rem;
    color: gray;*/
    border-left: 1px solid black;
    height: 100%;
}
.red-text{
    color: red;
}
.TA-Game-Title{
    margin-left: 5%;
    font-weight: 500;
    background-color: rgb(249,249,249);
    border: none;
}
.TAGT-on{
    font-weight: 600;

}
.TAGT-off{
    font-weight: 400;
    color: gray;
    font-size: .9rem;
}
.TA-Game-hr{
    padding: 0%;
    margin: 0%;
    margin-left: 4%;
    margin-bottom: 1%;
}
.TA-Games{
    overflow-y: scroll;
    white-space: nowrap;
}
.TA-GameCard{
    background-color: white;
    width: 29.5%;
    padding: 1% 3%;
    margin-left: 1%;
    display: inline-block;
}
.TA-GC-Innings{
    font-size: .7rem;
    color: gray;
    text-align: left;
}
.TA-GC-Team{
    font-weight: 600;
}
.TA-GC-TeamName{
    display: inline-block;
    width: 60%;
}
.TA-GC-TeamScore{
    display: inline-block;
    width: 40%;
    text-align: right;
}
.TA-Analysis{
    background-color: white;
    margin: 1%;

}
.TA-A-Title{
    font-size: 1.5rem;
    color: gray;
    font-weight: 300;
    font-style: italic;
    padding: 2%;
}
.TA-A-Card{
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
}
.TA-A-hr{
    padding: 1%;
    margin: 0%;
    padding-top: 1%;
    padding-bottom: 1%;
}
.TA-A-btn{
    width: 40%;
    border-radius: 1rem;
    margin-bottom: 3%;
    background-color: white;
    border: none;
    font-style: italic;
}

.TA-News{
    margin-top: 5%!important;
}

.TA-Tables{
    overflow-y: scroll;
    white-space: nowrap;
}

.TA-BiggestMovers{
    background-color: white;
    margin: 0%;
    padding: 2% 8% 2% 8%;
    width: 100%;
    display: inline-block;
}
.TA-BM-Title{
    font-size: 1.35rem;
    font-weight: 600;
}
.TA-BM-hr{
    width: 42%;
    padding: 1%;
    margin: 1%;
}
.green-text{
    color: green
}
.red-text{
    color: red
}
.TA-BM-Table{
    padding-left: 1%;
    padding-right: 1%;
}
.TA-BM-T-changecolumn{
    text-align: center;
    width: 25%;
    display: inline-block;
}
.TA-BM-T-pricecolumn{
    text-align: center;
    width: 25%;
    display: inline-block;
}
.TA-BM-T-teamcolumn{
    width: 50%;
    display: inline-block;
}
.bold-text{
    font-weight: 800;
}
.TA-BM-T-hr{
    margin-top: 0%;
    margin-bottom: 2%;
}
.TA-BM-btn-div{
    overflow-y: scroll;
    white-space: nowrap;
    margin-bottom: 3%;
    padding-bottom: 3%;
}
.TA-BM-btn-div-league{
    overflow-y: scroll;
    white-space: nowrap;
    margin-bottom: 3%;
}
.TA-BM-btn{
    font-size: .75rem;
    border-radius: 1rem;
    margin-right: 1%;
    background-color: white;
    border: none;
    color: black;
}
.TA-BM-btn-league{
    font-size: .9rem;
    border-radius: .1rem;
    margin-right: 0%;
    background-color: white;
    border: 1px solid #c5b358;
}
.TA-BM-btn-league-2{
    font-size: .9rem;
    border-radius: 0rem;
    margin-right: 0%;
    background-color: #c5b358;
    border: 1px solid #c5b358;
    color: white;
}
.tabm-selected{
    background-color: red;
    color: white;
}
.tabm-selected-gain{
    background-color: green;
    color: white;
}
.tabm-selected-gain-league{
    background-color: #c5b358;
    color: black;
    font-size: 1.3rem
}
.tabm-selected-gain-league-2{
    background-color: white;
    color: black;
    font-weight: 500;
    font-size: 1.3rem;
    border: none;
    border-top: 1px solid #c5b358;
    border-right: 1px solid #c5b358;
    border-left: 1px solid #c5b358;
}
.tabm-selected-traded{
    background-color: gray;
    color: white;
}
.gold-border{
    border: 1px solid #c5b358;
    border-top: none;
    border: none;
}

/*Polls*/
.TA-Polls{
    
    margin: 1%;
}
.TA-P-Title{
    font-size: 1.5rem;
    color: gray;
    font-weight: 300;
    font-style: italic;
    padding: 2%;
    padding-left: 10%;
}
.TA-P-Container{
    margin-bottom: 3%;
    padding-bottom: 3%;
}
.TA-P-Item{
    display: inline-block;
    margin-right: 1%;
    width: 100%;
    padding: 2%;
}
.TAPI-Question{
    font-size: 1.25rem;
    width: 39%;
    display: inline-block;
    font-weight: 600;
    vertical-align: top;
    padding-top: 1%;
    color: gray;
}
.TAPI-Results{
    width: 59%;
    display: inline-block;
    text-align: center;
}
.TAPI-R-Left{
    width: 50%;
    display: inline-block;
    color: white;
    height: 100%;
    
}
.TAPI-R-Right{
    width: 50%;
    display: inline-block;
    color: white;
    height: 100%;
}
.TAPI-R-Option{
    font-size: .8rem;
    color: black;
    background-color: white;
}
.TAPI-R-Result{
    font-size: 1.15rem;
    font-weight: 800;
    border-radius: 1rem;
}
.TAPI-R-Votes{
    font-size: .8rem;
}
.TA-P-hr{
    height: 2px;
    margin: 2%;
}
.gray-text{
    color: gray;
}

/* Make it a marquee */
.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    background-color: black;
}
.marqueDesktop{
    display: none;
}

.marquee span {
display: inline-block;
padding-left: 1%;
animation: marquee 180s linear infinite;
}
.marqueeDesktop span {
    display: none;
    padding-left: 1%;
    animation: marqueeDesktop 180s linear infinite;
}
.marquee-lineDesktop{
    background-color: #c5b358;
    margin: 1% 0% 0% 0%;
    padding: 0%;
    display: none;
    }

/* Make it move */
@keyframes marquee {
0% {
    transform: translate(0, 0);
}
100% {
    transform: translate(-100%, 0);
}
}
.marquee-text{
color:  #c5b358;
padding-top: 1%;
}
.marquee-line{
background-color: #c5b358;
margin: 1% 0% 0% 0%;
padding: 0%;
}

/* Article */
.Article{
    padding: 3%;
    padding-top: 6vh;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 20%;
    color: black !important;
}


/*SimCFB */
.conferenceDiv{
    padding: 3%;
    margin-left: 20%;
}
.conferenceTitle{
    font-size: 1.2rem;
    font-weight: 600;
}

@media only screen and (min-width: 1024px) {
    .TwoAnalysis{
        padding-top: 0%;
    }
    .DesktopContainer{
        width: 100%;
        padding-left: 25%;
        padding-right: 25%;
    }
    .anal-title{
        font-size: 1.5rem;
    }
    .anal-title-2{
        font-size: 2.25rem;
    }
    .anal-sub{
        font-size: 1.25rem;
    }
    .marqueeDesktop{
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        background-color: black;
    }
    /* Make it move */
    @keyframes marqueeDesktop {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(-100%, 0);
        }
    }
    .marqueeDesktop span {
        display: inline-block;
        padding-left: 1%;
        animation: marqueeDesktop 210s linear infinite;
        padding-bottom: 1%;
    }
    .marquee{
        display: none;
    }
    .marquee-lineDesktop{
        background-color: #c5b358;
        margin: 1% 0% 0% 0%;
        padding: 0%;
        display: inline;
        }
    .TA-IndexCard{
        width: 12.25%;
        font-size: .8rem;
        margin: 1%;
        margin-bottom: 0%;
        display: inline-block;
    }
    .TA-GameCard{
        background-color: white;
        width: 13.85%;
        padding: 1% 3%;
        margin-left: 1%;
        display: inline-block;
    }
    .TA-BiggestMovers{
        background-color: white;
        margin: 0%;
        padding: 2% 8% 2% 8%;
        width: 50%;
        display: inline-block;
    }
    .TA-News{
        margin-top: 0%!important;
    }
    /*SimCFB */
    .conferenceDiv{
        padding: 3%;
        display: inline-block;
        width: 80%;
        margin-left: 0%;
        vertical-align: top;
    }
    .conferenceTitle{
        font-size: 1.2rem;
        font-weight: 600;
    }
}