.Ticker{
    padding-top: 6vh;
}
.trade-teamcard{
    display: inline-block;
    background-color: white;
    margin: .5%;
    margin-left: 2%;
    margin-right: 0.5%;
    border-radius: .4rem;
    min-width: 20%;
    max-width: 31%;
    min-height: 10vh;
    display: inline-block;
    text-align: left;
    font-size: 0.9rem;
    -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
    padding-top: 2%;
    text-align: 'center';
    padding-left: 4px;
    overflow-y: hidden;
}
.trade-card{
    font-size: 0.7rem;
    color: darkred;
    font-weight: 300;
}
.trade-header-left{
    display: inline-block;
    width: 70%;
    font-weight: 600;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 1.1em
}
.trade-header-right{
    text-align: right;
    display: inline-block;
    font-weight: 300;
    padding-bottom: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    font-size: 0.9em;
    color: gray
}
.green{
    color: green;
}
.red{
    color: #ff7d7d;
}
.trade-card-type{
    display: inline-block;
    margin-right: 0%;
    width: 44%;
    padding-left: 20px;
}
.trade-card-team{
    display: inline-block;
}
.trade-card-price{
    display: inline-block;
    width: 56%;
    text-align: right;
    padding-right: 20px
}

.Search{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    background-color: rgb(244,244,244);
    z-index: 1;
    padding-bottom: 9vh;
}

.LeagueFilter{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1%;
    background-color: rgb(244,244,244);
    z-index: 1;
    padding-bottom: 9vh;
}
.leagueFilter-btn{
    
    margin-left: 2.6%;
    border: none;
    outline: none;
    color: gray;
    margin-bottom: 5px;
    background-color: black;
    font-size: 1.1em
}
.leagueFilter-btn-selected{
    
    margin-left: 2.6%;
    border: none;
    border-bottom: 2px #c5b358 solid;
    outline: none;
    font-weight: 600;
    color: #c5b358;
    margin-bottom: 5px;
    background-color: black;
    font-size: 1.1em;
    
}

.allTrades-img{
    width: 100%;
    margin-top: 7px
}

.full-name{
    width: 38.5%;
    padding-left: 1%;
    display: inline-block;
    font-size: 1.08em;
    font-weight: 400;
}
.full-price{
    text-align: center;
    width: 36.5%;
    display: inline-block;
    font-weight: 300;
    font-size: 1em;
}
.full-percent{
    text-align: right;
    width: 19%;
    padding-right: 2px;
    display: inline-block;
    font-size: 1.06em;
    font-weight: 300;
}
.full-span{
    font-size: .75em;
    color: gray;
    font-weight: 200;
    display: inline-block;
}
.full-img{
    width: 100%;
}

@media only screen and (min-width: 800px) {
.DesktopContainer{
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;
  }
}

@media only screen and (min-width: 1024px) {
    .Ticker{
        padding-top: 0vh;
    }
  .DesktopContainer{
      width: 100%;
      padding-left: 20%;
      padding-right: 20%;
  }
  .Search{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1%;
        background-color: rgb(244,244,244);
        z-index: 1;
        padding-bottom: 9vh;
    }
    .LeagueFilter{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1%;
        background-color: rgb(244,244,244);
        z-index: 1;
        padding-bottom: 3vh;
        padding-left: 25%;
        padding-right: 25%;
    }
    .trade-teamcard{
        max-width: 26%
    }
    .allTrades-img{
        width: 50%;
        margin-top: 7px;
        margin-left: 25%;
    }
    .full-name{
        width: 50%;
        padding-left: 1%;
        display: inline-block;
        font-size: 1.1em;
        font-weight: 400;
    }
    .full-price{
        text-align: center;
        width: 25%;
        display: inline-block;
        font-weight: 300;
        font-size: 1em;
    }
    .full-percent{
        text-align: right;
        width: 15%;
        padding-right: 5px;
        display: inline-block;
        font-size: 1.07em;
        font-weight: 300;
    }
    .full-span{
        font-size: .75em;
        color: gray;
        font-weight: 200;
        display: inline-block;
    }
    .full-img{
        width: 70%;
    }
}