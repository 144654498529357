.Friends {
    font-family: 'Roboto Slab', serif;
    background-color: rgb(245, 245, 245);
    padding-bottom: 5%;
    min-height: 100vh;
    padding-bottom: 10vh;
}
.F-Title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 5%;
  color: rgb(245, 245, 245);
  display: none;
}
.F-Card {
  width: 98%;
  background-color: white;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  color: black;
}
.cards-teammates{
  font-size: 1.7rem;
  margin-left: 5%;
}
.FC-user {
  display: inline-block;
  width: 98%;
  padding-left: 2%;
  font-size: 1.5rem;
  color: black;
}
.FC-user-img{
  width: 10%;
  height: 15%;
}
.FC-user-span{
  padding-left: 3%;
}
.FC-amount {
  display: inline-block;
  width: 38%;
  text-align: right;
  padding-right: 2%;
  font-weight: 200;
}
/* Cards Extended*/
.Friends-Extended {
  background: #fff;
  overflow: hidden;
  color: #000;
  line-height: 50px;

  transition: all 0.5s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0);
}
.Friends-Extended:target {
  transform: scaleY(1);
}
.FC-E-user {
  font-size: 1.75rem;
  text-align: center;
  font-weight: 600;
  padding-bottom: 4%;
  color: black;
  display: inline-block;
  width: 95%;
}
.FC-E-edit{
  display: inline-block;
  width: 5%;
  font-size: 1.75rem;
  color: gray;
}

.FC-E-hr {
  width: 85%;
  opacity: 0.5;
  padding: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}
.FC-E-items {
  padding-top: 4%;
  padding-bottom: 4%;
}
.FC-E-item-name {
  width: 57%;
  display: inline-block;
  padding-left: 3%;
  text-align: left;
  font-size: 1rem;
}
.FC-E-item-amount {
  width: 37%;
  display: inline-block;
  padding-right: 3%;
  text-align: right;
  font-size: 1.1rem;
  font-weight: 800;
}
.FC-E-item-returnBTN{
  border-radius: 1rem;
  margin-left: 2%;
  padding-top: 0%;
  padding-bottom: 0%;
  padding-left: 7%;
  padding-right: 7%;
  color: #c5b358;
}
.fa-caret-down{
  font-size: 1.4rem;
}
.FC-E-return-div{
  width: 50%;
  margin-left: 0%;
}
.FC-E-item-return-item {
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 1.2rem;
  opacity: 1;
  border-radius: 1rem;
  border: 1px solid #c5b358;
  font-weight: 800;
}
.block {
  display: inline-block;
}
.FC-E-Overlay{
  background-color: red;
}
.FC-E-item-full {
  text-align: center;
  font-weight: 200;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.2rem;
}
/*Shares */
.FC-S-TC-Div{
  vertical-align: middle;
  background-color: white;
}
.FC-S-TC-Div-Left{
  display: inline-block;
  width: 10%;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  height: 100%;
  vertical-align: top;
  margin-top: 5%;
  
}
.FC-S-TC-Div-Right{
  display: inline-block;
  width: 90%;
}
.FC-S-TC-hr{
  padding: 0%;
  margin: 0%;
}
.buySell{
  transform: rotate(90deg);
}

/* Add Friend */
.FC-Add {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-top: 5%;
  margin-bottom: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}
.AddFriend {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-top: -2%;
  margin-bottom: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}
.AF-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 10%;
  font-weight: 400;
}
.AF-Label {
  display: inline-block;
  padding-bottom: 3%;
  font-weight: 800;
  font-size: 1.5rem;
}
.AF-Input {
  text-align: center;
  padding:3%;
  padding-left: 3%;
  width: 70%;
  margin-left: 3%;
  border-radius: 1rem;
  border: 2px solid #c5b358;
  font-size: 1.5rem;

}
.AF-Input-Btn{
  margin-top: 2%;
  border: 2px solid #c5b358;
  color: #c5b358;
  background-color: white;
  font-size: 1.5rem;
  border-radius: 12rem;
  padding: 1% 10% 1% 10%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
}
.AF-Check-title{
  text-align: left;
  font-size: 1.1rem;
  padding-left: 5%;
}
.AF-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check {
  font-size: 2rem;
  border: 2px solid #c5b358;
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.75); /* IE */
  -moz-transform: scale(1.75); /* FF */
  -webkit-transform: scale(1.75); /* Safari and Chrome */
  -o-transform: scale(1.75); /* Opera */
  transform: scale(1.75);
  padding: 10px;
}

.AF-Check-Type {
  padding-left: 3%;
  padding-bottom: 2%;
  margin-left: 3.5%;
  font-size: 1.1rem;
  font-weight: 600;
}
.AF-Check-Desc {
  font-size: 0.8rem;
  font-weight: 200;
  padding-bottom: 2%;
}
.AF-hr {
  width: 70%;
  opacity: 0.5;
  padding: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}
.AF-btn {
  font-size: 1.1rem;
  padding: 2% 7% 2% 7%;
  width: 80%;
  border-radius: 0.75rem;
  border: 2px solid #c5b358;
  font-weight: 800;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* Requests */
.FR-Extended {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-bottom: 5%;
  margin-top: -6%;
  padding-top: 0%;
  padding-bottom: 3%;
  text-align: center;
}
.FR-E-Card {
  text-align: left;
  padding-left: 3%;
  padding-right: 3%;
}
.FR {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: white;
  margin-top: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}
.FR-hr-top {
  margin-left: 3%;
  margin-right: 3%;
}
.FR-E-Card-name {
  display: inline-block;
  width: 100%;
  font-size: 1.5rem;
  color: black;
}
.FR-E-C-E-name {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
}
.FR-E-C-E-Share {
  padding: 5%;
  background-color: rgb(240,240,240);
  border-radius: 0.8rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  margin-bottom: 5%;
}
.FR-E-C-E-S-title {
  font-size: 0.9rem;
  text-align: left;
}
.FR-E-C-E-S-items {
  padding-left: 3%;
}
.FR-E-C-E-S-item {
  font-size: 0.8rem;
  font-style: italic;
}
.itemShared {
  color: green;
}
.itemNotShares {
  color: red;
  text-decoration: line-through;
}
.bthSpace{
  font-size: 1.1rem;
  padding: 2% 7% 2% 7%;
  width: 80%;
  border-radius: 0.75rem;
  border: 2px solid #c5b358;
  font-weight: 800;
  margin-top: 2%;
  background-color: #c5b358;
  margin-bottom: 3%;
  border: 2px black solid;
  font-weight: 700;
  font-size: 1.2rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  
}
.bthDecline{
  padding: 2% 7% 2% 7%;
  border-radius: 0.75rem;
  font-weight: 400;
  border: 1px rgb(245, 245, 245) solid;
  font-size: .9rem;
  width: 70%;
}

/* Other */
.green{
  color: green;
}
.red{
  color: red;
}

/* WhatAre */
.WhatAre{
  background-color: white;
  margin: 3%;
}
.WA-Title{
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  padding: 5%;
  padding-bottom: 1%;
}
.WA-Body1{
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.2rem;
  font-style: italic;
  padding-bottom: 5%;
  text-align: center;
}
.WA-Body2{
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 1.1rem;
  text-align: left;
}
.WA-Body2-middle{
  text-align: center;
  font-size: .8rem;
}
.WA-hr{
  margin: 10% 10% 3% 10%;
}
.WA-Body3{
  padding-top: 0%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 5%;
}
.WA-Body3-li{
  margin-left: 5%;
}
.WA-Body2-img{
  width: 15%;
  display: inline-block;
  vertical-align: top;
  padding-top: 2%;
}
.WA-Body2-words{
  width: 85%;
  padding-left: 3%;
  display: inline-block;
}

/* Create Username */
.CU-Div{
  background-color: white;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  padding: 1%;
  margin-top: -3%;
  padding-bottom: 5%;
}
.CU-Input{
  border-radius: 1rem;
  text-align: center;
  width: 75%;
  margin-bottom: 2%;
  font-size: 2rem;
  border: 2px #c5b358 solid;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.CU-Btn{
  border-radius: 1rem;
  padding: 2% 10% 2% 10%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  font-weight: 600;
}

.bottom5{
  margin-bottom: 5%;
}
.whiteBack{
  background-color: white;
  padding: 1%;
  margin: 1%;
  font-size: 1.2rem;
  font-weight: 200;
}

/* Edit */
.EditFriendHodler{
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(230,230,230);
  outline: 2px black solid;
  overflow-y: auto;
}
.EditFriend{
  top: 0;
  left: 0;
  margin-left: 5vw;
  margin-top: 5vh;
  width: 90vw;
  height: 80vh;
  background-color: white;
  outline: 1px black solid;
  box-shadow: 0px 28px 46px 0px rgba(0,0,0,0.2);
  padding: 5%;
  padding-top: 2%;
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
.Edit-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check-Type-currently{
  font-weight: 200;
  margin-left: 2%;
  font-size: .9rem;
}
.Edit-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 2%;
  font-weight: 400;
}
.Edit-Label {
  display: inline-block;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2rem;
}
.Edit-deleteBtn{
  border-radius: .8rem;
  background-color: white;
  margin-top: 2%;
  font-weight: 200;
  padding: 0;
  border: none;
  background: none;
  font-size: 0.8rem;
}
.edit-close-btn{
  text-align: right;
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}

.viewMoreBtn{
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}
.username_friends{
  border: none;
  background: none;
  background-color: rgb(245,245,245);
  width: 90%;
  margin-left: 0%;
  font-size: 1.2rem;
  margin-top: 2%;
  box-shadow: 0px 14px 23px 0px rgba(0,0,0,0.2);
}

@media screen and (min-width: 600px) {
  .Friends {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media screen and (min-width: 768px) {
  .Friends {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
  .FC-E-item-return-item {
    background-color: #f9f9f9;
    min-width: 75%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 1.2rem;
    opacity: 1;
    border-radius: 1rem;
    border: 1px solid #c5b358;
    font-weight: 800;
  }
  .FC-S-TC-Div-Right{
    display: inline-block;
    width: 90%;
  }
  .F-Title{
    color: rgb(245,245,245);
  }
 
}


/* Pop Up */
.PopUp{
  position: fixed;
  top: 0;
  left: 0;
  margin: 6vh 0vw 5vh 0vw;
  width: 100vw;
  height: 85vh;
  background-color: white;
  overflow-y: scroll;
  padding-bottom: 3vh;
}
.popup-copy{
  text-align: center;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  outline: 1px solid black;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 2rem;
}

/*TwoFriendCard*/
.TwoFriendCard{
  padding-top: 7vh;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  background-color: rgb(249,249,249);
  min-height: 100vh;
  padding-bottom: 10vh;
}
.TFC-ProfilePic{
  
}
.TFC-PP-Left{
  display: inline-block;
  width: 10%;
  vertical-align: top;
}
.TFC-PP-Center{
  display: inline-block;
  width: 78%;
}
.TFC-PP-Right{
  display: inline-block;
  width: 10%;
  vertical-align: top;
}
.TFC-Img{
  width: 10%;
  border-radius: 100rem;
  margin-left: 0%;
}
.TFC-username{
  font-size: 1.5rem;
  color: black;
}
.TFC-Gold{
  font-size: 1.30rem;
  font-weight: 200;
  color: #c5b358;
}
.TFC-member{
  font-size: .75rem;
}
.TFC-Values{
  padding-top: 0%;
  margin-top: 0%;
}
.TFC-Value{
  font-size: 2.85rem;
  font-weight: 800;
  text-shadow: 1.5px 1.5px 1.5px #c5b358;
}
.fa-caret-square-up{
  margin-right: 3%;
}
.TFC-change{
  font-size: 1.25rem;
  color: green;
  margin-left: 5%;
}
.TFC-leagues{

}
.TFC-leagues button{
  border-radius: 1rem;
  width: 24%;
  margin-left: .5%;
  margin-right: .5%;
  background-color: rgb(249,249,249);
  border: none;
  margin-top: 3%;
}
.tfc-l-selected{
  background-color: gray !important; 
  color: rgb(249, 249, 249)
}
.TFC-Payouts{
  /*background-color: white;*/
  width: 70%;
  margin-left: 15%;
  border-radius: .5rem;
  margin-top: 3%;
}
.tfc-p-title{
  font-size: 1.45rem;
  font-weight: 200;
  /*text-decoration: underline;*/
}
.tfc-p-amount{
  font-size: 1.05rem;
  font-weight: 600;
}
.TFC-Info{
  margin-top: 5%;
  font-size: 1.15rem;
}
.tfc-i-Head{
  display: inline-block;
  width: 33%;
  text-align: center;
  background-color: rgb(249,249,249);
  padding: 0%;
  border: none;
}
.tfci-hr{
  margin: 0%;
  padding: 0%;
  margin-top: 1%;
}
.tfci-trade{

}
.tfci-selected{
  background-color: #c5b358;
  height: 5px;
}
.tfci-shares{
  
}
.tfci-content{
  
}

.tfc-i-line{
  width: 0.5%;
  display: inline-block;
  font-weight: 200;
  font-size: 1.5rem
}

.TFC-Content{
  padding-top: 13%;
}
.TFC-c-btn{
  margin-top: 5%;
  border-radius: .5rem;
  background-color: #c5b358;
  padding: 3%;
  font-size: 0.85rem;
}
.TFC-edit{
  display: inline-block;
  width: 100%;
  font-size: 1.75rem;
  color: gray;
  padding-right: 3%;
}

/* Scroller */ 

.T-Scroller{
  font-size: 1.15rem;
  /*background-color: rgb(146,140,108, 0.2);*/
  background-color: rgb(245, 245, 245);
  padding-top: 6vh;
}
.T-Scroller-Head{
  display: inline-block;
  width: 50%;
  text-align: center;
  padding: 0%;
  border: none;
}
.T-Scroller-hr{
  margin: 0%;
  padding: 0%;
  margin-top: 1%;
}

.T-Scroller-selected{
  background-color: #c5b358;
  height: 5px;
}

/*Start My Card*/
.MyCard{
  padding: 3%;
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  -moz-box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  margin: 3%;
  vertical-align: top;
  font-size: 1.5rem;
  margin-top: 5%;
  margin-bottom: 5%;
  color: black;
}
.MC-img-div{
  display: inline-block;
  width: 10%;
  
}
.MC-img{
  display: inline-block;
  width: 100%;
  border-radius: 1rem;
}
.MC-username{
  display: inline-block;
  padding-left: 3%;
  width: 65%;
  text-align: left;
  vertical-align: top;
  padding-top: 1.5%;
  font-weight: 500;
}
.MC-value{
  display: inline-block;
  padding-left: 3%;
  width: 14%;
  padding-right: 3%;
  text-align: right;
  vertical-align: top;
  padding-top: 2.25%;
  font-size: 1.2rem;
  font-weight: 200;
}
/*End My Card*/

/*Start Activity*/
.Teammate-Activity-title{
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 1%;
}
.Teammate-Activity-subtitle{
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  margin-bottom: 1%;
  color: gray;
}
.Teammate-Activity{
  overflow-y: scroll;
  white-space: nowrap;
  vertical-align: top;
  margin-bottom: 5%;

}
.T-Activity-Card{
  background-color: white;
  width: 34.5%;
  padding: 3%;
  margin : 1%;
  display: inline-block;
  border-radius: .5rem;
  min-height: 10vh;
  -webkit-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);;
  -moz-box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);;
  box-shadow: 0 0 2.5px 1px rgb(200, 200, 200);
  vertical-align: top;
  white-space: normal;
  text-align: center;
  color: black;
}
.T-Activity-Card-body{
  font-size: 1.15rem;
  padding-top: 2%;
  font-weight: 200;
}
/*End Activity*/

/*Start Teammates*/
.Teammate-Teammates-title{
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 1%;
  padding-left: 2%;
}
.Teammate-Teammates-div{
  padding: 3%;
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  -moz-box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  margin: 3%;
  vertical-align: top;
  font-size: 1.1rem;
  margin-bottom: 5%;
}
.T-Teammates-img-div{
  display: inline-block;
  width: 10%;
  
}
.T-Teammates-img{
  display: inline-block;
  width: 100%;
  border-radius: 1rem;
}
.T-Teammates-username{
  display: inline-block;
  padding-left: 3%;
  width: 45%;
  text-align: left;
  vertical-align: top;
  padding-top: 1.5%;
  font-weight: 500;
  color: black;
}
.T-Teammates-value{
  display: inline-block;
  padding-left: 3%;
  width: 44%;
  padding-right: 3%;
  text-align: right;
  vertical-align: top;
  padding-top: 2.25%;
  font-size: 1rem;
  font-weight: 200;
  color: black;
}
/*End Teammates*/

/*Start Activity*/
.Teammate-Signing-title{
  font-size: 1.5rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 1%;
  padding-left: 2%;
}
.Teammate-Signing-subtitle{
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  margin-bottom: 1%;
  color: gray;
  padding-left: 2%;
}
.Teammate-Signing-div{
  padding: 3%;
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  -moz-box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  box-shadow: 0 0 5px 2px rgb(200, 200, 200);
  margin: 3%;
  vertical-align: top;
  font-size: 1.1rem;
  margin-bottom: 5%;
}
.Teammate-Signing-code-div{
  width: 100%;
  text-align: center;
  font-weight: 200;
  font-size: 0.8rem;
  color: gray;
}
.Teammate-Signing-code{
  font-weight: 600;
  font-size: 1.2rem;
  color: black;
}
.Teammate-Signing-hr{
  border: .1px solid rgb(235,235,235);
}
.Teammate-Signing-progress{
  text-align: center;
  font-weight: 200;
  margin-top: 5%;
  margin-bottom: 5%;
}
.Teammate-Signing-progress-line-green{
  border: 5px solid green;
  margin-left: 10%;
  display: inline-block;
  width: 45%;
  padding-left: 0%;
  padding-right: 0%
}
.Teammate-Signing-progress-line-red{
  border: 5px solid red;

  margin-right: 10%;
  display: inline-block;
  width: 28%;
  padding-left: 0%;
  padding-right: 0%
}
.T-S-P-Numbers{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
}
.T-S-P-Num{
  display: inline-block;
  text-align: center;
  width: 20%;
}




@media screen and (min-width: 1024px) {

 /* Edit */
.EditFriendHodler{
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(230,230,230);
  outline: 2px black solid;
  overflow-y: auto;
}
.EditFriend{
  top: 0;
  left: 0;
  margin-left: 10vw;
  margin-top: 15vh;
  width: 80vw;
  height: 80vh;
  background-color: white;
  outline: 1px black solid;
  box-shadow: 0px 28px 46px 0px rgba(0,0,0,0.2);
  padding: 5%;
  padding-top: 2%;
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
.Edit-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check-Type-currently{
  font-weight: 200;
  margin-left: 2%;
  font-size: .9rem;
}
.Edit-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 2%;
  font-weight: 400;
}
.Edit-Label {
  display: inline-block;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2rem;
}
.Edit-deleteBtn{
  border-radius: .8rem;
  background-color: white;
  margin-top: 2%;
  font-weight: 200;
  padding: 0;
  border: none;
  background: none;
  font-size: 0.8rem;
}
.edit-close-btn{
  text-align: right;
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}
}
@media screen and (min-width: 1200px) {
  .DesktopOnly{
    padding-left: 25%;
    padding-right: 25%;
  }
  .Friends {
    width: 100%;
    padding-left: 30%;
    padding-right: 30%;
  }
  .WA-Body2{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.4rem;
    text-align: left;
  }
  .WA-Title{
    font-size: 1.9rem;
    font-weight: 600;
    text-align: center;
    padding: 5%;
    padding-bottom: 1%;
  }
  .WA-Body1{
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.5rem;
    font-style: italic;
    padding-bottom: 5%;
    text-align: center;
  }
  .WA-Body2-words{
    padding-top: 3%;
  }
  .FC-E-item-return-item {
    display: block;
    background-color: #f9f9f9;
    min-width: 30%;
    margin-left: 30%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 1.2rem;
    opacity: 1;
    border-radius: 1rem;
    border: 1px solid #c5b358;
    font-weight: 800;
  }
  .PopUp{
    position: fixed;
    top: 0;
    left: 0;
    margin: 6vh 0vw 5vh 0vw;
    width: 100vw;
    height: 95vh;
    padding-left: 25vw;
    padding-right: 25vw;
    background-color: white;
    overflow-y: scroll;
    padding-bottom: 0vh;
  }
  .popup-copy{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    outline: 1px solid black;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 2rem;
  }

 /* Edit */
.EditFriendHodler{
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(230,230,230);
  outline: 2px black solid;
  overflow-y: auto;
}
.EditFriend{
  top: 0;
  left: 0;
  margin-left: 25vw;
  margin-top: 15vh;
  width: 50vw;
  height: 80vh;
  background-color: white;
  outline: 1px black solid;
  box-shadow: 0px 28px 46px 0px rgba(0,0,0,0.2);
  padding: 5%;
  padding-top: 2%;
  z-index: 10;
  text-align: center;
  overflow-y: auto;
}
.Edit-Check-Div {
  width: 80%;
  text-align: left;
  padding-left: 10%;
}
.AF-Check-Type-currently{
  font-weight: 200;
  margin-left: 2%;
  font-size: .9rem;
}
.Edit-Title {
  font-size: 1.3rem;
  font-style: italic;
  padding-bottom: 2%;
  font-weight: 400;
}
.Edit-Label {
  display: inline-block;
  padding-bottom: 5%;
  font-weight: 800;
  font-size: 2rem;
}
.Edit-deleteBtn{
  border-radius: .8rem;
  background-color: white;
  margin-top: 2%;
  font-weight: 200;
  padding: 0;
  border: none;
  background: none;
  font-size: 0.8rem;
}
.edit-close-btn{
  text-align: right;
  border: none;
  background: none;
  text-decoration: underline;
  color: gray;
}
.T-Scroller{
  background-color: rgb(245, 245, 245);
  padding-top: 0vh;
}

.T-Teammates-img-div{
  display: inline-block;
  width: 6%;
  
}


}